const getters = {
  getAll: (state) => {
    if (state.all === {}) return []
    return Object.keys(state.all).map(key => state.all[key])
  },
  getPayload: (state) => {
    if (state.payload === {}) return null
    return state.payload
  },
  getItemByName: (state) => (name) => {
    return state.all[name]
  }
}

const actions = {
}

const mutations = {
  setAll (state, data) {
    if (data) {
      data.forEach(option => {
        if (option) {
          state.all[option.name] = option
        } else {
          console.log('Cannot find option', option, data)
        }
      })
    }
  },
  setPayload (state, data) {
    state.payload = data
  },
  clear (state) {
    state.all = {}
  },
  clearPayload (state) {
    state.payload = {}
  },
  updateSelected (state, payload) {
    // State needs to be refreshed completely for components to notice
    // a change
    let newState = {}
    Object.keys(state.all).forEach(key => {
      newState[key] = state.all[key]
      if (key === payload.group) {
        newState[key].visible = payload.option
      }
    })
    state.all = {}
    state.all = newState
  },
  updateHidden (state, payload) {
    // State needs to be refreshed completely for components to notice
    // a change
    let newState = {}
    Object.keys(state.all).forEach(key => {
      newState[key] = state.all[key]
      if (key === payload.name) {
        newState[key].hidden = payload.hidden
      }
    })
    state.all = {}
    state.all = newState
  },
}

export default {
  // state,
  getters,
  actions,
  mutations
}
