import _common from "./_common"

const type = 'visibilitygroup'

class visibilitygroup {
  title = ''
  name = ''
  visible = null
  hidden = false
  init = false
  options = []

  constructor (data) {
    this.title = data.title ?? ''
    this.name = data.name ?? ''
    this.visible = data.visible ?? null
    this.options = data.options ?? []

    this.options.forEach(option => {
      option.matchNameExactly = option.matchNameExactly ?? false
    })

    if (this.options.length > 0) {
      this.visible = this.visible ?? this.options[0].name
    }
  }

  setHidden(value) {
    this.hidden = value ?? false
  }

  setInit(value) {
    this.init = value ?? false
  }
}


const assembleData = function (menu) {
  const menuItems = _common.getMenuItems(menu, type)

  return menuItems.map(menuItem => {
    const template = _common.getTemplateItem(menuItem[type], type)
  
    if (template) {
      let option = new visibilitygroup(template)
      option.setHidden(menuItem.hidden)
      option.setInit(menuItem.init)
      return option
    } else {
      return null
    }
  })

}

const fetchData = function (menu) {
  _common.fetchMenuData(assembleData, menu, type)
}

const importData = function (data) {
  return _common.importGroupData(data, type)
}

const exportData = function () {
  return _common.exportGroupData(type)
}

const exportDataPretty = function () {
  return _common.exportGroupDataPretty(type)
}

export default {
  fetchData,
  importData,
  exportData,
  exportDataPretty
}