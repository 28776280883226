import _common from "./_common"
import store from 'Store'

const type = 'view'

class view {
  title = ''
  name = ''
  hidden = false
  payload = {}

  constructor (data) {
    this.title = data.title ?? ''
    this.name = data.name ?? ''
    this.payload = data.payload
  }

  setHidden(value) {
    this.hidden = value ?? false
  }
}

const assembleData = function (menu) {
  const menuItems = _common.getMenuItems(menu, type)

  return menuItems.map(menuItem => {
    const template = _common.getTemplateItem(menuItem[type], 'views')
  
    if (template) {
      let option = new view(template)
      option.setHidden(menuItem.hidden)
      return option
    } else {
      return null
    }
  })

}

const fetchData = function (menu) {
  _common.fetchMenuData(assembleData, menu, type)
}

const importData = function (data) {
  return _common.importGroupData(data, type)
}

const exportData = function () {
  return _common.exportGroupData(type)
}

const exportDataPretty = function () {
  let exportArray = []
  Object.values(store.state.view.all).forEach(option => {
    if (!option.hidden) {
      exportArray.push({option: option.title, visible: option.visible})
    }
  })
  return exportArray
}

export default {
  fetchData,
  importData,
  exportData,
  exportDataPretty
}