<script>
import configure from "@/tools/configurator/configure"

export default {
  name: 'modelpickeritem',
  components: {
  },
  props: {
    scene: Object
  },
  computed: {
    bannerpath () {
      return process.env.BASE_URL + this.scene.banner
    },
    isActive () {
        return this.$store.getters['scenes/getCurrentScene'].sceneuid === this.scene.sceneuid
    },
    ctaText () {
      // checks if this model is the currently active one
      if (this.isActive) { return 'Reload model' } else { return 'Select model' }
    }    
  },
  methods: {
    selectModel () {
      // close the modelpicker and mobile bottom nav
      this.$store.commit('ui/setDesktopModelpicker', false)
      this.$store.commit('ui/setMobileModelpicker', false)

      // only load a model if it isn't the same as the current one
      if (!this.isActive) {
        this.$router.push({query:{scene: this.scene.sceneuid}})
        
        this.$store.dispatch('scenes/setCurrentScene', {scene: this.scene})
        configure.parseProjectData(this.scene)
        configure.loadScene(this.scene.sceneuid)

        if (this.$vuetify.breakpoint.mobile) {
          this.$store.commit('ui/setBottomnav', false)
        } else {
          this.$store.commit('ui/setDesktopnav', true)
        }
      }
    }
  }
}
</script>

<template>
  <v-card 
    color="cardaccent" 
    elevation=3
  >
    <v-img 
      v-ripple="{class:'primary--text'}" 
      class="primary--text align-end" 
      :src="bannerpath" 
      @click="selectModel()" 
      alt="banner"
      max-height="200px"
      contain
    >
      <v-card-text 
        v-if="isActive"
      >
        This is the current model
      </v-card-text>
    </v-img>

    <div class="mx-3 pb-2">
      <div class="text-h5 font-weight-medium">{{ scene.scenetitle }}</div>
      <div class="text-sm-subtitle-1 font-weight-light text--secondary">{{ scene.description }}</div>
    </div>

    <v-card-actions v-if="!isActive">
      <v-spacer></v-spacer>    
      <v-btn
        depressed 
        :class="isActive ? 'card' : 'primary'" 
        @click="selectModel()" 
      >
        {{ this.ctaText }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
