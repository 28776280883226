import _common from "./_common"
import store from 'Store'

const type = 'visibilitysingle'

class visibilitysingle {
  title = ''
  name = ''
  visible = true
  hidden = false
  exactname = true
  matchNameExactly = true
  objects = []

  constructor (data) {
    this.title = data.title ?? ''
    this.name = data.name ?? ''
    this.visible = data.visible ?? true
    this.exactname = data.exactname ?? true
    this.matchNameExactly = data.matchNameExactly ?? true
    this.objects = data.objects ?? []
  }

  setHidden(value) {
    this.hidden = value ?? false
  }
}

const assembleData = function (menu) {
  const menuItems = _common.getMenuItems(menu, type)

  return menuItems.map(menuItem => {
    const template = _common.getTemplateItem(menuItem[type], type)
  
    if (template) {
      let option = new visibilitysingle(template)
      option.setHidden(menuItem.hidden)
      return option
    } else {
      return null
    }
  })

}

const fetchData = function (menu) {
  _common.fetchMenuData(assembleData, menu, type)
}

const importData = function (data) {
  return _common.importGroupData(data, type)
}

const exportData = function () {
  return _common.exportGroupData(type)
}

const exportDataPretty = function () {
  let exportArray = []
  Object.values(store.state.visibilitysingle.all).forEach(option => {
    if (!option.hidden) {
      exportArray.push({option: option.title, visible: option.visible})
    }
  })
  return exportArray
}

export default {
  fetchData,
  importData,
  exportData,
  exportDataPretty
}