import templateData from 'ProjectData/template'
import libraryData from 'ProjectData/library'
import store from 'Store'

/**
 * Find all menuitems of a certain type
 * @param {Array} menu an array of menugroup objects
 * @param {String} type the type of menuitem we're looking for
 * @returns array of menuitems
 */
const getMenuItems = function (menu, type) {
  let items = []
  menu.forEach(menugroup => {
    menugroup.options.forEach(menuoption => {
      if (menuoption[type]) {
        items.push(menuoption)
      }
    })
  })
  return items
}

/**
 * Find an item in the template data of a specific type by name
 * @param {String} name The name of the template item
 * @param {String} type The type of the template item
 * @returns an object
 */
 const getTemplateItem = function (name, type) {
  return templateData[type].find(item => item.name === name)
}

/**
 * Find an item in the library data of a specific type by name
 * @param {String} name The name of the template item
 * @param {String} type The type of the template item
 * @returns an object
 */
 const getLibraryItem = function (name, type) {
   if (libraryData[type]) {
     return libraryData[type].find(item => item.name === name)
   }
}

/**
 * Assemble the data from project and template and add it to the store 
 * all at once
 * @param {function} assembler The function to assemble the data. Unique per data type
 * @param {Array} menu The project menus
 * @param {String} type the type of the entity we're fetching
 */
 const fetchMenuData = function (assembler, menu, type) {
  store.commit(`${type}/clear`)
  const options = assembler(menu)
  store.commit(`${type}/setAll`, options)
}

const fetchSceneData = function (assembler, scene, type) {
  store.commit(`${type}/clearPayload`)
  const payload = assembler(scene, type)
  store.commit(`${type}/setPayload`, payload)
}

/**
 * Imports group data and updates the store
 * @param {Object} data The data that needs to be imported
 * @param {string} type the type of the entity
 */
const importGroupData = function (data, type) {
  Object.keys(data).forEach(key => {
    store.commit(`${type}/updateSelected`, {group: key, option: data[key]})
  })
}

/**
 * Get an object with the names of the visible options of a certain type. Used
 * for query parameters or storing a configuration.
 * Works on group items
 * @param {string} type the type of the entity
 * @returns object
 */
const exportGroupData = function (type) {
  let exportObject = {}
  const groups = store.getters[`${type}/getAll`]
  groups.forEach(group => {
    if (!group.hidden) {
      exportObject[group.name] = group.visible
    }
  })
  return exportObject
}

/**
 * Get an array of the visible options of a certain type with their titles. Used
 * for exporting to PDF.
 * Works on group items
 * @param {string} type the type of the entity
 * @returns array of objects
 */
const exportGroupDataPretty = function (type) {
  let exportArray = []
  const groups = store.getters[`${type}/getAll`]
  groups.forEach(group => {
    if (!group.hidden) {
      const visibleOption = group.options.find(option => option.name === group.visible)
      exportArray.push({item: group.title, option: visibleOption.title, swatch: visibleOption.swatch})
    }
  })
  return exportArray
}

/**
 * Singular data is used during scene setup once. This method compiles a full 
 * object from three places: project, template and library
 * @param {object} scene The current scene
 * @param {string} type the type of data
 * @returns Object with fully compiled data
 */
const assembleSingularData = function (scene, type) {
  const project = scene[type]
  const template = project ? getTemplateItem(project.template, type) : null
  const library = template ? getLibraryItem(template.library, type) : null

  let payload = {}
  if (library) {
    payload = {...library.payload}
  }
  if (template) {
    payload = {...payload, ...template.payload}
  }
  if (project) {
    payload = {...payload, ...project.payload}
  }
  return payload
}

const compileTemplateData = function (type) {
  if (!templateData[type]) return null
  const templates = templateData[type].map(template => {
    const libraryItem = getLibraryItem(template.library, type)
    let payload = {}
    if (libraryItem) {
      payload = {...libraryItem.payload}
    }
    if (template) {
      payload = {...payload, ...template.payload}
    }
    const output = {
      name: template.name,
      payload: payload
    }
    return output
  });
  store.commit(`${type}/setAll`, templates)
}

export default {
  getMenuItems,
  getTemplateItem,
  getLibraryItem,
  fetchMenuData,
  fetchSceneData,
  assembleSingularData,
  compileTemplateData,
  importGroupData,
  exportGroupData,
  exportGroupDataPretty
}