<template>
  <modelpicker/>
</template>

<script>
import modelpicker from 'Components/modelpicker'
export default {
  name: 'Models',
  components: {
    modelpicker
  }
}
</script>
