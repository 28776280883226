import textureTools from '@/tools/sketchfab/textures'
import materialTools from '@/tools/sketchfab/materials'
import store from '@/store'

const AddTexture = function (sensarObject, deformationObject) {

    return new Promise((resolve, reject) => {
        //Read a single deformationobject from the data file and upload its texture to sketchfab
        const url = process.env.BASE_URL + sensarObject.deformationurl + deformationObject.image
        textureTools.addTexture(url).then(uid => {
            store.dispatch('sensar/setDeformationUid', {sensarname: sensarObject.name, deformationObject: deformationObject, uid: uid})
            resolve()      
        })
    })

}

const SetDisplacementFrame = function (deformation, deformationFactor, deformationmultiplier) {
    let terrainMaterial = materialTools.getSceneMaterial('Terrain')

    let disp = textureTools.makeTexture(deformation.uid, 0)
    disp.internalFormat = 'LUMINANCE'
    terrainMaterial.channels['Displacement'].texture = disp
    terrainMaterial.channels['Displacement'].factor = deformationmultiplier * deformationFactor
    materialTools.setMaterial(terrainMaterial, null)
}

const SetAlbedoFrame = function (deformation, deformationFactor) {
    let terrainMaterial = materialTools.getSceneMaterial('Terrain')

    let albedo = textureTools.makeTexture(deformation.uid, 0)
    // disp.internalFormat = 'LUMINANCE'
    terrainMaterial.channels['AlbedoPBR'].texture = albedo
    // terrainMaterial.channels['Displacement'].factor = 100 * deformationFactor
    materialTools.setMaterial(terrainMaterial, null)
}

const initTerrain = function (promises) {
    if (store.state.sensar.all.length > 0) {
        //load the sensar deformation textures
        store.state.sensar.all.forEach(sensarItem => {
            sensarItem.deformation.forEach(deformationObject => {
                promises.push(AddTexture(sensarItem, deformationObject))
            })
        })
    }
}

export default { 
    AddTexture, 
    SetDisplacementFrame, 
    SetAlbedoFrame,
    initTerrain
}
