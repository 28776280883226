<script>
import store from '../../store'
import liquidpresetmenu from './liquid-preset-menu'

export default {
  name: 'liquidPreset',
  components: {
    liquidpresetmenu
  },
  props: {
    liquidname: String,
    liquid: Object,
    disabled: Boolean
  },
  data: function () {
    return {
      toggleMenu: false
    }
  },
  computed: {
    theLiquidpreset () {
      const myLiquid = store.getters['liquid/getLiquidByProperty']('name', this.liquid.name)
      return myLiquid.liquidpresets.find(preset => preset.liquidname === this.liquidname)
      // return myPresets
    },
    icon () {
      if (this.theLiquidpreset.icon) {
        return (process.env.BASE_URL + this.theLiquidpreset.icon)
      } else {
        return null
      }
    },  
  },
  methods: {
    // closeMenu () {
    //   this.toggleMenu = false
    // },
    // openMenu () {
    //   this.toggleMenu = true
    // },
    // highlight () {
    //   // just adding the click event adds a highlight to a menu item
    // },
    // getIconPath (icon) {
    //   const imagePath = require.context('../assets/icons/', true)
    //   // eslint-disable-next-line
    //   return API_URL + imagePath(icon, true)
    // }
  
  }
}
</script>

<template>
  <v-list-group
    v-if="theLiquidpreset && theLiquidpreset.options.length > 1"
    color='primary'
  >
    <template v-slot:activator>
      <v-list-item-avatar 
        v-if="theLiquidpreset.swatch" 
        :color="theLiquidpreset.swatch" 
        class="elevation-4"
        left
      />
      <v-list-item-avatar v-if="icon" class="elevation-4">
        <v-img :src="icon"/>
      </v-list-item-avatar>      

      <v-list-item-content color='cardaccent' two-line>
          <v-list-item-title v-text="theLiquidpreset.title"></v-list-item-title>
          <v-list-item-subtitle>{{ theLiquidpreset.currentTitle }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
      <v-divider></v-divider>

      <!-- <materialmenu :options="theLiquidpreset.options" :name="theLiquidpreset.name"/> -->
      <liquidpresetmenu :preset="theLiquidpreset" :options="theLiquidpreset.options" :name="theLiquidpreset.name" :liquid="liquid"/>

  </v-list-group>


</template>

<style lang="scss" scoped>

</style>
