const library = {
  materials: [
    {
      name: 'Steel',
      payload: {
        AlbedoPBR: {factor: 1.0, color: [170, 170, 170]},
        MetalnessPBR:{factor: 1},
        RoughnessPBR: {enable: true, factor: 0.15},
        GlossinessPBR: {enable: false},
        ClearCoat: {enable: false},
        SpecularF0: {factor: 0.0}
      }
    },
    {
      name: 'Skai',
      payload: {
        AlbedoPBR: {factor: 1.0, color: [128, 128, 128]},
        GlossinessPBR: {factor: 0.7},
        SpecularF0: {factor: 0.3}
      }
    },
    {
      name: 'Carpaint',
      payload: {
        AlbedoPBR: {factor: 1.0, color: [160,160,160]},
        RoughnessPBR: {enable: true, factor: 0.85, textureurl: 'data/mirari/assets/carpaint_rough_light.jpg', uv:7},
        GlossinessPBR: {enable: false},
        MetalnessPBR: {enable: true, factor: 0.95},
        NormalMap: {enable: true, factor: 0.20, textureurl: 'data/mirari/assets/carpaint_norm.jpeg', uv:7},
        BumpMap: {enable: false},
        SpecularF0: {factor: 0.0},
        ClearCoat: {
          factor: 0.4,
          reflectivity: 0.65,
          enable: true,
          tint: [
            255,
            255,
            255
          ],
          thickness: 8.0
        },
        ClearCoatRoughness: {
          factor: 0.0,
          // enable: true
        },
        Anisotropy: {enable: false, texture: null},
      }
    },    
  ],
  player: [

  ],
  postprocessing: [
    {
      name: 'museum',
      payload: {
        enable: true,
        AR: false,
        chromaticAberrationEnable: false,
        colorBalanceEnable: false,
        doDistortionVR: false,
        dofEnable: false,
        grainEnable: false,
        taaEnable: true,
        taaTransparent: true,
        vrFading: false,
        vrStartFade: false,
        webVR: false,
        
        ssrEnable: true,
        ssrFactor: 1,
        ssaoEnable: true,
        ssaoRadius: 0.8,
        ssaoIntensity: 0.25,
        ssaoBias: 0.05,
        sharpenEnable: true,
        sharpenFactor: 0.05,
        vignetteEnable: false,
        vignetteAmount: 0.4,
        vignetteHardness: 0.4,
        bloomEnable: false,
        bloomFactor: 2,
        bloomThreshold: 1,
        bloomRadius: 0.3,
        toneMappingEnable: false,
        toneMappingMethod: "filmic",
        toneMappingExposure: 1.9341917024,
        toneMappingBrightness: 0.2360515021,
        toneMappingContrast: -0.0271816881,
        toneMappingSaturation: 1,         
      }
    }
  ],
  environment: [
    {
      name: 'museum',
      payload: {
        enabled: true,
        exposure: 0.81,
        lightIntensity: 0.8,
        rotation: 1.5,
        shadowEnabled: true,
        uid: 'f476ebd2ecd847ffa9ed1b3fa02f7442'
      }
    },
  ],
  background: [

  ]
}
  
export default library;