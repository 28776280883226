<script>
import environmentmenu from './menu'

export default {
  name: 'environmentoption',
  components: {
    environmentmenu,
  },
  data: () => ({
    show: false,

  }),  
  props: {
    optionname: String
  },
  computed: {
    studiogroup () {
      return this.$store.getters['studiogroup/getItemByName'](this.optionname)
    },
    currentOption () {
      let current = this.studiogroup.options.find(item => item.name === this.studiogroup.visible)
      return current
    },
  },
  methods: {
  }  
}
</script>

<template>
  <v-card>
    <div class="d-flex flex-no-wrap">
      <v-list-item-avatar 
        v-if="studiogroup.swatch" 
        :color="studiogroup.swatch" 
        class="elevation-4 ma-2"
        left
        tile
        size="80"
      />
      <v-list-item-avatar 
        v-if="studiogroup.icon" 
        class="elevation-4 ma-2"
        left
        tile
        size="80"
      >
        <v-img 
          :src="studiogroup.icon"
        />
      </v-list-item-avatar> 
      <div>
        <v-card-title>
          {{ studiogroup.title }}
        </v-card-title>

        <v-card-subtitle
        >
          {{ currentOption.title }}
        </v-card-subtitle>
      </div>
    </div>

    <v-card-actions>
      <v-btn
        text
        @click="show = !show"
      >
        Environment list
        <v-icon 
          right
        >
          {{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
        <environmentmenu :options="studiogroup.options" :name="studiogroup.name"/>
      </div>
    </v-expand-transition>

  </v-card>
</template>