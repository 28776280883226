import _base from './_base-module'
import templateData from 'ProjectData/template'

const state = {
  lights: {
    0: null,
    1: null,
    2: null,
  },
  lights2d: {
    0: [-100, -100],
    1: [-100, -100],
    2: [-100, -100],
  },
  lights3d: {
    0: [0, 0, 0],
    1: [0, 0, 0],
    2: [0, 0, 0],
  },
  lightslatlon: {
    0: [0, 0],
    1: [0, 0],
    2: [0, 0],
  },
  lightmoves: false,
  camera: {
    latlon: [0,0],
    '3d': [0,0,0],
    target: [0,0,0],
    fov: 0
  },
  all: [],
}

const getters = {
  ..._base.getters,
  getLight: (state) => (id) => {
    return state.lights[id]
  },
  getLight2d: (state) => (id) => {
    return state.lights2d[id]
  },
  getLight3d: (state) => (id) => {
    return state.lights3d[id]
  },
  getLightLatlon: (state) => (id) => {
    return state.lightslatlon[id]
  },
  export: (state) => {
    let exportObject = {}
    const ids = [0,1,2]
    ids.forEach(id => {
      // take the original matrix and insert the calculated 3d position
      // That's the only thing we care about really
      let m3 = [...state.lights[id].matrix]
      m3.splice(12, 0, ...state.lights3d[id])
      
      const lightObject = {
        color: state.lights[id].color,
        enabled: state.lights[id].enabled,
        shadowEnabled: state.lights[id].shadowEnabled,
        intensity: state.lights[id].intensity,
        matrix: m3,
      }
      exportObject[id] = lightObject
    })
    return exportObject
  }
}

const actions = {
  fetchData ({ commit }) {
    commit('clearAll')
    templateData.nmalight && templateData.nmalight.forEach(item => {
        commit('addItem', item)
    })
  },
  setLight ({commit}, {id, light}) {
    commit('setLight', {id: id, light: light})
  },
  setLight2d ({commit}, {id, coord}) {
    commit('setLight2d', {id: id, coord: coord})
  },
  setLight3d ({commit}, {id, coord}) {
    commit('setLight3d', {id: id, coord: coord})
  },
  setCamera3d ({commit}, {coord}) {
    commit('setCamera3d', {coord: coord})
  },
  setLightmoves ({commit}, {value}) {
    commit('setLightmoves', {value: value})
  },
  setLightLatLon ({commit}, {id, coord}) {
    commit('setLightLatLon', {id: id, coord: coord})
  },
  setCameraLatLon ({commit}, {coord}) {
    commit('setCameraLatLon', {coord: coord})
  },
}

const mutations = {
  ..._base.mutations,
  clearAll (state) {
    state.all = []
  },
  addItem (state, item) {
    state.all.push(item)
  },
  setLight (state, payload) {
    const lights = {}
    Object.keys(state.lights).forEach(key => {
      if (String(key) === String(payload.id)) {
        lights[key] = payload.light
      } else {
        lights[key] = state.lights[key]
      }
    })
    state.lights = {}
    state.lights = lights
    // state.lights[payload.id] = payload.light
  },
  setLight2d (state, payload) {
    state.lights2d[payload.id] = payload.coord
  },
  setLight3d (state, payload) {
    state.lights3d[payload.id] = payload.coord
  },
  setCamera3d (state, payload) {
    state.camera3d = payload.coord
  },
  setLightmoves (state, payload) {
    state.lightmoves = payload.value
  },
  setLightLatLon (state, payload) {
    state.lightslatlon[payload.id] = payload.coord
  },
  setCameraLatLon (state, payload) {
    state.cameralatlon = payload.coord
  },
  setLightProperty (state, payload) {
    state.lights[payload.id][payload.key] = payload.property
  },
  setCameraProperty (state, payload) {
    state.camera[payload.key] = payload.property
  },
  import (state, payload) {
    [0,1,2].forEach(id => {
      state.lights[id] = {}
      state.lights[id].color = payload[id].color
      state.lights[id].enabled = payload[id].enabled
      state.lights[id].shadowEnabled = payload[id].shadowEnabled
      state.lights[id].intensity = payload[id].intensity
      state.lights[id].matrix = payload[id].matrix
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
