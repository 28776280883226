<script>
import configure from '@/tools/configurator/configure'
import sensarTools from '@/tools/project/sensar'
import store from '@/store'

export default {
  name: 'sensaroption',
  components: {
  },
  props: {
    optionname: String
  },
  computed: {
    sensar () {
      return store.getters['sensar/getItemByName'](this.optionname)
    },
    currentframe: {
      get: function () {
        return this.sensar.currentframe
      },
      set: function (newValue) {
        if (newValue < this.sensar.startframe) {
          newValue = this.sensar.endframe
        } else if (newValue > this.sensar.endframe) 
        {
          newValue = this.sensar.startframe
        }
        this.date = this.allowedDates[newValue]
        this.pressure = this.sensar.deformation[newValue].pressure
        sensarTools.SetDisplacementFrame(this.sensar.deformation[newValue], this.deformationValue, this.sensar.deformationmultiplier);
        if (this.switch1) sensarTools.SetAlbedoFrame(this.sensar.deformation[newValue], this.deformationValue);
        store.commit('sensar/updatePropertyOfItemByName', {itemName: this.sensar.name, property: 'currentframe', value: newValue })
      }
    },
    deformationValue: {
      get: function () {
        return this.deformationVal
      },
      set: function (newValue) {
        if (newValue < this.deformationMin) {
          newValue = this.deformationMax
        } else if (newValue > this.deformationMax) 
        {
          newValue = this.deformationMin
        }
        this.deformationVal = newValue
        // this.currentframe = this.currentframe
      }
    },
    speedValue: {
      get: function () {
        return this.speedVal
      },
      set: function (newValue) {
        if (newValue < this.speedMin) {
          newValue = this.speedMax
        } else if (newValue > this.speedMax) 
        {
          newValue = this.speedMin
        }
        this.speedVal = newValue
        // this.currentframe = this.currentframe
      }
    },
    switch1: {
      get: function () {
        return this.switch
      },
      set: function (newValue) {
        this.switch = newValue
        if (newValue === false) {
          const material = store.state.material.all[0]
          const option = material.options.find(option => option.name === material.visible)
          configure.SetMaterialOption(material, option)
        } else {
          sensarTools.SetAlbedoFrame(this.sensar.deformation[this.currentframe], this.deformationValue);
        }
      }
    }        
  },
  data: () => ({
      // currentframe: 0,
      deformationMin: 0,
      deformationMax: 25,
      deformationVal: 10,
      speedMin: 1,
      speedMax: 25,
      speedVal: 10,
      date: '',
      pressure: '',
      dateMin: '',
      dateMax: '',
      allowedDates: [],
      show: true,
      switch: false
  }),
  mounted () {
      this.currentframe = this.sensar.currentframe
      this.speedValue = this.sensar.playbackspeed
      this.allowedDates = this.sensar.deformation.map(item => item.date)
      this.dateMin = this.allowedDates[0]
      this.dateMax = this.allowedDates[this.allowedDates.length-1]
      this.date = this.dateMin
  },
  methods: {
    play () {
        store.dispatch('sensar/clearInterval')

        let self = this
        const interval = setInterval(function() {
          self.currentframe++
        }, 1000/self.speedValue);

        store.dispatch('sensar/setInterval', {interval: interval})
    },
    pause () {
      store.dispatch('sensar/clearInterval')
    },
    skipBackward () {
      this.currentframe = this.sensar.startframe
      store.dispatch('sensar/clearInterval')
    },
    skipPrevious () {
      this.currentframe--
      store.dispatch('sensar/clearInterval')
    },
    skipForward () {
      this.currentframe = this.sensar.endframe
      store.dispatch('sensar/clearInterval')
    },
    skipNext () {
      this.currentframe++
      store.dispatch('sensar/clearInterval')
    },
    setAnimationSpeed () {
      if (store.state.sensar.interval !== null) {
        this.pause()
        this.play()
      }
    },
    setDate (value) {
      const index = this.allowedDates.findIndex(item => item === value)
      this.currentframe = index
      this.pause()
    },
    isDateAllowed (value) {
      return this.allowedDates.indexOf(value) !== -1
    }  
  }  
}
</script>

<template>
<v-card color="cardaccent">
  <v-card-title> {{ sensar.title }} </v-card-title>

  <div class="ml-3 text-left">
    <v-icon large @click="skipBackward">mdi-skip-backward</v-icon>
    <v-icon large @click="skipPrevious">mdi-skip-previous</v-icon>
    <v-icon large @click="pause">mdi-pause</v-icon>
    <v-icon large @click="play">mdi-play</v-icon>
    <v-icon large @click="skipNext">mdi-skip-next</v-icon>
    <v-icon large @click="skipForward">mdi-skip-forward</v-icon>
  </div>

  <div class="ml-4 mt-3 text-left">
    <span class="text--secondary">Date </span>
    <span>{{ date }}</span>
  </div>

  <div class="ml-4 mt-3 text-left" v-if="pressure">
    <span class="text--secondary">Pressure </span>
    <span>{{ pressure }}</span>
  </div>


        <v-container class="ml-1">
        <v-row>
        <v-col cols="12">
          <v-slider
            label="Player"
            :min="sensar.startframe"
            :max="sensar.endframe"
            v-model="currentframe"
            @start="pause"
          >
            <template v-slot:append>
              <v-text-field
                v-model="currentframe"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 45px"
              ></v-text-field>
            </template>
          </v-slider>    
        </v-col>
        <v-col cols="12">
          <v-slider
            label="Scale"
            :min="deformationMin"
            :max="deformationMax"
            v-model="deformationValue"
          >
            <template v-slot:append>
              <v-text-field
                v-model="deformationValue"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 45px"
              ></v-text-field>
            </template>
          </v-slider>    
        </v-col>
        <v-col cols="12">
          <v-slider
            label="Speed(fps)"
            :min="speedMin"
            :max="speedMax"
            v-model="speedValue"
            @end="setAnimationSpeed"
          >
            <template v-slot:append>
              <v-text-field
                v-model="speedValue"
                @click="setAnimationSpeed"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 45px"
              ></v-text-field>
            </template>
          </v-slider>
        </v-col>
        </v-row>
        </v-container>
        <v-list-item>
          <v-switch v-model="switch1" :label="`Deformation as image: ${switch1.toString()}`"></v-switch>
        </v-list-item>

      <v-row 
        justify="center"
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <v-date-picker
          color="primary"
          elevation="10"
          no-title
          :allowed-dates="isDateAllowed"
          class="mb-4"
          v-model="date"
          :min="dateMin"
          :max="dateMax"
          @change="setDate"
        ></v-date-picker>
      </v-row>
</v-card>

</template>

