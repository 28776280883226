<script>

export default {
  name: 'MobileTopBar',
  components: {
  },    
  props: {
    landscape: Boolean,
    headerlogo: String,
  },
  data: () => ({
  }),
  computed: {
    scenes () {
      return this.$store.getters['scenes/getAll']
    },
    panoactive () {
      return this.$store.state.pano.panoActive
    },
    desktopnav () {
      return this.$store.state.ui.desktopnav
    },
  },
  mounted () {
  },
  methods: {
    setMobileModelpicker () {
      this.$store.dispatch('ui/setMobileModelpicker', {isOpen: true})
    },
    showDesktopNav () {
      this.$store.commit('ui/setDesktopnav', true)
    },
  }
}
</script>

<template>
  <div
    class="mobiletopbar d-flex"
  >
    <v-btn
      class="ml-2 mt-2"
      fab
      elevation=2
      small
      @click="showDesktopNav"
      v-if="landscape"
    >
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>  
    <v-btn
      class="ml-2 mt-2"
      fab
      elevation=2
      absolute
      small
      @click="setMobileModelpicker"
      v-if="scenes.length > 1 && !panoactive"
    >
      <v-icon>mdi-menu</v-icon>
    </v-btn>          
    <v-img 
      class="headerlogo mr-2 mt-2 ml-auto" 
      v-if="headerlogo" 
      height="40" 
      position="right"
      contain
      max-width="250"
      :src="headerlogo"
    >
    </v-img>
  </div>
</template>

<style lang="scss" scoped>
.mobiletopbar {
  position: absolute;
  z-index: 3;
  width: 100%;
}
.headerlogo {
  z-index: 3;
  right: 0px;
  top: 0px;
}
</style>

