import _common from "./_common"

const type = 'materialoptionslists'

class materialoptionslists {
  name = ''
  options = []

  constructor (data) {
    this.name = data.name ?? ''
    this.options = data.options ?? []
  }
}

const assembleData = function (menu) {
  const menuItems = _common.getMenuItems(menu, type)

  return menuItems.map(menuItem => {
    const template = _common.getTemplateItem(menuItem[type], type)
  
    if (template) {
      let option = new materialoptionslists(template)
      return option
    } else {
      return null
    }
  })

}

const fetchData = function (menu) {
  _common.fetchMenuData(assembleData, menu, type)
}

export default {
  fetchData,
}