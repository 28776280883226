import tweakpane from '../../tweakpane'
import postprocessing from '../postprocessing';

let folder = null

let values = {}
let presetkeys = [
  'Post-enable',
  'Post-ssrEnable',
  'Post-ssrFactor',
  'Post-ssaoEnable',
  'Post-ssaoRadius',
  'Post-ssaoIntensity',
  'Post-ssaoBias',
  'Post-grainEnable',
  'Post-grainFactor',
  'Post-grainAnimated',
  'Post-dofEnable',
  'Post-dofBlurNear',
  'Post-dofBlurFar',
  'Post-sharpenEnable',
  'Post-sharpenFactor',
  'Post-chromaticAberrationEnable',
  'Post-chromaticAberrationFactor',
  'Post-vignetteEnable',
  'Post-vignetteAmount',
  'Post-vignetteHardness',
  'Post-bloomEnable',
  'Post-bloomThreshold',
  'Post-bloomFactor',
  'Post-bloomRadius',
  'Post-toneMappingEnable',
  'Post-toneMappingMethod',
  'Post-toneMappingExposure',
  'Post-toneMappingContrast',
  'Post-toneMappingBrightness',
  'Post-toneMappingSaturation',
  'Post-colorBalanceEnable',
  'Post-colorBalanceEnable-low-R',
  'Post-colorBalanceEnable-low-G',
  'Post-colorBalanceEnable-low-B',
  'Post-colorBalanceEnable-mid-R',
  'Post-colorBalanceEnable-mid-G',
  'Post-colorBalanceEnable-mid-B',
  'Post-colorBalanceEnable-high-R',
  'Post-colorBalanceEnable-high-G',
  'Post-colorBalanceEnable-high-B',
]

const buildGUI = async function() {

  // Get the post values from the scene
  values = await postprocessing.getPostProcessing()
  values.import = ''

  // create a folder for the post processing
  folder = tweakpane.pane.addFolder({
    title: 'Postprocessing',
    hidden: true
  });

  const btnExport = folder.addButton({
    title: 'Export Post processing',
  })
  btnExport.on('click', () => {
    values.import = tweakpane.exportJSON('post')
    tweakpane.updateGui()
  })  
  const btnImport = folder.addButton({
    title: 'Import Post processing',
  })
  btnImport.on('click', () => {
    tweakpane.refreshing = true
    tweakpane.pane.importPreset(JSON.parse(values.import));
    tweakpane.refreshing = false
    applyValues(null)
  })  
  folder.addInput( values, 'import')
  folder.addSeparator();

  folder.addInput(values, 'enable', {presetKey: 'Post-enable'})
  folder.addSeparator();

  folder.addInput(values, 'ssrEnable', {presetKey: 'Post-ssrEnable'})
  folder.addInput(values, 'ssrFactor', {min: 0,  max: 1, step: 0.01, presetKey: 'Post-ssrFactor'})
  folder.addSeparator();

  folder.addInput(values, 'ssaoEnable', {presetKey: 'Post-ssaoEnable'})
  folder.addInput(values, 'ssaoRadius', {min: 0, max: 20, step: 0.01, presetKey: 'Post-ssaoRadius'})
  folder.addInput(values, 'ssaoIntensity', {min: 0, max: 1, step: 0.01, presetKey: 'Post-ssaoIntensity'})
  folder.addInput(values, 'ssaoBias', {min: 0, max: 2, step: 0.01, presetKey: 'Post-ssaoBias'})
  folder.addSeparator();

  folder.addInput(values, 'grainEnable', {presetKey: 'Post-grainEnable'})
  folder.addInput(values, 'grainFactor', {min: 0, max: 0.5, step: 0.01, presetKey: 'Post-grainFactor'})
  folder.addInput(values, 'grainAnimated', {presetKey: 'Post-grainAnimated'})
  folder.addSeparator();

  folder.addInput(values, 'dofEnable', {presetKey: 'Post-dofEnable'})
  folder.addInput(values, 'dofBlurNear', {min: 0, max: 1, step: 0.01, presetKey: 'Post-dofBlurNear'})
  folder.addInput(values, 'dofBlurFar', {min: 0, max: 1, step: 0.01, presetKey: 'Post-dofBlurFar'})
  folder.addSeparator();

  folder.addInput(values, 'sharpenEnable', {presetKey: 'Post-sharpenEnable'})
  folder.addInput(values, 'sharpenFactor', {min: 0, max: 5, step: 0.1, presetKey: 'Post-sharpenFactor'})
  folder.addSeparator();

  folder.addInput(values, 'chromaticAberrationEnable', {presetKey: 'Post-chromaticAberrationEnable'})
  folder.addInput(values, 'chromaticAberrationFactor', {min: 0, max: 0.1, step: 0.01, presetKey: 'Post-chromaticAberrationFactor'})
  folder.addSeparator();

  folder.addInput(values, 'vignetteEnable', {presetKey: 'Post-vignetteEnable'})
  folder.addInput(values, 'vignetteAmount', {min: 0, max: 1, step: 0.01, presetKey: 'Post-vignetteAmount'})
  folder.addInput(values, 'vignetteHardness', {min: 0, max: 1, step: 0.01, presetKey: 'Post-vignetteHardness'})
  folder.addSeparator();

  folder.addInput(values, 'bloomEnable', {presetKey: 'Post-bloomEnable'})
  folder.addInput(values, 'bloomThreshold', {min: 0, max: 1, step: 0.01, presetKey: 'Post-bloomThreshold'})
  folder.addInput(values, 'bloomFactor', {min: 0, max: 2, step: 0.01, presetKey: 'Post-bloomFactor'})
  folder.addInput(values, 'bloomRadius', {min: 0, max: 1, step: 0.01, presetKey: 'Post-bloomRadius'})
  folder.addSeparator();

  folder.addInput(values, 'toneMappingEnable', {presetKey: 'Post-toneMappingEnable'})
  folder.addInput(
    values,
    'toneMappingMethod',{
    options: {
      linear: 'linear', 
      reinhard: 'reinhard', 
      filmic: 'filmic'
    },
    presetKey: 'Post-toneMappingMethod'
  })

  folder.addInput(values, 'toneMappingExposure', {min: 0, max: 2, step: 0.01, presetKey: 'Post-toneMappingExposure'})
  folder.addInput(values, 'toneMappingContrast', {min: -1, max: 1, step: 0.01, presetKey: 'Post-toneMappingContrast'})
  folder.addInput(values, 'toneMappingBrightness', {min: -1, max: 1, step: 0.01, presetKey: 'Post-toneMappingBrightness'})
  folder.addInput(values, 'toneMappingSaturation', {min: 0, max: 2, step: 0.01, presetKey: 'Post-toneMappingSaturation'})
  folder.addSeparator();

  folder.addInput(values, 'colorBalanceEnable', {presetKey: 'Post-colorBalanceEnable'})
  const cbLowFolder = folder.addFolder( {title: 'Low' });
  cbLowFolder.addInput(values.colorBalanceLow, '0', {min:-1, max: 1, step: 0.01, label: 'R', presetKey: 'Post-colorBalanceEnable-low-R'})
  cbLowFolder.addInput(values.colorBalanceLow, '1', {min:-1, max: 1, step: 0.01, label: 'G', presetKey: 'Post-colorBalanceEnable-low-G'})
  cbLowFolder.addInput(values.colorBalanceLow, '2', {min:-1, max: 1, step: 0.01, label: 'B', presetKey: 'Post-colorBalanceEnable-low-B'})
  const cbMidFolder = folder.addFolder( {title: 'Mid' });
  cbMidFolder.addInput(values.colorBalanceMid, '0', {min:-1, max: 1, step: 0.01, label: 'R', presetKey: 'Post-colorBalanceEnable-mid-R'})
  cbMidFolder.addInput(values.colorBalanceMid, '1', {min:-1, max: 1, step: 0.01, label: 'G', presetKey: 'Post-colorBalanceEnable-mid-G'})
  cbMidFolder.addInput(values.colorBalanceMid, '2', {min:-1, max: 1, step: 0.01, label: 'B', presetKey: 'Post-colorBalanceEnable-mid-B'})
  const cbHighFolder = folder.addFolder( {title: 'High' });
  cbHighFolder.addInput(values.colorBalanceHigh, '0', {min:-1, max: 1, step: 0.01, label: 'R', presetKey: 'Post-colorBalanceEnable-high-R'})
  cbHighFolder.addInput(values.colorBalanceHigh, '1', {min:-1, max: 1, step: 0.01, label: 'G', presetKey: 'Post-colorBalanceEnable-high-G'})
  cbHighFolder.addInput(values.colorBalanceHigh, '2', {min:-1, max: 1, step: 0.01, label: 'B', presetKey: 'Post-colorBalanceEnable-high-B'})

  folder.on('change',  (event) => {
    applyValues(event)
  })
}

function applyValues(value) {
  postprocessing.setPostProcessing(values)
}

const getValues = function(payload) {
  for (let value in payload) {
    values[value] = payload[value]
  }
}

export default {
  get values() {
    return values;
  },  
  get folder() {
    return folder;
  },
  get presetkeys() {
    return presetkeys
  },
  set values(newvalue) {
    return values = newvalue;
  },  
  buildGUI,
  getValues
}