<script>
import LightControl from './light-control.vue'

export default {
  name: "nmalight",
  components: {
    LightControl
  },
  props: {
  },
  computed: {
  },
  data() {
    return {
      tab: 0,
    }
  },     
  methods: {
  },
  mounted() {
  }
};
</script>

<template>
<v-card>
  <v-tabs
    v-model="tab"
  >
    <v-tab
      v-for="index in [0,1,2]"
      :key="index"
    >
      Light {{ index + 1 }}
    </v-tab>
  </v-tabs>

  <v-tabs-items v-model="tab">
    <v-tab-item
      v-for="index in [0,1,2]"
      :key="index"
      eager
    >
      <LightControl 
        :lightid="index"
      />
    </v-tab-item>
  </v-tabs-items>
</v-card>
</template>

<style lang="scss">
</style>
