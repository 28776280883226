import _common from '../editors/_common'

const state = {
  payload: {},
  all: {}
}

const getters = {
  ..._common.getters
}

const actions = {
}

const mutations = {
  ..._common.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}