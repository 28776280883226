import _base from './_base-module'
import templateData from 'ProjectData/template'

const state = {
  all: []
}

const getters = {
  ..._base.getters,
}

const actions = {
  fetchData ({ commit }) {
    commit('clearAll')
    if (templateData.camera) {
      for (let view of templateData.camera) {
        commit('addItem', view)
      }
    }
  }
}

const mutations = {
  ..._base.mutations,
  addItem (state, item) {
    state.all.push(item)
  },
  clearAll (state) {
    state.all = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
