<script>
import configure from '@/tools/configurator/configure'
import store from '../../store'

export default {
  name: 'liquidpresetmenu',
  components: {
  },
  props: {
    preset: Object,
    options: Array,
    name: String,
    liquid: Object
  },
  computed: {
    currentLiquidpreset () {
      const myLiquid = store.getters['liquid/getLiquidByProperty']('name', this.liquid.name)
      const myPresets = myLiquid.liquidpresets.filter(preset => preset.liquidname === this.preset.liquidname)
      return myPresets[0]
    },
     
  },
  methods: {
    setLiquidPresetOption (liquidpreset, option) {
      // using the store form the import helps keep the ui updated even when it's
      // not visible. e.g. when it's triggered from the states menu
      store.commit('liquid/updateSelectedPreset', {liquid: this.liquid, liquidpreset: liquidpreset, option: option})
      store.commit('liquid/updateLiquidsets', {liquid: this.liquid, liquidname: this.currentLiquidpreset.liquidname, payload: option.payload})
      // store.commit('materialList/updateTitle', {groupname: material.name, pickedOption: option.title, swatch: option.swatch, icon: option.icon})

      const myLiquid = store.getters['liquid/getLiquidByProperty']('name', this.liquid.name)
      configure.SetMaterialOption(myLiquid.material, myLiquid.material, myLiquid.liquidsets)
    },
    getIcon (option) {
      if (option.icon) {
        return (process.env.BASE_URL + option.icon)
      } else {
        return null
      }
    },     
    // getIconPath (icon) {
    //   const imagePath = require.context('../assets/icons/', true)
    //   // eslint-disable-next-line
    //   return API_URL + imagePath(icon, true)
    // }
  }
}
</script>

<template>

<div>
  <v-sheet
    class="d-flex flex-wrap pa-2"
    elevation="0"
    color="cardaccent2"
  >
    <v-sheet
      v-for="(option, index) in options" 
      :key="index" 
    >
      <v-avatar
        tile
        v-if="option.swatch" 
        :color="option.swatch" 
        @click="setLiquidPresetOption(preset, option)"
        size=62
      >  
      </v-avatar>
      
      <v-avatar
        tile
        v-if="option.icon" 
        @click="setLiquidPresetOption(preset, option)"
        size=62
      >
        <img
          :src="getIcon(option)"
        >      
      </v-avatar>
    </v-sheet>
  </v-sheet>
</div>



<!-- <md-menu-item @click="setLiquidPresetOption(currentLiquidpreset, option)">
  {{option.title}}
  <md-icon v-if="currentLiquidpreset.visible === option.title">check</md-icon>
  <md-button v-if="option.swatch != null" :style="option.swatch" class="md-icon-button md-raised"></md-button>
  <md-button v-if="option.icon != null" class="md-icon-button md-raised">
    <md-avatar> -->
      <!-- <img :src="getIconPath(option.icon)"> -->
    <!-- </md-avatar>
  </md-button>
</md-menu-item> -->
</template>

<style lang="scss" scoped>
  .md-menu {
    width: 300px;
    max-width: 100%;
    display: inline-block;
  }
</style>
