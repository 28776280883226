import player from './player'
import { vec3 } from 'gl-matrix'

const updateAnnotation = function (position3d) {
  return new Promise((resolve, reject) => {
    if (player.api) {
      // api.getWorldToScreenCoordinates(
      //   position3d,
      //   function(coord) {
      //     resolve(coord.canvasCoord)
      //   }
      // )
      player.api.getCameraLookAt(function (err, camera) {
        if (err) {
          console.log(err.stack)
        } else {
          // Figure out if the object is in front or behind
          let eye = vec3.fromValues(camera.position[0],camera.position[1],camera.position[2])
          const target = vec3.fromValues(camera.target[0],camera.target[1],camera.target[2])
          let pos = vec3.fromValues(position3d[0],position3d[1],position3d[2])
          vec3.subtract(target,target,eye)
          vec3.subtract(pos,pos,eye)
          const angle = vec3.angle(target,pos)
          // if this angle is PI, the coordinate is straight behind us. If the angle is 0
          // it's straight ahead. Here we hide items which are at about 30%
          if (angle < 1.0) {
            player.api.getWorldToScreenCoordinates(
              position3d,
              function(coord) {
                resolve(coord.canvasCoord)
              }
            )
          } else {
            // move an item off canvas
            resolve([-100,-100])
          }
        }
      })  
    } else {
      reject(new Error('API not found'))
    }
  })
}

// This method does raycasting. It's too heavy to do every frame,
// for multiple annotations on heavy scenes
const getAnnotationOcclusion = function (position3dend) {
  return new Promise((resolve, reject) => {
    if (player.api) {
      player.api.getCameraLookAt(function (err, camera) {
        if (camera) {
          player.api.pickFromScene(
            camera.position,
            position3dend,
            function(err, callback) {
              resolve(callback)
            }
          )
        }
      })
    } else {
      reject(new Error('API not found'))
    }
  })
}

const printAnnotations = function () {
  return new Promise((resolve, reject) => {
    if (player.api) {
      player.api.getAnnotationList(function(err, annotations) {
        for (let a of annotations) {
          console.log(a)
          console.log(a.name)
          console.log(a.position)
        }
        // console.log(annotations);
      })
    
    } else {
      reject(new Error('API not found'))
    }
  })  

}
window.configuratorapi.printAnnotations = printAnnotations
export default { updateAnnotation, getAnnotationOcclusion, printAnnotations }
