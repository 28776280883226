import { render, staticRenderFns } from "./liquid-option.vue?vue&type=template&id=3873e5ac&scoped=true&"
import script from "./liquid-option.vue?vue&type=script&lang=js&"
export * from "./liquid-option.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3873e5ac",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCard,VListItem,VListItemAction,VListItemContent,VListItemGroup,VListItemTitle,VSwitch})
