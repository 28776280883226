<script>
import menuSubGroup from './menusubgroup'
import menuOptions from './menu-options'

export default {
  name: 'menuGroup',
  components: {
    menuSubGroup,
    menuOptions,
  },
 
  props: {
    menu: Object
  },
  computed: {
    icon () {
      return this.menu.icon
    }
  }, 
}
</script>

<template>
  <v-expansion-panel
  >

    <v-expansion-panel-header 
      color="cardaccent"
      class="text-h6 font-weight-light"
    >
      <v-icon
        class="headericon"
      >
        {{menu.icon}}
      </v-icon>    
      {{ menu.title }}
    </v-expansion-panel-header>
    <v-expansion-panel-content  color="card" class="pa-0">
    <menuOptions 
      :menu="menu"
    />
    
    <div 
      v-if="menu.submenu"
    >
      <div 
        v-for="subitem in menu.submenu" 
        :key="subitem.name"
      >
        <menuSubGroup 
          :menu="subitem"
        />
      </div>
    </div>

    </v-expansion-panel-content>
  </v-expansion-panel>

</template>

<style lang="scss" scoped>
  .headericon {
    flex: 0 0 0;
    margin-right: 12px;
  }
</style>