<script>
import tweakpanelightoverlayitem from "./tweakpane-light-overlay-item.vue"
import nmatools from "@/tools/project/nma"
import cameratools from '@/tools/sketchfab/camera'

export default {
  name: 'tweakpaneoverlay',
  components: {
    tweakpanelightoverlayitem
  },
  props: {
    cameramoves: Boolean,
    // manipulatingSlider: Boolean
  },

  data: function () {
    return {
      origin: [-100, -100],
      resize: false
    }
  },  
  computed: {
    hasLightControls () {
      return this.$store.state.ui.editorvisible
      // const menu = this.$store.getters['menu/getAll']
      // const lightOption = menu.find(menuitem => {
      //   return menuitem.options.find(option => option.nmalight)
      // })
      // return lightOption ? true : false
    },
  },
  mounted: function () {
    this.tick()
  },
  methods: {
    tick () {
      // This tick is called every frame. If the camera is moving, we update the position
      // of the annotation. If the camera has just stopped, we update the occlusion
      // of the annotation but we don't update the position anymore.
      const self = this
      if (self.cameramoves || self.resize) {
        // if (!self.manipulatingSlider) {
          cameratools.getCamera()
          .then(camera => {
            const latlon = nmatools.getLatLonFromPos(camera.position)
            self.$store.commit('nmalight/setCameraProperty', {key: 'latlon', property: latlon})
            self.$store.commit('nmalight/setCameraProperty', {key: 'target', property: camera.target})
          })
          cameratools.getFov()
          .then(fov => {
            self.$store.commit('nmalight/setCameraProperty', {key: 'fov', property: fov})
          })
        // }

        nmatools.getScreenCoord([0,0,0])
        .then(result => {
          self.origin = [result[0], result[1]]
          self.resize = false
        })
      } else {
      }
      requestAnimationFrame(self.tick)
    },
    onResize () {
      this.resize = true
    }
  }

}
</script>

<template>
  <div 
    v-if="hasLightControls"
    id="tweakpanewrapper" 
    class="tweakpanewrapper"
    v-resize="onResize"
  >
      <div
        v-for="index in [0,1,2]"
        :key="index"
      >
        <tweakpanelightoverlayitem 
          :lightid="index"
          :origin="origin"
          :cameramoves="cameramoves"
        />
      </div>    
  </div>
</template>

<style lang="scss" scoped>
  .tweakpanewrapper {
    overflow: hidden;
    position: absolute;
    top: 0px;
    left: 0px;  
    z-index:3;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
</style>
