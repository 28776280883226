<script>
import screenfull from 'screenfull'
import tweakpane from '../../tools/tweakpane'

export default {
  name: 'desktoptopbar',
  components: {
  },    
  props: {
    headerlogo: String,
  },
  data: () => ({
    isFullscreen: false,
  }),
  computed: {
    desktopnav () {
      return this.$store.state.ui.desktopnav
    },
    editor () {
      return this.$store.state.ui.editor
    },
    editorvisible () {
      return this.$store.state.ui.editorvisible
    },
    scenes () {
      return this.$store.getters['scenes/getAll']
    },
    panoactive () {
      return this.$store.state.pano.panoActive
    },    
  },
  mounted () {
  },
  methods: {
    setDesktopModelpicker () {
      this.$store.commit('ui/setDesktopModelpicker', true)
    },
    showDesktopNav () {
      this.$store.commit('ui/setDesktopnav', true)
    },
    toggleEditorvisible () {
      this.$store.commit('ui/setEditorvisible', !this.editorvisible)
      if (this.editorvisible) {
        tweakpane.showEditor()
        this.$store.dispatch('nmalight/setLightmoves', {value: true})

      } else {
        tweakpane.hideEditor()
        this.$store.dispatch('nmalight/setLightmoves', {value: false})

      }
    },
    toggleFullscreen () {
      if (screenfull.isEnabled) {
        screenfull.toggle();
        screenfull.on('change', () => {
          this.isFullscreen = screenfull.isFullscreen
        })
      } else {
      }      
    }
  }
}
</script>

<template>
  <div
    class="desktoptopbar d-flex"
  >
    <v-btn
      class="ml-2 mt-2"
      fab
      elevation=2
      @click="showDesktopNav"
      v-if="!desktopnav"
    >
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>

    <v-btn
      class="ml-2 mt-2"
      fab
      elevation=2
      @click="setDesktopModelpicker"
      v-if="scenes.length > 1 && !panoactive"
    >
      <v-icon>mdi-menu</v-icon>
    </v-btn>

    <v-btn
      class="ml-2 mt-4"
      fab
      small
      plain
      @click="toggleFullscreen"
    >
      <v-icon >{{ isFullscreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen' }}</v-icon>
    </v-btn>

    <v-btn
      class="ml-2 mt-4"
      fab
      small
      plain
      v-if="editor"
      @click="toggleEditorvisible"
    >
      <v-icon >{{ editorvisible ? 'mdi-pencil-off' : 'mdi-pencil' }}</v-icon>
    </v-btn>

    <v-img 
      class="headerlogo mr-2 mt-2 ml-auto" 
      v-if="headerlogo" 
      height="60" 
      position="right"
      contain
      max-width="250"
      :src="headerlogo"
    >
    </v-img>
  </div>
</template>

<style lang="scss" scoped>
.desktoptopbar {
  position: absolute;
  z-index: 3;
  width: 100%;
}
.headerlogo {
  right: 0px;
  top: 0px;
}
</style>

