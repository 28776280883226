import _base from './_base-module'

const state = {
  all: [],
  currentScene: {},
  sceneLoaded: false
}

const getters = {
  ..._base.getters,
  getCurrentScene (state) {
    return state.currentScene
  },
  getsceneLoaded (state) {
    return state.sceneLoaded
  },
  getScene: (state) => (sceneuid) => {
    return state.all.find(scene => scene.sceneuid === sceneuid)
  }
}

const actions = {
  setCurrentScene ({ commit }, {scene}) {
    commit('setCurrentScene', scene)
  },
  setSceneLoaded ({ commit }) {
    commit('setLoadedState', true)
  },
  setSceneLoading ({ commit }) {
    commit('setLoadedState', false)
  }  
}

const mutations = {
  init (state, set) {
    state.all = []
    for (let item of set) {
      if (item.enabled === true || item.enabled === undefined) {
        state.all.push(item)
      }
    }
  },
  setCurrentScene (state, scene) {
    state.currentScene = scene
  },
  setLoadedState (state, loadState) {
    state.sceneLoaded = loadState
  }  
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
