<script>
import Tabs from "./Tabs.vue";
import CardCommercial from "../card-commercial.vue";

export default {
  name: "MobileNav",
  components: {
    Tabs,
    CardCommercial,
  },    
  props: {
    landscape: Boolean,
    scenetitle: String,
    projecttitle: String,
    contactcard: Boolean,
    summary: Boolean,
    pricing: Boolean,
  },
  data: () => ({
    menuPosition: "middle",
    swipeDirection: null,
  }),
  created () {
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    bottomnav: {
      get: function () { return this.$store.state.ui.bottomnav },
      set: function (newValue) { this.$store.dispatch('ui/setBottomnav', {isOpen: newValue}) }
    },
    tabsheight() {
      return 0
    },

    mobileheight () {
      if (this.$vuetify.breakpoint.height < this.$vuetify.breakpoint.xs) {
        return 300
      } else {
        return this.$vuetify.breakpoint.height/2
      }
    }
  },
  mounted () {
  },

  methods: {
    swipe(direction) {
      this.swipeDirection = direction;
      if (direction === "Up") {
        this.bottomnav = true
        window.scrollTo(0, 0);
      } else if (direction === "Down") {
        this.bottomnav = false
      }
    },
    toggleBottomNav() {
      this.bottomnav = !this.bottomnav
    },
  }
}
</script>

<template>
<div
  v-if="mobile"
>
  <v-card 
    class="tabs mt-n0" 
    tile
    flat
    v-if="!landscape"
    :height="bottomnav ? mobileheight : '88px'"
  >
    <v-toolbar 
      color="cardaccent2"
      class="px-4"
      height="56"
      @click="toggleBottomNav"
      v-touch="{ up: () => swipe('Up'), down: () => swipe('Down')}"
      v-ripple="{ class: `accent--text` }"
    >
      <div
        color="card"
        class="d-flex flex-column ml-0"
        style="max-width: 80%;"
      >
        <div class="text-truncate text-h5 font-weight-medium">{{ scenetitle }}</div>
        <div class="mt-n1 text-truncate text-sm-subtitle-1 font-weight-light text--secondary">{{ projecttitle }}</div>
      </div>

      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon v-if="bottomnav">mdi-chevron-down</v-icon>
        <v-icon v-else>mdi-chevron-up</v-icon>
      </v-btn>      
    </v-toolbar>
    <v-card
      color="card"
      class="overflow-auto"
      :height="mobileheight - 56"
      flat
      tile
    >
      <v-card-text class="pa-0">
        <Tabs/>
      </v-card-text>
    </v-card>
  </v-card>

  <CardCommercial
    v-if="!landscape"
    :contactcard="contactcard"
    :summary="summary"
    :pricing="pricing"

  />
</div>
</template>

<style lang="scss" scoped>
  .tabs {
    z-index: 1;
    transition: height 0.3s;
  }

  .tabscard {
    height: calc(50vh - 56px)
  }

  .v-toolbar {
    z-index: 2;
  }  
</style>

