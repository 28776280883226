<script>
// import configure from '@/tools/configurator/configure'
import presetgroupmenu from './menu'

export default {
  name: 'presetgroup',
  components: {
    presetgroupmenu
  },
  props: {
    optionname: String
  },
  data: () => ({
    expanded: null
  }),
  computed: {
    presetgroup () {
      return this.$store.getters['presetgroup/getItemByName'](this.optionname)
    },
    visibleOption () {
      return this.presetgroup.options.find(option => option.name === this.presetgroup.visible)
    },
    icon () {
      if (this.visibleOption.icon) {
        return (process.env.BASE_URL + this.visibleOption.icon)
      } else {
        return null
      }
    },    

  },
  methods: {
    highlight () {
      // just adding the click event adds a highlight to a menu item
    }
  },
  mounted () {
    if (this.presetgroup.expand) this.expanded = true
  },   
}
</script>

<template>

  <v-list-group
    v-if="presetgroup && presetgroup.options.length > 1"
    color='accent'
    v-model="expanded"
    
  >
    <template v-slot:activator>
      <v-list-item-avatar 
        v-if="visibleOption.swatch" 
        :color="visibleOption.swatch" 
        class="elevation-4"
        left
      />
      <v-list-item-avatar v-if="icon" class="elevation-4">
        <v-img :src="icon"/>
      </v-list-item-avatar>      

      <v-list-item-content two-line>
          <v-list-item-title v-text="presetgroup.title"></v-list-item-title>
          <v-list-item-subtitle>{{ visibleOption.title }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
      <v-divider></v-divider>
      <presetgroupmenu :presetgroup="presetgroup"/>

  </v-list-group>

</template>