<script>
import configure from '@/tools/configurator/configure'

export default {
  name: 'environmentmenu',
  props: {
    options: Array,
    name: String
  },
  computed: {
  },
  methods: {
    setEnvironment (option) {
      // using the store from the import helps keep the ui updated even when it's
      // not visible. e.g. when it's triggered from the states menu
      if (option.payload.theme === "dark") {
        this.$vuetify.theme.dark = true
      }
      if (option.payload.theme === "light") {
        this.$vuetify.theme.dark = false
      }      
      this.$store.commit('studiogroup/updateSelected', {group: this.name, option: option.name})
      configure.SetStudioGroup(option.payload)
    },    
  }  
}
</script>

<template>
<div>
  <v-sheet
    class="d-flex flex-wrap pa-2"
    elevation="0"
    color="white"
  >
    <v-sheet
      v-for="(option, index) in options" 
      :key="index" 
    >
      <v-avatar
        tile
        v-if="option.swatch" 
        :color="option.swatch" 
        @click="setEnvironment(option)"
        size=62
      >
      </v-avatar>

      <v-avatar
        tile
        v-if="option.icon" 
        @click="setEnvironment(option)"
        size=62
      >
        <v-img :src="option.icon"/>
      </v-avatar>
    </v-sheet>
  </v-sheet>
</div>
</template>