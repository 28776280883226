<script>
export default {
  name: 'splash',
  components: {

  },
  data: () => ({

  }),
  computed: {

  },

  methods: {
  },
  mounted () {
  }
}
</script>

<template>
<v-sheet class="loader">
      <v-row
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <v-col
          class="headline font-weight-light text-center"
          cols="12"
        >
          Loading 3D experience
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="primary"
            indeterminate
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
</v-sheet>
</template>

<style lang="scss" scoped>
.loader {
  overflow: hidden;
  position: absolute;
  z-index:3;
  height: 100%;
  width: 100%;
  pointer-events: none;    
//   left: 50%;
//   top: 50%;
//   transform: translate(-50%, -50%);
}
</style>
