<script>
import materialoption from './editors/material/material-option'
import optionhook from './editors/optionhook/optionhook'
import visibilitygroup from './editors/visibilitygroup/group'
import presetgroup from './editors/presetgroup/group'

import materialswappableoption from './materialswappable-option'
import viewoption from './editors/view/option'
import studiogroup from './editors/studiogroup/option'
import visibilitysingleoption from './visibilitysingle-option'
import animationoption from './animation-option'
import sensaroption from './sensar-option'
import transformoption from './transform-option'
import labelcard from './label-card'
import textcard from './text-card'
import liquidoption from './liquid/liquid-option'
import nmalightoption from './project/nma-light-option'
import cameraoption from './project/nma-camera-option'
import panooption from './pano-option'

export default {
  name: 'menuOptions',
  components: {
    animationoption,
    materialoption,
    materialswappableoption,
    sensaroption,
    viewoption,
    studiogroup,
    presetgroup,
    optionhook,
    visibilitygroup,
    visibilitysingleoption,
    transformoption,
    labelcard,
    textcard,
    liquidoption,
    nmalightoption,
    cameraoption,
    panooption
  },  
  props: {
    menu: Object,
    device: String
  },
  data () {
    return {
    }
  },  
  computed: {
    icon () {
      return this.menu.icon
    },

  },
  methods: {
    highlight () {
      // just adding the click event adds a highlight to a menu item
    },
 
  }  
}
</script>

<template>
<div v-if="menu.options">
  <v-list
    color="card"
    class="pa-0"
    
  >
  <div v-for="(option, index) in menu.options" :key="index">
    <sensaroption v-if="option.sensar" :optionname="option.sensar"/>
    <nmalightoption v-if="option.nmalight" :optionname="option.nmalight"/>
    <cameraoption v-if="option.camera" :optionname="option.camera"/>
    <animationoption v-if="option.animation" :optionname="option.animation"/>
    <materialoption v-if="option.material && !option.hidden" :optionname="option.material"/>
    <materialswappableoption v-if="option.materialswappable" :optionname="option.materialswappable"/>
    <viewoption v-if="option.view" :optionname="option.view"/>
    <studiogroup v-if="option.studiogroup && !option.hidden" :optionname="option.studiogroup"/>
    <labelcard v-if="option.label" :optionname="option.label"/>
    <textcard v-if="option.text" :optionname="option.text"/>
    <visibilitysingleoption v-if="option.visibilitysingle && !option.hidden" :optionname="option.visibilitysingle"/>
    <visibilitygroup v-if="option.visibilitygroup &&  !option.hidden" :optionname="option.visibilitygroup"/>
    <transformoption v-if="option.transform && !option.hidden" :optionname="option.transform"/>
    <presetgroup v-if="option.presetgroup && !option.hidden" :optionname="option.presetgroup"/>
    <optionhook v-if="option.optionhook && !option.hidden" :optionname="option.optionhook"/>
    <liquidoption v-if="option.liquid" :optionname="option.liquid"/>
    <panooption v-if="option.pano" :optionname="option.pano"/>
  </div>
  </v-list>

</div>    
</template>