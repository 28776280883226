<script>
import configure from '@/tools/configurator/configure'
import annotationTools from '@/tools/sketchfab/annotations'
import materialoption from './editors/material/material-menu'
import viewoption from './editors/view/option'
import visibilitysingleoption from './visibilitysingle-option'
import visibilitygroupoption from './editors/visibilitygroup/option'
import labeloption from './label-option'

export default {
  name: 'annotationmenu',
  components: {
    materialoption,
    viewoption,
    visibilitygroupoption,
    visibilitysingleoption,
    labeloption
  },
  props: {
    annotations: Array
  },
  data: function () {
    return {
      payloadactions: []
    }
  },  
  computed: {
    currentannotation () {
      return this.$store.getters['annotation/getCurrent']
    },
    currentannotationaction () {
      const type = Object.keys(this.currentannotation.payload.action)[0]
      return this.$store.getters[`${type}/getItemByName`](this.currentannotation.payload.action[type])
    },
    currentIndex () {
      return this.annotations.findIndex(item => item.name === this.currentannotation.name)
    },
    ui () {
      return this.$store.getters['ui/getState']
    },     
    theSwitch: {
      get: function () {
        return this.$store.getters['annotation/getShow']
      },
      set: function (newValue) {
        this.$store.commit('annotation/updateShow', newValue)
      }
    }    
  },
  mounted: function () {

  },
  methods: {
    highlight () {
      // just adding the click event adds a highlight to a menu item
    },
    pickAnnotation (annotation) {
      this.$store.commit('annotation/updateCurrent', annotation.name)




      // load the view (if present)
      if (annotation.payload.view) {
        const view = this.$store.getters['view/getItemByName'](annotation.payload.view)
        if (view) {
          configure.setView(view.payload)
        } else {
          console.log("View not found:", annotation.payload.view)
        }
      }
      if (this.ui.expandactiveannotation) {
        this.$store.commit('annotation/setVisible', {annotationName: annotation.name, visibility: true})
      }      
    },
    nextAnnotation () {
      let nextIndex = this.currentIndex + 1
      if (nextIndex >= this.annotations.length) nextIndex = 0
      this.pickAnnotation(this.annotations[nextIndex])
    },    
    previousAnnotation () {
      let previousIndex = this.currentIndex - 1
      if (previousIndex < 0) previousIndex = this.annotations.length - 1
      this.pickAnnotation(this.annotations[previousIndex])
    },    
    toggleSwitch () {
      this.theSwitch = !this.theSwitch
    }    
  },
}
</script>

<template>
<v-card
  color="card"
>
<v-list-item-group>
  <v-list-item 
    @click="toggleSwitch"
  >
    <template 
      v-slot:default="{ active, toggle }"
    >
      <v-list-item-content >
        <v-list-item-title>
          Show Hotspots
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action >
        <v-switch 
          color="accent" 
          @click.stop="toggle" 
          :input-value="active" 
          v-model="theSwitch"
        >
        </v-switch>
      </v-list-item-action>
    </template>
  </v-list-item>
</v-list-item-group>

  <v-menu :close-on-content-click="true" offset-y max-height="500">
    <template v-slot:activator="{ on }">
      <v-list rounded>
      <v-list-item @click="highlight" v-on="on">
        <v-list-item-content two-line>
          <v-list-item-title v-text="currentannotation.title.menu"></v-list-item-title>
          <v-list-item-subtitle>{{ Object.keys(currentannotation.payload.action)[0] }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-icon >mdi-menu-right</v-icon>
      </v-list-item>
    </v-list>
    </template>
    <v-list>
      <v-list-item v-for="(option, index) in annotations" :key="index" @click="pickAnnotation(option)" >
        <v-list-item-title v-text="option.title.menu"></v-list-item-title>
      </v-list-item>      
    </v-list>  
  </v-menu>

  <div class="d-flex justify-space-between pa-2">
  <v-btn text @click="previousAnnotation()">
    <v-icon left>mdi-chevron-left</v-icon>Previous
  </v-btn>
  <v-btn text @click="nextAnnotation()">
    Next <v-icon right>mdi-chevron-right</v-icon>
  </v-btn>
  </div>
  <v-divider></v-divider>
  <v-list rounded>
    <materialoption v-if="currentannotation.payload.action.material" :material="currentannotationaction"/>
    <viewoption v-if="currentannotation.payload.action.view" :optionname="currentannotation.payload.action.view"/>
    <visibilitysingleoption v-if="currentannotation.payload.action.visibilitysingle" :optionname="currentannotation.payload.action.visibilitysingle"/>
    <visibilitygroupoption v-if="currentannotation.payload.action.visibilitygroup" :optionname="currentannotation.payload.action.visibilitygroup"/>
    <labeloption v-if="currentannotation.payload.action.label" :optionname="currentannotation.payload.action.label"/>
  </v-list>
</v-card>
</template>
