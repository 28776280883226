class Animation {
    constructor(animation) {
        this.name = ''
        this.title = 'Unknown animation'
        this.startframe = 0
        this.endframe = 99
        this.playbackspeed = 50
        this.currentframe = 0


        if (animation) {
            this.name = animation.name ? animation.name : ''
            this.title = animation.title ? animation.title : ''
            this.startframe = animation.payload.startframe ? animation.payload.startframe : 0
            this.endframe = animation.payload.endframe ? animation.payload.endframe : 99
            this.playbackspeed = animation.payload.playbackspeed ? animation.payload.playbackspeed : 50
            this.currentframe = animation.payload.currentframe ? animation.payload.currentframe : 0
        }
    }
}

export default Animation