<script>
import optionhookmenu from './optionhook-menu'

export default {
  name: 'optionhook',
  components: {
    optionhookmenu
  },
  props: {
    optionname: String
  },
  data: () => ({
    expanded: null
  }),
  computed: {
    optionhook () {
      return this.$store.getters['optionhook/getItemByName'](this.optionname)
    },
    visibleOption () {
      return this.optionhook.options.find(option => option.name === this.optionhook.visible)
    },
    icon () {
      if (this.visibleOption.icon) {
        return (process.env.BASE_URL + this.visibleOption.icon)
      } else {
        return null
      }
    },    
  },
  methods: {
  },
  mounted () {
    if (this.optionhook?.expand) {
      this.expanded = true
    }
  },   
}
</script>

<template>
  <v-list-group
    v-if="optionhook && !optionhook.hidden && optionhook.options.length > 1"
    color='accent'
    v-model="expanded"
  >
    <template v-slot:activator>
      <v-list-item-avatar 
        v-if="visibleOption.swatch" 
        :color="visibleOption.swatch" 
        class="elevation-4"
        left
      />
      <v-list-item-avatar v-if="icon" class="elevation-4">
        <v-img :src="icon"/>
      </v-list-item-avatar>      

      <v-list-item-content two-line>
          <v-list-item-title v-text="optionhook.title"></v-list-item-title>
          <v-list-item-subtitle>{{ visibleOption.title }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>

    <v-divider></v-divider>
    <optionhookmenu :optionhook="optionhook"/>
  </v-list-group>
</template>