<script>
import configure from '@/tools/configurator/configure'
import datatools from '../../../tools/datatools'

export default {
  name: 'optionhookmenu',
  components: {
  },
  data: () => ({
    visible: ''
  }),
  props: {
    optionhook: Object
  },
  computed: {
  },
  methods: {
    setOptionhook (option) {
      this.$store.commit('optionhook/updateSelected', {group: this.optionhook.name, option: option.name})
      this.visible = option.name
      // REDO ALL PRESETGROUPS WHICH USE THIS HOOK
      const presetgroups = this.$store.getters['presetgroup/getAll']
      presetgroups.forEach(presetgroup => {
        const hookedOption = this.$store.getters['presetgroup/getOptionFromGroupByHook'](presetgroup.name, presetgroup.visible, this.optionhook.name, option.name)
        if (hookedOption) configure.SetPresetGroup(presetgroup, hookedOption)
      });

      // Make menus visible or hidden in case they subscribe to this optionhook
      datatools.material.hookMenuVisibility()
      datatools.optionhook.hookMenuVisibility()
    }
  },
  mounted () {
    this.visible = this.optionhook.visible
  }
}
</script>

<template>
  <v-list color="cardaccent2">
    <v-list-item 
      v-for="(option, index) in optionhook.options"
      :key="index"
      :option="option"
      @click="setOptionhook(option)"
    >
      <v-list-item-title 
        v-text="option.title"
      ></v-list-item-title>

      <v-avatar 
        v-if="option.swatch" 
        :style="option.swatch" 
        tile
      >
      </v-avatar>

      <v-list-item-icon class="my-2" v-if="option.name === visible">
        <v-icon color="accent">mdi-check-circle</v-icon>
      </v-list-item-icon>    
                
      <v-list-item-icon class="my-2" v-else>
        <v-icon color="cardaccent">mdi-circle-outline</v-icon>
      </v-list-item-icon>              
    </v-list-item>
  </v-list>  
</template>