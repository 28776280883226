import _base from './_base-module'
import MenuItem from '@/classes/MenuItem'

const Model = MenuItem

const state = {
  all: []
}

const getters = {
  ..._base.getters
}

const actions = {
  setCurrentScene ({ commit }, {scene}) {
    commit('setCurrentScene', scene)
  },
  fetchData ({ commit }, {menu}) {
    commit('init', menu)
  }
}

const mutations = {
  init(state, set) {
    return _base.mutations.init(state, set, Model);
  },
  setCurrentScene (state, scene) {
    state.currentScene = scene
  },
  setLoadedState (state, loadState) {
    state.sceneLoaded = loadState
  }  
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
