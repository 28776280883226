<script>
import ModelList from "../model-list.vue"

export default {
  name: 'mobilemodelpicker',
  components: {
    ModelList
  },
  computed: {
    dialog () {
      return this.$store.getters['ui/getItemByName']('mobilemodelpicker')
    },
  },
}
</script>

<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-top-transition"
  >
    <ModelList/>
  </v-dialog>
</template>

<style lang="scss" scoped>
</style>