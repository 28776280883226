const state = {
  all: {}
}

const getters = {
  getTexture: (state) => (uid) => {
    return state.all[uid]
  },
  getTextureByName: (state) => (name) => {
    for (let uid in state.all) {
      if (state.all[uid].name === name) {
        return state.all[uid]
      }
    }
  },
  getTextureNames(state) {
    let names = []
    for (let uid in state.all) {
      console.log("uid", uid);
      names.push(state.all[uid].name)
    }
    console.log("names", names);
    return names
  }
}

const actions = {
  storeTexture ({ commit }, texture) {
    commit('storeTexture', texture)
  },
}

const mutations = {
  storeTexture(state, texture) {
    // console.log('storing', texture.name, texture.uid)
    state.all[texture.uid] = texture
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
