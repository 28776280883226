import _common from "./_common"
import store from 'Store'

const type = 'studiogroup'

class studiogroup {
  title = ''
  name = ''
  visible = null
  hidden = false
  init = false
  options = []

  constructor (data) {
    this.title = data.title ?? ''
    this.name = data.name ?? ''
    this.visible = data.visible ?? null
    this.options = data.options ?? []

    if (this.options.length > 0) {
      this.visible = this.visible ?? this.options[0].name
    }
  }

  setHidden(value) {
    this.hidden = value ?? false
  }

  setInit(value) {
    this.init = value ?? false
  }
}

const assembleOptionPayload = function (studiogroupOption) {
  let newPayload = {}
  if (studiogroupOption.payload.environment && studiogroupOption.payload.environment.basedon) {
    const template = store.getters['environment/getItemByName'](studiogroupOption.payload.environment.basedon)
    if (template) {
      newPayload.environment =  {payload: {...template.payload, ...studiogroupOption.payload.environment.payload}}
    } else {
      console.log("No template found for environment", studiogroupOption.payload.environment.basedon)
    }
  }
  
  if (studiogroupOption.payload.lighting && studiogroupOption.payload.lighting.basedon) {
    const template = store.getters['lighting/getItemByName'](studiogroupOption.payload.lighting.basedon)
    if (template) {
      newPayload.lighting = {payload: {...template.payload, ...studiogroupOption.payload.lighting.payload}}
    } else {
      console.log("No template found for lighting", studiogroupOption.payload.lighting.basedon)
    }
  }
  
  if (studiogroupOption.payload.background && studiogroupOption.payload.background.basedon) {
    const template = store.getters['background/getItemByName'](studiogroupOption.payload.background.basedon)
    if (template) {
      newPayload.background = {payload: {...template.payload, ...studiogroupOption.payload.background.payload}}
    } else {
      console.log("No template found for background", studiogroupOption.payload.background.basedon)
    }
  }
  
  if (studiogroupOption.payload.post && studiogroupOption.payload.post.basedon) {
    const template = store.getters['post/getItemByName'](studiogroupOption.payload.post.basedon)
    if (template) {
      newPayload.post = {payload: {...template.payload, ...studiogroupOption.payload.post.payload}}
    } else {
      console.log("No template found for postprocessing", studiogroupOption.payload.post.basedon)
    }
  }
  
  if (studiogroupOption.payload.theme) {
    newPayload.theme = studiogroupOption.payload.theme
  }
  return newPayload
}

const assembleData = function (menu) {
  const menuItems = _common.getMenuItems(menu, type)

  const studiogroups = menuItems.map(menuItem => {
    const template = _common.getTemplateItem(menuItem[type], type)
  
    if (template) {
      let option = new studiogroup(template)
      option.setHidden(menuItem.hidden)
      option.setInit(menuItem.init)
      return option
    } else {
      return null
    }
  })

  studiogroups.forEach(studiogroup => {
    studiogroup.options.forEach(option => {
      option.payload = assembleOptionPayload(option)

    })
  })

  return studiogroups
}

const fetchData = function (menu) {
  _common.fetchMenuData(assembleData, menu, type)
}

const importData = function (data) {
  return _common.importGroupData(data, type)
}

const exportData = function () {
  return _common.exportGroupData(type)
}

const exportDataPretty = function () {
  return _common.exportGroupDataPretty(type)
}

export default {
  fetchData,
  importData,
  exportData,
  exportDataPretty
}