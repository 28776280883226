<script>
import configure from '@/tools/configurator/configure'

export default {
  name: 'textcard',
  components: {
  },
  props: {
    optionname: String
  },
  data: () => ({
    show: false,
  }),  
  computed: {
    theOption () {
      return this.$store.getters['text/getTextitem'](this.optionname)
    },
    theText: {
      get: function () {
        const item = this.$store.getters['text/getText'](this.optionname)
        return item
      },
      set: function (newValue) {
        this.$store.dispatch('text/setText', {name: this.optionname, text: newValue})
        this.textChange()
      }
    }
  },
  methods: {
      textChange () {
          configure.SetTextOption(this.theOption)
      }
  }  
}
</script>

<template>
    <v-card flat>
        <!-- <v-card-actions class="pa-0">
            <v-card-title class="subtitle-1 py-1" @click="show = !show">{{ theOption.title }}</v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="show = !show">
                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
        </v-card-actions> -->

        <!-- <v-expand-transition> -->
            <!-- <div v-show="show"> -->
                <v-card-text>
                <v-text-field
                    :label="theOption.title"
                    @change="textChange"
                    v-model="theText"
                ></v-text-field>
                </v-card-text>
            <!-- </div> -->
        <!-- </v-expand-transition>  -->
    </v-card>
</template>