<script>
import configure from '@/tools/configurator/configure'
import panomenu from './pano-menu.vue'

export default {
  name: 'PanoExit',
  components: {
    panomenu
  },
  data: () => ({
  }),
  watch: {
    desktopnav: {
      immediate: true,
      handler: function (val, oldVal) {
        if (this.panoactive && val) {
          this.exitPano()
        }
      }
    }
  },
  computed: {
    currentPano () {
      return this.$store.getters['pano/getItemByName'](this.$store.state.pano.currentPano)
    },
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    desktopnav () {
      return this.$store.state.ui.desktopnav
    },
    panoactive () {
      return this.$store.state.pano.panoActive
    },
    avatarsize () {
      return this.mobile ? 60 : 80
    }
  },
  methods: { 
    exitPano () {
      const exitView = this.$store.getters['view/getItemByName'](this.currentPano.exitview)
      configure.exitPano(this.currentPano.exitcameraconstraints, exitView.payload)
      this.$store.commit('pano/panoActive', false)
      if (this.mobile) {
        this.$store.commit('ui/setBottomnav', true)
      } else {
        this.$store.commit('ui/setDesktopnav', true)
      }
    }
  },
  mounted () {
  }
}
</script>

<template>
  <div
    class="pano-overlay d-flex justify-center"
  >
    <v-sheet
      class="pano-bar d-flex"
    >
      <panomenu :options="currentPano.options" :name="currentPano.name" :avatarsize="avatarsize"/>
      <v-btn
        depressed
        tile
        :width="avatarsize"
        :height="avatarsize"
        @click="exitPano"
        class="mt-2 mr-2"
        color="accent"
      >    
        Exit
      </v-btn>
    </v-sheet>
  </div>
  <!-- <v-btn
    fab
    absolute
    :small="mobile"
    color="accent"
    class="camera-fab mr-2 mb-2"
    @click="exitPano"
  >
    <v-icon 
      :large="!mobile"
    >mdi-close</v-icon>
  </v-btn> -->
</template>

<style lang="scss" scoped>
.pano-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
}
.pano-bar {
  bottom: 0px;
  position: absolute;
  z-index: 3;
}
</style>
