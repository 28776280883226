import store from '../../store'
import player from './player'
import textureTools from './textures'

let materialList


const storeMaterialList = function () {
  return new Promise((resolve, reject) => {
    if (player.api) {
      player.api.getMaterialList(function (err, theMaterialList) {
        if (err) {
          console.log(err.stack)
        } else {
          materialList = theMaterialList
          theMaterialList.forEach(material => {
            store.dispatch('sf_materials/storeMaterial', material)
          });
          resolve()
        }
      })
    } else {
      reject(new Error('API not found'))
    }
  })
}

// const getMaterialList = function () {
//   return materialList
// }

const getMaterialFromScene = function (materialName) {
  return new Promise(resolve => {
    if (player.api) {
      player.api.getMaterialList(function (err, theMaterialList) {
        resolve(theMaterialList.find(mtl => mtl.name === materialName))
      })
    }
  })
}

const setMaterial = function (theMaterial) {
  return new Promise ((resolve, reject) => {
    if (player.api) {
      player.api.setMaterial(theMaterial, function () {
        store.dispatch('sf_materials/storeMaterial', theMaterial)
        resolve()
      })
    }else{
      reject()
    }
  })
}

const offsetUV = function (mateiralName, channelName, offset) {
  const mtl = getSceneMaterial(mateiralName)
  player.api.setUVOffset(mtl, channelName, offset[0], offset[1])
}

const scaleUV = function (materialName, channelName, offset) {
  const mtl = getSceneMaterial(materialName)
  return new Promise(resolve => {
    player.api.setUVScale(mtl, channelName, offset[0], offset[1], () => {
      resolve()
    })
  })
}

const SetLiquidtextureValue = function (basetextureUrl, liquidkey, liquidValue) {
  var textureurl = basetextureUrl
  var regex = new RegExp((liquidkey), 'g')
  var newSet = liquidValue
  basetextureUrl = textureurl.replace(regex, newSet)
  return basetextureUrl
}



const setMaterialChannelsOptions = async function (theMaterial, theChannels, liquidPayload) {
  // multiple channels provided in an object
  // each channel has an object with properties such as factor or color
  // multiple properties per channel are to be expected
  for (let channelName in theChannels) {
    if (channelName === 'cullFace') {continue}
    if (channelName === 'Matcap') {continue}
    for (let propertyName in theChannels[channelName]) {
      switch (propertyName) {
        case 'texture': {
          if (theChannels[channelName].texture !== null && theChannels[channelName].texture !== undefined) {

            // Load a texture which is already present in the sketchfab scene
            if (theChannels[channelName].texture.indexOf('http') === -1) {
              const textureUid = textureTools.getTextureUidByName(theChannels[channelName].texture)
              if (textureUid !== undefined) {
                const uv = theChannels[channelName].uv || 0
                addTextureToMaterial(theMaterial, channelName, textureUid, uv, theChannels[channelName].filter)
              } else {
                removeTextureFromMaterial(theMaterial, channelName)
              }
            }
          } else {
            removeTextureFromMaterial(theMaterial, channelName)
          }
          break
        }
        case 'textureurl': {
          // Load a texture from outside the sketchfab scene, but bundled with the app
          // The file needs to be CORS enabled. We're using an environment variable to set the
          // root of the path. API_URL is an environment varialbe and represents the url
          // where the site is running. Here we're using the imagePath context to find the
          // textures
          if (theChannels[channelName].textureurl !== '') {
            // eslint-disable-next-line
            const theUrl = process.env.BASE_URL + theChannels[channelName].textureurl
            await getExternalTextureUid(theChannels, channelName, theUrl)
            const textureUid = textureTools.getTextureUidByName(theUrl)
            const uv = theChannels[channelName].uv || 0
            addTextureToMaterial(theMaterial, channelName, textureUid, uv)
          } else {
            removeTextureFromMaterial(theMaterial, channelName)
          }
          break
        }
        case 'liquidtexture': {
          const theUrl = theChannels[channelName].liquidtexture
          // apply a liquid payload to this URL and populate all anchors
          let liquidUrl = theUrl
          for (let liquidset of liquidPayload) {
            liquidUrl = this.SetLiquidtextureValue(liquidUrl, liquidset.liquidname, liquidset.payload)
          }
          this.getExternalTextureUid(theChannels, channelName, liquidUrl).then(result => {
            const uv = theChannels[channelName].uv || 0
            this.addTextureToMaterial(theMaterial, channelName, result, uv)
          })
          break
        }
        case 'colorrgb': 
        case 'tintrgb': 
        case 'refractionColorrgb': {
          break
        }
        case 'tint':
        case 'refractionColor':
        case 'color': {
          const theColor = colorNormalize1(theChannels[channelName][propertyName])
          if (theMaterial.channels[channelName]) {
            theMaterial.channels[channelName][propertyName] = theColor
          }
          // color and texture values in most channels are mutually exclusive
          // if both are encountered, sketchfab will throw an error
          // the SubsurfaceTranslucency channel can hold a texture AND color at the same time
          // in that channel we shouldn't clear either channel
          if (channelName !== 'SubsurfaceTranslucency' && theMaterial.channels[channelName] != null) {
            delete theMaterial.channels[channelName].texture
            delete theMaterial.channels[channelName].UVTransforms
          }
          break
        }
        case 'factor': {
          theMaterial.channels[channelName].factor = theChannels[channelName].factor
          break
        }
        case 'textureuid': {
          break
        }
        case 'UVScale': {
          await scaleUV(theMaterial.name, channelName, theChannels[channelName].UVScale)
          break
        }
        default: {
          theMaterial.channels[channelName][propertyName] = theChannels[channelName][propertyName]
          break
        }
      }
    }
  }
}

const convertColorToArray = function (color) {
  return [color.r, color.g, color.b]
}

/**
 * Normalize an RGB value from 0-255 to 0-1. Applies gamma correction
 * @param {array} color an array with three elements: rbg
 * @returns a 0-1 normalized array
 */
const colorNormalize1 = function (color) {
  const theColor = [0, 0, 0]
  if (color === null || color === undefined) return theColor
  theColor[0] = Math.pow(color[0] / 255, 2.2)
  theColor[1] = Math.pow(color[1] / 255, 2.2)
  theColor[2] = Math.pow(color[2] / 255, 2.2)
  return theColor
}

/**
 * Normalize an RGB value from 0-1 to 0-255. Applies gamma correction
 * @param {array} color an array with three elements: rgb
 * @returns a 0-255 normalized array
 */
const colorNormalize255 = function (color) {
  const theColor = [0, 0, 0]
  if (color === null || color === undefined) return theColor
  theColor[0] = Math.pow(color[0], (1/2.2)) * 255
  theColor[1] = Math.pow(color[1], (1/2.2)) * 255
  theColor[2] = Math.pow(color[2], (1/2.2)) * 255
  return theColor
}

const addTextureToMaterial = function (theMaterial, channelName, textureUid, uv, filter) {
  // once we have the texture uid, apply it to the material
  if (textureUid != null) {
    // in most channels, textures and colors are mutually exclusive
    if (channelName !== 'SubsurfaceTranslucency') {
      delete theMaterial.channels[channelName].color
    }

    const theTexture = textureTools.makeTexture(textureUid, uv, filter)
    theMaterial.channels[channelName].texture = theTexture
  }
}

const removeTextureFromMaterial = function (theMaterial, channelName) {
  theMaterial.channels[channelName].texture = null
}

const getExternalTextureUid = function (materialChannels, channelName, textureUrl) {
  const textureUid = textureTools.getTextureUidByName(textureUrl)
  return new Promise((resolve, reject) => {
    if (!textureUid) {
      // It's a new texture, we haven't added it before. Let's upload it to Sketchfab
      textureTools.addTexture(textureUrl)
      .then(result => {
        // Store the uid of the added texture in the material for later reference. This avoids having to upload the same
        // texture over and over
        materialChannels[channelName].textureuid = result
        resolve(result)
      })
    } else {
      // in case the uid hasn't been added to the material properly before
      if (materialChannels[channelName].textureuid === null || materialChannels[channelName].textureuid === undefined) {
        if (textureUid) materialChannels[channelName].textureuid = textureUid
      }
      // We've already added this texture. Use the existing texture uid
      textureTools.updateTexture(textureUrl, materialChannels[channelName].textureuid)
      .then(result => {
        materialChannels[channelName].textureuid = result
        resolve(result)
      })
    }
  })
}

const getSceneMaterial = function (materialName) {
  const result = materialList.find(mtl => mtl.name === materialName)
  return result
}




export default {
  storeMaterialList,
  // getMaterialList,
  get materialList() {
    return materialList;
  },    
  setMaterial,
  offsetUV,
  getSceneMaterial,
  addTextureToMaterial,
  removeTextureFromMaterial,
  SetLiquidtextureValue,
  setMaterialChannelsOptions,
  convertColorToArray,
  colorNormalize255,
  colorNormalize1,
  getMaterialFromScene
}
