import player from './player'
import camera from './camera'

const getScreenShot = function (width, height, callback) {
  if (player.api) {
    player.api.getScreenShot(width, height, 'image/png', function (err, result) {
      if (callback != null && err === null) callback(result)
      if (callback == null && err === null) {
        window.open(result, "_blank");    
      }
    })
  }
}

const getScreenshotPromise = function (width, height, cam) {
//   return new Promise((resolve, reject) => {
//     function callback() {
//       // sketchfab callback for camera doesnt do its job
//       // so we have to wait a bit before taking the photo
//       setTimeout(function () {
//         player.api.getScreenShot(width, height, 'image/png', function (err, result) {
//           let imageString = result.split(',')[1];
//           resolve(imageString);
//         });
//       }, 1000);
//     }

//   if (cam) {
//     console.log("cam", cam);
//     player.api.setFov(camera.fov, function () {
//       player.api.setCameraLookAt(camera.eye, camera.lookat, 0, callback);
//     })
//   } else {
//     callback()
//   }
// })

  return new Promise((resolve, reject) => {
    player.api.getScreenShot(width, height, 'image/png', function (err, result) {
      resolve(result);
    });
  })
}


export default { 
  getScreenShot,
  getScreenshotPromise,
}
