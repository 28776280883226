import player from '../sketchfab/player';
import store from '@/store'
import lightingtools from '@/tools/sketchfab/lighting'
import { vec3, mat4, glMatrix, quat } from 'gl-matrix'
import datatools from '../datatools'

const initLights = function(promises) {
  [0,1,2].map(id => {
    promises.push(addLight(id))
  })
}

const addLight = function (id) {
  return new Promise(resolve => {
    lightingtools.getLight(id)
    .then(light => {
      store.dispatch('nmalight/setLight', {id: id, light: light})
      resolve()
    })
  })
}

const getScreenCoord = function (position3d) {
  return new Promise((resolve, reject) => {
    let api = player.api
    if (position3d === null) resolve([-100,-100])
    if (api && position3d) {
      // api.getWorldToScreenCoordinates(
      //   position3d,
      //   function(coord) {
      //     resolve(coord.canvasCoord)
      //   }
      // )
      api.getCameraLookAt(function (err, camera) {
        if (err) {
          console.log(err.stack)
        } else {
          // Figure out if the object is in front or behind
          let eye = vec3.fromValues(camera.position[0],camera.position[1],camera.position[2])
          const target = vec3.fromValues(camera.target[0],camera.target[1],camera.target[2])
          let pos = vec3.fromValues(position3d[0],position3d[1],position3d[2])
          vec3.subtract(target,target,eye)
          vec3.subtract(pos,pos,eye)
          const angle = vec3.angle(target,pos)
          // if this angle is PI, the coordinate is straight behind us. If the angle is 0
          // it's straight ahead. Here we hide items which are at about 30%
          if (angle < 1.0) {
            api.getWorldToScreenCoordinates(
              position3d,
              function(coord) {
                resolve(coord.canvasCoord)
              }
            )
          } else {
            // move an item off canvas
            resolve([-100,-100])
          }
        }
      })  
    }
  })
}

const glMatrixToSfMatrix = function (matrix) {
  let sf_matrix = []
  sf_matrix[0] = matrix[4]
  sf_matrix[1] = matrix[5]
  sf_matrix[2] = matrix[6]
  sf_matrix[3] = matrix[7]

  sf_matrix[4] = -matrix[0]
  sf_matrix[5] = -matrix[1]
  sf_matrix[6] = -matrix[2]
  sf_matrix[7] = -matrix[3]

  sf_matrix[8] = matrix[8]
  sf_matrix[9] = matrix[9]
  sf_matrix[10] = matrix[10]
  sf_matrix[11] = matrix[11]

  sf_matrix[12] = matrix[12]
  sf_matrix[13] = matrix[13]
  sf_matrix[14] = matrix[14]
  sf_matrix[15] = matrix[15]

  return sf_matrix  
}

/*
Convert a lat and lon value to a matrix. This assumes a certain radius
and an up vector. We're also assuming the target is at the origin
*/
const setLightMatrixFromLatLon = function (latlon, radius, target) {
  const lat = glMatrix.toRadian(-latlon[0])
  const lon = glMatrix.toRadian(latlon[1])
  const R = radius ? radius : -1.5
  const origin = vec3.create()
  const greenwich = vec3.fromValues(0, R, 0)
  vec3.rotateX(greenwich, greenwich, origin, lat)
  vec3.rotateZ(greenwich, greenwich, origin, lon)
  const T = target ? vec3.fromValues(target[0], target[1], target[2]) : vec3.create()
  const up = vec3.fromValues(0, 0, 1)
  const matrix = mat4.create()
  mat4.targetTo(matrix, greenwich, T, up)

  return glMatrixToSfMatrix(matrix)

}

// const setLightMatrix = function (sf_pos) {
//   const pos = vec3.fromValues(sf_pos[0], sf_pos[1], sf_pos[2])
//   const target = vec3.create()
//   const up = vec3.fromValues(0, 0, 1)
//   const matrix = mat4.create()
//   mat4.targetTo(matrix, pos, target, up)

//   return glMatrixToSfMatrix(matrix)
// }

const getLatLonFromPos = function (sf_pos) {
  const pos = vec3.fromValues(-sf_pos[1], sf_pos[0], -sf_pos[2])
  const target = vec3.create()
  const up = vec3.fromValues(0, 0, 1)
  const matrix = mat4.create()
  mat4.targetTo(matrix, pos, target, up)

  let q = quat.create()
  mat4.getRotation(q, matrix)
  let angles = quatToSpherical(q)
  const degAngles = [angles.lat * 180/Math.PI -90, angles.lon * 180/Math.PI]
  return degAngles
}

const quatToSpherical = function(quat){
  // Convert a quat to lat lon values
  let look = vec3.fromValues(0, 0, 1)
  vec3.transformQuat(look, look, quat)
  vec3.normalize(look, look)
  if(Math.abs(look[0]) < 0.001) look[0] = 0.001;

  var angles = {};
  var radius = 1;

  angles.lat = Math.acos(look[2]/radius);
  angles.lon = Math.atan2(look[1] , look[0]);

  return angles;
}

const getSetupJson = function () {
  /*
  Get all menu settings and make a json object we can reuse later
  */
  let menuoptions = []
  let exportObject = {}
  store.state.menu.all.forEach(menu => {
    menu.options.forEach(option => {
      menuoptions.push(option)
    })
  })
  menuoptions.forEach(option => {
    if (option.nmalight) {
      const item = store.getters['nmalight/export']
      exportObject.nmalight = item
    }
    if (option.material) {
      exportObject.material = datatools.material.exportData()
    }
    if (option.visibilitysingle) {
      exportObject.visibilitysingle = datatools.visibilitysingle.exportData()
    }
    if (option.visibilitygroup) {
      exportObject.visibilitygroup = datatools.visibilitygroup.exportData()
    }
    if (option.presetgroup) {
      exportObject.presetgroup = datatools.presetgroup.exportData()
    }
  })
  return exportObject
}

export default {
  initLights,
  getScreenCoord,
  // setLightMatrix,
  setLightMatrixFromLatLon,
  getLatLonFromPos,
  getSetupJson
}