import visibilitysingle from "./visibilitysingle"
import visibilitygroup from "./visibilitygroup"
import material from "./material"
import optionhook from "./optionhook"
import presetgroup from "./presetgroup"
import studiogroup from "./studiogroup"
import pano from "./pano"
import view from "./view"
import player from "./player"
import background from "./background"
import environment from "./environment"
import postprocessing from "./postprocessing"
import lighting from "./lighting"
import annotation from "./annotation"
import materialoptionslists from "./materialoptionslists"

export default {
  visibilitysingle,
  visibilitygroup,
  material,
  optionhook,
  presetgroup,
  studiogroup,
  pano,
  view,
  player,
  background,
  environment,
  postprocessing,
  lighting,
  annotation,
  materialoptionslists,
}