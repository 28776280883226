<script>
import ModelPickerItem from './modelpicker-item'

export default {
  name: 'modellist',
  components: {
    ModelPickerItem,
  },
  computed: {
    scenes () {
      return this.$store.getters['scenes/getAll']
    },
    ui () {
      return this.$store.getters['ui/getState']
    },
  },
  mounted () {
  },
  data: () => ({
  }),
  methods: {
    closeModelpicker () {
      this.$store.commit('ui/updateItemByName', {key: 'mobilemodelpicker', value: false})
      this.$store.commit('ui/updateItemByName', {key: 'desktopmodelpicker', value: false})
    }
  }
}
</script>

<template>
  <v-card
    class="overflow-x-hidden fill-height"
    tile
    color="card"
  >
    <v-toolbar
      color="cardaccent2"
      class="px-4"
    >
      <v-btn
        color="card"
        fab
        depressed
        small
        @click="closeModelpicker"
        class="ml-n2"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>  

      <div class="ml-3">
        <div class="text-truncate text-h5 font-weight-medium">Pick a model</div>
      </div>
    </v-toolbar>

    <v-row class="pa-5">
      <v-col v-for="scene in scenes" :key="scene.id" cols="12" class="mobilemodelpicker">
        <ModelPickerItem :scene="scene"/>
      </v-col>
    </v-row>
  </v-card>
</template>

<style lang="scss" scoped>
</style>