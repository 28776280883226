import _common from './_common'

const state = {
  all: {}
}

const getters = {
  ..._common.getters,
  getOptionFromGroupByHook: (state, getters) => (presetgroupname, optionname, hookname, hookoptionname) => {
    const presetgroup = getters.getItemByName(presetgroupname)// state.all.find(item => item.name === presetgroupname)
    if (presetgroup) {
      let presetgroupoptions = presetgroup.options.filter(o => o.name === optionname)
      if (presetgroupoptions.length > 0) {
        // there are multiple options for the optionname. Verify they're linked
        // by the same optionhook
        if (presetgroupoptions.every(item => item.optionhook?.name === hookname)) {
          const hookedOption = presetgroupoptions.find(item => item.optionhook.option === hookoptionname)
          if (hookedOption) {
            return hookedOption
          }
        }
      }
    }
    return null
  },
}

const actions = {
}

const mutations = {
  ..._common.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
