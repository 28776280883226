<script>
import CameraControl from './camera-control.vue'

export default {
  name: "nmacamera",
  components: {
    CameraControl
  },
  props: {
  },
  computed: {
  },
  data() {
    return {
    }
  },     
  methods: {
  },
  mounted() {
  }
};
</script>

<template>
<v-card>
  <CameraControl />
</v-card>
</template>

<style lang="scss">
</style>
