<script>
import visibilitygroupmenu from './menu'

export default {
  name: 'visibilitygroup',
  components: {
    visibilitygroupmenu
  },
  props: {
    optionname: String
  },
  computed: {
    visibilitygroup () {
      return this.$store.getters['visibilitygroup/getItemByName'](this.optionname)
    },
    visibleOption () {
      return this.visibilitygroup.options.find(option => option.name === this.visibilitygroup.visible)
    },
    icon () {
      if (this.visibleOption.icon) {
        return (process.env.BASE_URL + this.visibleOption.icon)
      } else {
        return null
      }
    },    
  },
  methods: {
  }  
}
</script>

<template>
  <v-list-group
    v-if="visibilitygroup && visibilitygroup.options.length > 1"
    color='accent'
  >
    <template v-slot:activator>
      <v-list-item-avatar 
        v-if="visibleOption.swatch" 
        :color="visibleOption.swatch" 
        class="elevation-4"
        left
      />
      <v-list-item-avatar v-if="icon" class="elevation-4">
        <v-img :src="icon"/>
      </v-list-item-avatar>      

      <v-list-item-content two-line>
          <v-list-item-title v-text="visibilitygroup.title"></v-list-item-title>
          <v-list-item-subtitle>{{ visibleOption.title }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <v-divider></v-divider>
    <visibilitygroupmenu :visibilitygroup="visibilitygroup"/>

  </v-list-group>

</template>