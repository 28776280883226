import _common from "./_common"

const type = 'lighting'

const fetchData = function (scene) {
  _common.compileTemplateData(type)
  _common.fetchSceneData(_common.assembleSingularData, scene, type)
}

export default {
  fetchData,
}