<script>
import configure from '@/tools/configurator/configure'
import animationTools from '@/tools/sketchfab/animation'

export default {
  name: 'animationoption',
  components: {
  },
  props: {
    optionname: String
  },
  computed: {
    animation () {
      return this.$store.getters['animations/getItemByName'](this.optionname)
    }
  },
  methods: {
    play () {
        animationTools.getAnimations().then(animations => {
            if (animations.length > 0) animationTools.play()
        })
    },
    pause () {
        animationTools.getAnimations().then(animations => {
            if (animations.length > 0) animationTools.pause()
        })
    }
  }  
}
</script>

<template>
<v-card color="grey darken-2">
    <v-card-title> {{ animation.title }} </v-card-title>


  <!-- <v-card-text>
    <div>
      Hi, I'm Klaas and I've built this demo. Get in touch to work with a team of specialists. We're experienced in building 3d configurators from A to Z.
    </div>
  </v-card-text> -->

  <v-card-actions>
    <v-spacer></v-spacer>   
     <v-btn class="ma-2" text icon>
        <v-icon>mdi-skip-backward</v-icon>
      </v-btn>
     <v-btn class="ma-2" text icon>
        <v-icon>mdi-skip-previous</v-icon>
      </v-btn>
     <v-btn class="ma-2" text icon @click="pause">
        <v-icon>mdi-pause</v-icon>
      </v-btn>
     <v-btn class="ma-2" text icon @click="play">
        <v-icon>mdi-play</v-icon>
      </v-btn>
     <v-btn class="ma-2" text icon>
        <v-icon>mdi-skip-next</v-icon>
      </v-btn>
     <v-btn class="ma-2" text icon>
        <v-icon>mdi-skip-forward</v-icon>
      </v-btn>
  </v-card-actions>
</v-card>

</template>