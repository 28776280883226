<script>
import configure from '@/tools/configurator/configure'

export default {
  name: 'presetgroupmenu',
  components: {
  },
  props: {
    presetgroup: Object
  },
  data: () => ({
    visible: ''
  }),
  computed: {
    theOptions () {
      let self = this
      // THIS CAN BE SIMPLER

      // the list of preset options is filtered by an optionhook. If there are multiple options with the same name
      // we take a look if there's an optionhook available to figure out which option to show
      let options = this.presetgroup.options
      let myOrderedArray = options.reduce(function (accumulator, currentValue, index) {
        const idx = accumulator.findIndex(item => item.name === currentValue.name)
        if (idx === -1) {
          accumulator.push(currentValue)
        } else {
          // a preset with this name has already collected. Check if there's an optionhook to determine
          // which preset we should use
          if (accumulator[idx].optionhook?.name === currentValue.optionhook?.name) {
            // get the optionhook these options share
            const theHook = self.$store.getters['optionhook/getItemByName'](currentValue.optionhook.name)
            if (theHook.visible === currentValue.optionhook.option) {
              accumulator[idx] = currentValue
            }
          }
        }
        return accumulator
      }, [])      
      return myOrderedArray
    },

 
  },
  methods: {
    setPresetOption (option) {
      if (option.theme === "dark") {
        this.$vuetify.theme.dark = true
      }
      if (option.theme === "light") {
        this.$vuetify.theme.dark = false
      }
      this.$store.commit('presetgroup/updateSelected', {group: this.presetgroup.name, option: option.name})
      this.visible = option.name
      configure.SetPresetGroup(this.presetgroup, option)
    }    
  },
  mounted () {
    this.visible = this.presetgroup.visible
  }
}
</script>

<template>
  <v-list color="cardaccent2">
    <v-list-item
      v-for="(option, index) in theOptions"
      :key="index"
      @click="setPresetOption(option)"
    >
      <v-list-item-title 
        v-text="option.title"
      ></v-list-item-title>

      <v-avatar 
        v-if="option.swatch" 
        :style="option.swatch" 
        tile
      >
      </v-avatar>

      <v-list-item-icon class="my-2" v-if="option.name === visible">
        <v-icon color="accent">mdi-check-circle</v-icon>
      </v-list-item-icon>    
                
      <v-list-item-icon class="my-2" v-else>
        <v-icon color="cardaccent">mdi-circle-outline</v-icon>
      </v-list-item-icon>              
    </v-list-item>
  </v-list>  
</template>