import _base from './_base-module'
import templateData from 'ProjectData/template'
import Animation from '@/classes/Animation'
const Model = Animation

const state = {
  all: []
}

const getters = {
  ..._base.getters 
}

const actions = {
    fetchData ({ commit }) {
        commit('clearAll')
        templateData.animations && templateData.animations.forEach(item => {
            commit('addItem', item)
        })
    }
}

const mutations = {
    ..._base.mutations,
    addItem (state, item) {
        state.all.push(new Model(item))
    },
    clearAll (state) {
       state.all = []
    }  
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
