<script>
import configure from '@/tools/configurator/configure'

export default {
  name: 'viewoption',
  components: {
  },
  props: {
    optionname: String
  },
  computed: {
    view () {
      // console.log('this.optionname', this.optionname)
      // const myview = this.$store.getters['views/getItemByName'](this.optionname)
      // console.log('myview', myview)
      // return myview
      return this.$store.getters['view/getItemByName'](this.optionname)
    }
  },
  methods: {
    setView () {
      configure.setView(this.view.payload)
    }
  }  
}
</script>

<template>
    <v-list-item v-if="view !== undefined" @click="setView">
      <v-list-item-content two-line>
        <v-list-item-title v-text="view.title"></v-list-item-title>
        <v-list-item-subtitle>Camera view</v-list-item-subtitle>
      </v-list-item-content>
      <v-icon >mdi-menu-right</v-icon>
    </v-list-item>
</template>