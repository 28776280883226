import _common from "./_common"

const type = 'player'

// const assembleData = function (scene) {
//   const project = scene[type]
//   const template = _common.getTemplateItem(scene[type].template, type)
//   const library = _common.getLibraryItem(template.library, type)

//   let payload = {}
//   if (library) {
//     payload = {...library.payload}
//   }
//   if (template) {
//     payload = {...payload, ...template.payload}
//   }
//   if (project) {
//     payload = {...payload, ...project.payload}
//   }
//   return payload
// }

const fetchData = function (scene) {
  _common.fetchSceneData(_common.assembleSingularData, scene, type)
}

export default {
  fetchData,
}