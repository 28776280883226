import tweakpane from '../../tweakpane'
import environment from '../environment';

let folder = null

let values = {
  import: '',
  enable: true,
  uid: '',
  rotation: 0,
  exposure: 0,
  shadowEnabled: false,
  lightIntensity: 0,
}

let presetkeys = [
  'Environment-enable',
  'Environment-uid',
  'Environment-rotation',
  'Environment-exposure',
  'Environment-shadowEnabled',
  'Environment-lightIntensity',
]

const buildGUI = function() {


  // Environment Logs
  const allEnvironmentTextures = {
    // Klaas specific environments
    'Klaas_syferfontein_1d_clear_2k': '9a04bc42ea76440296b0db140ac8c850',
    'Klaas_Assembled_environment_004': 'ebc114da108b47b183e48d96f1026f2c',
    'Klaas_cambridge_2k': 'd53fce477b4643b2a6a017aefc1faaec',
    'Klaas_aircraft_workshop_01_2k_desat': 'af72d80dad4f4be7848447fb415950e8',
    'Klaas_skylit_garage_2k': '695494745a0b49acaf69e141e57cb411',
    'Klaas_canary_wharf_2k': 'c507fd403ebb491caa63de60205bff49',
    'Klaas_studio010': 'bd299bce6f95498dbe9060cf7c47bf47',
    'Klaas_between_bridges_2k': '9832dc782e824b42a4bfbb4561f8ce01',
    'Klaas_Studio_set2_013_KFJR': '870d47a05c1040fa931109cd31e2b7b2',
    'Klaas_modern_buildings_2k': 'ff8b45fbffbb4d908caa0f37f50280cd',
    'Klaas_studio008small': '8fb9caf4ea934dfca12e2cb60fe52a2b',
    'Klaas_skate_park_2k': 'ee429e1a91f847bbb5d02070d46bb680',
    'Klaas_under_bridge_2k': 'b07c025a6cfa4d4d940196474803768e',
    'Klaas_Studio_set2_02_KFJR': 'cee23bc323c34d60a68cc0bc542cfb73',
    'Klaas_quattro_canti_2k': '3c39ea87e7fc4168b032da0192b723f0',
    'Klaas_quarry_01_2k': 'eff0e231bab04d7aa74477b5c7b30c72',
    'Klaas_schadowplatz_2k': 'dc7053d53632402e8b2c18252fd56fa0',
    'Klaas_studio019': '245e1485944e47e4ac991e5fab7e1e9f',
    'Klaas_cirrus_006': '2977bdb9e7e845c08c6a43032caac0c6',
    'Klaas_old_bus_depot_2k': '9fcaa835b90640399554adc819aebb56',
    'Klaas_studio_small_03_2k': '539e7b2e39374d009803c5e3bdd9367d',
    'Klaas_studio024': 'b628efe3c1a449d1ade1a6f230319b89',
    'Klaas_syferfontein_0d_clear_two_suns_desat_2k': 'e408f72bc0284b81a60207b7e44c6911',
    'Klaas_machine_shop_03_2k_desat': '1adefb35df054306a6bf17ad30a533af',
    'Klaas_machine_shop_02_2k_desat': 'fa346108f11c4d48b343c16ceb810684',
    'Klaas_Studio_set2_25_KFJR_desat': 'dc85cabd2199498b854eb9d7e55051ca',
    'Klaas_paul_lobe_haus_2k': '0c138602de2f452b8eca61a83965ed2c',
    'Klaas_syferfontein_0d_clear_2k': 'd7d26c79b3b540359fc1b8e08bf37f54',
    'Klaas_wasteland_clouds_2k': '16aa22a4c65a458991d5febede118144',
    'Klaas_Studio_set2_014_KFJR': 'b25a4df4eda44d05b9afd0dbafc9ef10',
    'Klaas_windowStudio_2k': '1229635c2e0d473c93a6a5b788cb58d8',
    'Klaas_dresden_station_night_2k': 'c8ca1d670de4401984be01d9a79b0ea9',
    'Klaas_autoshop_01_2k_desat': '1754e402c06b44fa8b6ba39fe47f383e',
    'Klaas_studio021': '7e618627a7944a87af7f9472d7797ed3',
    'Klaas_monks_forest_2k_desat': '553a95d7ef414897b74db3319c47d32d',
    'Klaas_Assembled_environment': '03498aae1a4541cc908ecc20b736f384',
    
    // Mirari specific environments
    'Mirari_cirrus_002': '7ebe53ff0bd949d9b591624d2dc0ae4e',
    'Mirari_cirrus_006': '233979328b7a40fb998212349271315c',
    'Mirari_cirrus_007': '31574c7cbfbb4b23ba3abe23ae6907f0',
    'Mirari_DH-SL-06_2K': 'c3b37f7278dc468abdfc6cee77f92640',
    'Mirari_DH-SL-04_2k': 'edda5f2228d3472b86368bb0983edceb',
    'Mirari_hanger_linden_1': '80021f7755624813ab82841a98afbdc7',
    'Mirari_Hangar_1b_modified_2k': '044bd42907654423bfd8dd831dc4a086',
    'Mirari_HDRI - Airplane Hangar Interior 1b - EXR - 2048x1024': '08ae03895f2a468fbfa1ea4034137525',
    'Mirari_HDRI - Airplane Hangar Interior 7b - EXR - 2048x1024': '22111d1345674cedb642d2b166e2e7ce',
    'Mirari_ma-EUG_1_2K': '95e1d50d40d34abca36b2039a567b508',
    'Mirari_HDRI - Airplane Hangar Interior 7b - EXR - 2048': 'ff5c2618ebb6429893b2ccfe0d1ca63d',
    'Mirari_quarry_04_1k': '3b2941987f0f45a3a6dfc7238b76adb2',
    'Mirari_ma-EUG_1_4K': '2d54c56d5f2946be85ff992c83e3463e',

    // KLAAS TEAMS environments
    'ma-PHA_4385_Abo': 'a8b6d355c0fc48bd952052518600364b',
    'Assembled_environment_004': 'f476ebd2ecd847ffa9ed1b3fa02f7442',
    'dikhololo_night_2k': '0bc44ae191f344b1a4e6931d2efc6068',
    'syferfontein_0d_clear_two_suns_desat_2k': 'acbf86b69c114c6b813a00a11dc48391',
    'spiaggia_di_mondello_2k': '2ca655236f5a44c3932c87ce9304fd02',
    'ulmer_muenster_4k': '8d135a0986374d838736d747297ab29b',

    // SKETCHFAB ENVIRONMENTS
    'Studio Soft': '08468d6fcf9846039cc3e51accddd9f9',
    'royal_esplanade_4k_desaturated': 'd106177604ef44cf8cdc539e4d942423',
    'Trinitatis Church': 'e00dc642058b4176a4aaa449ea8ad5f8',
    'Treasure Island': '5e7120378b00431ea18151e97a8366ec',
    'Muir Wood': '41192cc664484a0fa565da3361d10c9c',
    'Kirby Cove': '8653449395004fd58820874bfff93ce7',
    'Gareoult': 'e871dd2920334a0f9c3107a00da3c24a',
    'Bus Garage': '80fba9759ab94307a782f38d359b8ea8',
    'Urban Exploring Interior': 'c281dff366844cbc8b33179337037f42',
    'Terrace Near the Granaries': '16cdcddf277d423d99094f8db0c731e9',
    'Glazed Patio by Restaurant': '2fa15e0b1cee45a5b02605a288934e1b',
    'Gdansk Shipyard Buildings': '3e2ad3e1f1ea47679f045a7eb0e6af49',
    'Cave Entry in the Forest': '268447ca0a954f44b06d1387863d114e',
    'Abandoned Sanatorium': '2e9c3708feb74137b0436f946b8b66b8',
    'St. Nicholas Church': '675ff0f13b474c62947097939fe9aa1e',
    'Road in Tenerife Mountain': '749a0594343e4ff9a2875cb411d0ad1a',
    'Road in Dordogne': '4eda3e78ed214dc8852c439dc64b9c9d',
    'Protestant Church': 'df5b82c5ccec42cbb9c5ba9528f0be52',
    'Industrial Room': '9190e8da70694ef3b9d1d0c01541917e',
    'In Front of Chapel': '78a54c2fd53a4ba4891a4bbdac7f30f7',
    'Studio': 'df380da788ee444885722735039b0c09',
    'Queen Mary Chimney': 'cd5e5b1607d844cdb928e96ff9c36b5c',
    'Popcorn Lobby': '491ddabd93aa49ecb5ad36538f7d00e3',
    'Milky Way': '102d22e28ca34190a8470402ccdc35d3',
    'Winter Forest': '4024128cf8904b69946e891caac5f305',
    'Tropical Ruins': '5335c7c5c2434866ac8a442157f24f5e',
    'Tropical Beach': 'b83e3a8794ae47e0b4039e7fdb031602',
    'Topanga Forest': '591b2c2db5744df1803a09d477f5a2c7',
    'Tokyo Big Sight': '2a016b232e444ef3a6ba323c51aa5063',
    'Malibu Overlook': '02751cd893a14f3986fb17a90245f64f',
    'Footprint Court': 'd348dfd2a8104ab0b8528f885d645eb3',
    'Ditch River': '956bb00775a044e7afdd1c17f6bdab63',
    'Bryant Park': 'e73867d210de4bc2b5eb261738cf3e79',
    'Pine Tree Arch': 'e2aa1ab3582c4feab7371baf1e4cd734',
    'Studio 2': 'efd4ef0fc18a4ec0a1313d782fade965'
  }
  folder = tweakpane.pane.addFolder({
    title: 'Environment',
    hidden: true
  })

  const btnExport = folder.addButton({
    title: 'Export Environment',
  })
  btnExport.on('click', () => {
    values.import = tweakpane.exportJSON('environment')
    tweakpane.updateGui()
  })  
  const btnImport = folder.addButton({
    title: 'Import Environment',
  })
  btnImport.on('click', () => {
    tweakpane.refreshing = true
    tweakpane.pane.importPreset(JSON.parse(values.import));
    tweakpane.refreshing = false
    applyValues(null)
  })  
  folder.addInput( values, 'import')

  folder.addInput(values, 'enable', {presetKey: 'Environment-enable'})
  folder.addInput(values, 'uid', {
    options: allEnvironmentTextures,
    presetKey: 'Environment-uid'
  })
  folder.addInput(values, 'rotation', {min: 0, max: Math.PI * 2, step: 0.1, presetKey: 'Environment-rotation'})
  folder.addInput(values, 'exposure', {min: 0, max: 15, step: 0.01, presetKey: 'Environment-exposure'})
  folder.addInput(values, 'shadowEnabled', {presetKey: 'Environment-shadowEnabled'})
  folder.addInput(values, 'lightIntensity', {min: 0, max: 5, step: 0.01, presetKey: 'Environment-lightIntensity'})
  // environmentFolder.add(environmentGUI, 'bias', 0, 0.5)

  folder.on('change', (event) => applyValues(event))


}

const getValues = function(payload) {
  for (let value in payload) {
    values[value] = payload[value]
  }
}

function applyValues (value) {
  environment.setEnvironment(values, true)
}

export default {
  get values() {
    return values;
  },  
  get folder() {
    return folder;
  },
  get presetkeys() {
    return presetkeys
  },
  set values(newvalue) {
    return values = newvalue;
  },  
  buildGUI,
  getValues
}