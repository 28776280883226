<script>
import configure from '@/tools/configurator/configure'
import store from '../../store'

export default {
  name: 'liquidManual',
  components: {
  },
  props: {
    liquidname: String,
    liquid: Object,
    disabled: Boolean
  },
  data: function () {
    return {
      customColors: null
    }
  },
  computed: {
    theLiquidManual () {
      const myLiquid = store.getters['liquid/getLiquidByProperty']('name', this.liquid.name)
      return myLiquid.liquidmanuals.find(manual => manual.liquidname === this.liquidname)
    },
  },
  methods: {
    applyLiquid (liquidManual) {
      // store.commit('liquid/updateCurrentPreset', {name: this.liquid.name, liquidname: this.manual.liquidname, option: null})
      store.commit('liquid/updateLiquidsets', {liquid: this.liquid, liquidname: this.theLiquidManual.liquidname, payload: this.customColors})

      const myLiquid = store.getters['liquid/getLiquidByProperty']('name', this.liquid.name)
      configure.SetMaterialOption(myLiquid.material, myLiquid.material, myLiquid.liquidsets)
    }
  }
}
</script>

<template>

<v-card>
  <v-card-text>
  <v-text-field
    persistent-hint
    autocomplete="off"
    :label="theLiquidManual.title"
    value=""
    hint="Separate with comma's: green,blue,red"
    v-model="customColors"
  ></v-text-field>
  </v-card-text>
  <v-card-actions>
    <v-btn 
      depressed
      @click="applyLiquid(theLiquidManual)"
    >
      Apply {{theLiquidManual.title}}
    </v-btn>
  </v-card-actions>
</v-card>

<!-- <div>
  <md-field>
    <label>{{theLiquidManual.title}}</label>
    <md-input v-model="customColors"></md-input>
    <span class="md-helper-text">Separate with comma's: green,blue,red</span>
  </md-field>
  <md-button class="md-raised" @click="applyLiquid(theLiquidManual)">Apply {{theLiquidManual.title}}</md-button>
</div> -->
</template>

<style lang="scss" scoped>
</style>
