<script>
import configure from "@/tools/configurator/configure"
import colorparse from 'color-parse'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import datatools from '../../tools/datatools'

export default {
  name: 'modelsummary',
  components: {
  },
  computed: {
    scenes () {
      return this.$store.getters['scenes/getAll']
    },
    ui () {
      return this.$store.getters['ui/getState']
    },
    dialog () {
      return this.$store.getters['ui/getItemByName']('commercialbar')
    },
    commercialbar: {
      get: function () { return this.$store.state.ui.commercialbar },
      set: function (newValue) { this.$store.dispatch('ui/setCommercialbar', {value: newValue}) }
    },      
    screenshot() {
      return this.$store.state.ui.screenshot
    },    
    currentscene () {
      return this.$store.getters['scenes/getCurrentScene']
    },
    sceneurl () {
      let url = `${process.env.BASE_URL}#/?scene=${this.currentscene.sceneuid}`
      return url
    },
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    width () {
      if (this.mobile) {
        return '100%'
      } else {
        return '400px'
      }
    },
    materialOptions () {
      return datatools.material.exportDataPretty()
    },
    visibilitysingleOptions () {
      return datatools.visibilitysingle.exportDataPretty()
    },
    visibilitygroupOptions () {
      return datatools.visibilitygroup.exportDataPretty()
    },
  },
  mounted () {
  },
  data: () => ({
    snackbar: false,
  }),
  methods: {
    setCommercialbar () {
      this.$store.dispatch('ui/setCommercialbar', {value: false})
    },
    createUrl () {
      let url = `${process.env.BASE_URL}#/?scene=${this.currentscene.sceneuid}`
      if (this.ui.queryview) url += `&view=${this.ui.queryview}`
      if (this.ui.export) url += `&setup=${this.ui.export}`
      return url
    },
    clickUrl () {
      const url = this.createUrl()
      navigator.clipboard.writeText(url);
      this.snackbar = true
    },
    rgbToHex(rgbValues) {
      let hex = rgbValues.map(int => {
        const hex = parseInt(int).toString(16);
        return (hex.length==1) ? "0"+hex : hex;
      })
      hex = "#"+hex.join("");
      return hex
    },
    urlToBase64(url) {
      return new Promise(resolve => {
        let img = new Image();
        img.src = url;
        // img.crossOrigin = 'Anonymous';
        img.onload = function(){
          let canvas = document.createElement('canvas')
          let ctx = canvas.getContext('2d')
          canvas.height = img.height;
          canvas.width = img.width;
          ctx.drawImage(img, 0, 0);
          let dataURL = canvas.toDataURL('png');
          canvas = null;
          resolve(dataURL);
        };
      })
    },
    async getPdf () {
      let dd = {
        pageSize: 'A4',
        pageOrientation: 'portrait',
        
        content: [
          {
            columns: [
              {
                width: '*',
                text: this.currentscene.scenetitle,
                style: 'title'
              },

            ]
          },
          {
            text: this.currentscene.header,
            style: 'subheader'
          },
          {
            text: this.currentscene.subheader,
            style: 'subheader'
          },
          {
            image: this.screenshot, width: 400
          },
        ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 10]
          },
          title: {
            fontSize: 36,
            bold: false,
            margin: [0, 0, 0, 10]
          },
          subheader: {
            fontSize: 16,
            bold: false,
            margin: [0, 10, 0, 5]
          },
          tableExample: {
            margin: [0, 5, 0, 15]
          },
          tableHeader: {
            bold: true,
            fontSize: 13,
            color: 'black'
          },
          link: {
            margin: [0, 5, 0, 15],
            decoration: 'underline',
            color: 'blue',
          },
        },
        defaultStyle: {
          fontSize: 12
        }
      }

      if (this.ui.headerlogo) {
        dd.content[0].columns.push(
          {
            width: '120',
            image: await this.urlToBase64(process.env.BASE_URL + this.ui.headerlogo)
          }
        )
      }

      dd.content.push({ text: 'Open in configurator', link: this.createUrl(), style: 'link'})
      dd.content.push({qr: this.createUrl(), fit: 180, pageBreak: 'after'})
      dd.content.push({text: 'Options', style: 'title'})

      if (this.materialOptions.length > 0) {
        let table = {style: 'tableExample', layout: 'lightHorizontalLines'}
        table.table = {
          headerRows: 1, 
          widths: ['*', '*', 50],
          body: [[
          {text: 'Materials', style: 'tableHeader'},
          {text: 'Choice', style: 'tableHeader'},
          {text: '', style: 'tableHeader'},
        ]]}
        this.materialOptions.forEach(item => {
          const rgb = colorparse(item.swatch)
          const hex = this.rgbToHex(rgb.values)
          table.table.body.push([item.item, item.option, {fillColor: hex, text: ''}])
        })
        // dd.content.push({text: 'Material choices', fontSize: 14, bold: true, margin: [0, 20, 0, 8]})
        dd.content.push(table)
      }

      if (this.visibilitysingleOptions.length > 0) {
        let table = {style: 'tableExample', layout: 'lightHorizontalLines'}
        table.table = {
          headerRows: 1, 
          widths: ['*'],
          body: [[
          {text: 'Options', style: 'tableHeader'},
        ]]}
        this.visibilitysingleOptions.forEach(item => {
          if (item.visible) {
            table.table.body.push([item.option])
          }
        })

        // dd.content.push({text: 'Material choices', fontSize: 14, bold: true, margin: [0, 20, 0, 8]})
        dd.content.push(table)
      }

      if (this.visibilitygroupOptions.length > 0) {
        let table = {style: 'tableExample', layout: 'lightHorizontalLines'}
        table.table = {
          headerRows: 1, 
          widths: ['*', '*'],
          body: [[
          {text: 'Option groups', style: 'tableHeader'},
          {text: 'Choice', style: 'tableHeader'},
        ]]}
        this.visibilitygroupOptions.forEach(item => {
          table.table.body.push([item.item, item.option])
        })

        // dd.content.push({text: 'Material choices', fontSize: 14, bold: true, margin: [0, 20, 0, 8]})
        dd.content.push(table)
      }
      
      
      pdfMake.createPdf(dd).open();

    }
  }
}
</script>

<template>
  <v-navigation-drawer
    v-model="commercialbar"
    absolute
    temporary
    :width="width"
  >  
    <v-card
      class="overflow-x-hidden fill-height"
      tile
      color="card"
    >
      <v-toolbar
        color="cardaccent2"
        class="px-4"
      >
        <v-btn
          color="card"
          fab
          small
          depressed
          @click="setCommercialbar"
          class="ml-n2"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>  

        <div class="ml-3">
          <div class="text-truncate text-h5 font-weight-medium">Summary</div>
        </div>
      </v-toolbar>

      <v-text-field
        v-model="sceneurl"
        filled
        label="Copy URL"
        @click="clickUrl"
        class="mx-2 mt-2"
      ></v-text-field>

      <v-sheet
        color="cardaccent"
        max-height="512px"
        class="ma-4 elevation-4"
        :aspect-ratio="1/1"
      >
        <v-img
          :src=screenshot
        >
        </v-img>
      </v-sheet>

      <v-btn
        class="ml-4"
        @click="getPdf"
      >
        Create a PDF
      </v-btn>
    </v-card>
    
    <v-snackbar
      v-model="snackbar"
      timeout=2000
    >
      URL copied to clipboard
      <template v-slot:action="{ attrs }">
        <v-btn
          color="accent"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>   
  </v-navigation-drawer>  
</template>

<style lang="scss" scoped>
</style>