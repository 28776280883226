import player from './player'
import materials from './materials';
import { vec3, mat4, mat3, glMatrix, quat } from 'gl-matrix'

const glMatrixToSfMatrix = function (matrix) {
  let sf_matrix = []
  sf_matrix[0] = matrix[4]
  sf_matrix[1] = matrix[5]
  sf_matrix[2] = matrix[6]
  sf_matrix[3] = matrix[7]

  sf_matrix[4] = -matrix[0]
  sf_matrix[5] = -matrix[1]
  sf_matrix[6] = -matrix[2]
  sf_matrix[7] = -matrix[3]

  sf_matrix[8] = matrix[8]
  sf_matrix[9] = matrix[9]
  sf_matrix[10] = matrix[10]
  sf_matrix[11] = matrix[11]

  sf_matrix[12] = matrix[12]
  sf_matrix[13] = matrix[13]
  sf_matrix[14] = matrix[14]
  sf_matrix[15] = matrix[15]

  return sf_matrix  
}

const quatposToMatrix = function (inputquat, pos) {
  let m4 = mat4.create()
  const q = quat.fromValues(inputquat.x, inputquat.y, inputquat.z, inputquat.w)
  mat4.fromQuat(m4, q)
  m4[12] = pos.x
  m4[13] = pos.y
  m4[14] = pos.z
  return glMatrixToSfMatrix(m4)
}

const setLight = function (lightId, options) {
  if (options.quat && options.pos) {
    const matrix = quatposToMatrix(options.quat, options.pos)
    options.matrix = matrix
  }

  if (options.colorrgb) {
    options.color = materials.colorNormalize1(materials.convertColorToArray(options.colorrgb))
  }
  
  return new Promise((resolve, reject) => {
    if (player.api) {
      player.api.setLight(lightId, options, function (err) {
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      });
    }
  });
};

// const setLight = function (lightId, options) {
//   return new Promise((resolve, reject) => {
//     if (player.api) {
//       player.api.setLight(lightId, options, function (err) {
//         if (err) {
//           reject(err)
//         } else {
//           resolve()
//         }
//       })
//     }
//   })
// }

const getLight = function (lightId) {
  return new Promise((resolve, reject) => {
    if (player.api) {
      player.api.getLight(lightId, function (err, light) {
        if (err) {
          reject(err)
        } else {
          resolve(light)
        }
      })
    }
  })
}

const setLightFeatureEnabled = function (enable) {
  return new Promise((resolve, reject) => {
    if (player.api) {
      player.api.setLightFeatureEnabled(enable, function (err) {
        if (err) {
          reject(err)
        }
        resolve()
      })
    }
  })
}

window.configuratorapi.setLight = setLight
window.configuratorapi.getLight = getLight

export default { setLight, getLight, setLightFeatureEnabled }
