<script>
import annotationoverlayitem from './annotation-overlay-item'
import annotationTools from '@/tools/sketchfab/annotations'

export default {
  name: 'annotationoverlay',
  components: {
    annotationoverlayitem
  },
  props: {
    cameraMoves: Boolean
  },

  data: function () {
    return {
      // annotationStyle: {},
      juststoppedmoving: false,
      juststartedmoving: false,
      payloadactions: []
      // annotations: []
    }
  },  
  computed: {
    annotations () {
      const ann = this.$store.getters['annotation/getAll']
      // for (let a of ann) {
      //   this.annotationStyle[a.name] = {
      //     transform: 'translate(' + 0 + 'px,' + 0 + 'px)',
      //     opacity: 0.0,
      //     'pointer-events': 'none',
      //     'z-index': 1
      //   }
      // }
      return ann
    },
    annotationsHeight () {
      return this.$store.getters['ui/getItemByName']('contentHeight')
    },
    showAnnotations () {
      return this.$store.getters['annotation/getShow']
    }
  },
  mounted: function () {
    // collect the payload actions
    for (let annotation of this.annotations) {
      if (annotation.payload.action.material) {
        this.payloadactions.push({material: this.$store.getters['material/getItemByName'](annotation.payload.action.material)})
      }
      if (annotation.payload.action.visibilitysingle) {
        this.payloadactions.push({visibilitysingle: this.$store.getters['visibilitysingle/getItemByName'](annotation.payload.action.visibilitysingle)})
      }
      if (annotation.payload.action.visibilitygroup) {
        this.payloadactions.push({visibilitygroup: this.$store.getters['visibilitygroup/getItemByName'](annotation.payload.action.visibilitygroup)})
      }
      if (annotation.payload.action.label) {
        const theLabel = this.$store.getters['labels/getItemByName'](annotation.payload.action.label)
        this.payloadactions.push({label: theLabel})
      }
      if (annotation.payload.action.pano) {
        this.payloadactions.push({pano: this.$store.getters['pano/getItemByName'](annotation.payload.action.pano)})
      }
    }


    // this.annotations = this.$store.getters['annotation/getAll']
    this.tick()
  },
  methods: {
    tick () {
      // This tick is called every frame. If the camera is moving, we update the position
      // of the annotation. If the camera has just stopped, we update the occlusion
      // of the annotation but we don't update the position anymore.
      const self = this
      if (self.cameraMoves) {
        if (self.juststartedmoving) {

        }
        self.juststartedmoving = false
        // self.occluded = true
        // self.pendingoccluded = true
        self.juststoppedmoving = true
        // self.setAnnotationPos(self)
      } else {
        if (self.juststoppedmoving) {
          // Doing 3d raycasting is too heavy on most models. The annotations will lag and stutter
          // when done every frame. Only raycast once the camera stops moving
          // self.setAnnotationOcclusion(self)
        }
        self.juststoppedmoving = false
        requestAnimationFrame(self.tick)
        
      }
    } 
  }

}
</script>

<template>
<div v-if="showAnnotations" class="annotationWrapper" :style="annotationsHeight">
  <annotationoverlayitem v-for="(item, index) in annotations" 
    :key="index" 
    :annotation="item" 
    :show="item.show" 
    :cameraMoves="cameraMoves"
    :juststartedmoving="juststartedmoving"
    :juststoppedmoving="juststoppedmoving"
    :action="payloadactions[index]"
  />
</div>
</template>

<style lang="scss" scoped>
.annotationWrapper {
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;  
  z-index:3;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
</style>
