const template = {
  optionhook: [
  ],
  materialoptions: [
  ],
  visibilitysingle: [
    {
      title: 'Zonwering',
      name: 'zonwering',
      visible: true,
      objects: [
          'zonwering',
      ]
    },
    {
      title: 'Space Frame',
      name: 'spaceframe',
      visible: true,
      objects: [
          'spaceframe',
      ]
    },
    {
      title: 'Dak',
      name: 'dak',
      visible: true,
      objects: [
          'dak'
      ]
    },
    {
      title: 'Gevel',
      name: 'gevel',
      visible: true,
      objects: [
          'gevel'
      ]
    },
    {
      title: 'Begane grond',
      name: 'beganegrond',
      visible: true,
      objects: [
          'beganegrond'
      ]
    },
    {
      title: 'Black Box',
      name: 'blackbox',
      visible: true,
      objects: [
          'blackbox'
      ]
    },
    {
      title: 'Entree',
      name: 'entree',
      visible: true,
      objects: [
          'entree'
      ]
    },
    {
      title: 'Entresol',
      name: 'entresol',
      visible: true,
      objects: [
          'entresol'
      ]
    },
    {
      title: 'Maison d\'Artiste',
      name: 'mda',
      visible: true,
      objects: [
          'mda'
      ]
    },
    {
      title: 'Souterrain',
      name: 'souterrain',
      visible: true,
      objects: [
          'souterrain'
      ]
    },
    {
      title: 'Mensen',
      name: 'mensen',
      visible: true,
      objects: [
          'mensen'
      ]
    },
    {
      title: 'Kelder',
      name: 'kelder',
      visible: true,
      objects: [
          'kelder',
      ]
    },
  ],
  visibilitygroup: [
  ],
  transform: [
  ],
  materials: [
  ],
  views: [
    {
      title: 'Zuidgevel',
      name: 'zuidgevel',
      payload: {
        eye: [0,-500,20],
        lookat: [0,0,0],
        fov: 15
      }
    },
    {
      title: 'Oostgevel',
      name: 'oostgevel',
      payload: {
        eye: [500,0,20],
        lookat: [0,0,0],
        fov: 15
      }
    },
    {
      title: 'Noordgevel',
      name: 'noordgevel',
      payload: {
        eye: [0,500,20],
        lookat: [0,0,0],
        fov: 15
      }
    },
    {
      title: 'Westgevel',
      name: 'westgevel',
      payload: {
        eye: [-500,0,20],
        lookat: [0,0,0],
        fov: 15
      }
    },
    {
      title: 'Dak',
      name: 'dak',
      payload: {
        eye: [0,-20,500],
        lookat: [0,0,0],
        fov: 15
      }
    },
    {
      title: 'Entree',
      name: 'entree',
      payload: {
        eye: [14.88,-57.85,9.50],
        lookat: [-7.32,-3.64,-2.88],
        fov: 30
      }
    },
    {
      title: 'Entree',
      name: 'entree',
      payload: {
        eye: [14.88,-57.85,9.50],
        lookat: [-7.32,-3.64,-2.88],
        fov: 30
      }
    },
    {
      title: 'Maison d\'Artiste',
      name: 'mda',
      payload: {
        eye: [-5.40,11.15,1.82],
        lookat: [-22.72,-0.61,5.42],
        fov: 45
      }
    },
    {
      title: 'Black Box',
      name: 'box',
      payload: {
        eye: [-17.06,-0.48,-1.96],
        lookat: [21.67,-1.56,6.71],
        fov: 45
      }
    },
    {
      title: 'Zuidgevel',
      name: 'mda-zuidgevel',
      payload: {
        eye: [550,-12000,800],
        lookat: [550,0,800],
        fov: 15
      }
    },
    {
      title: 'Oostgevel',
      name: 'mda-oostgevel',
      payload: {
        eye: [12000,550,800],
        lookat: [0,550,800],
        fov: 15
      }
    },
    {
      title: 'Noordgevel',
      name: 'mda-noordgevel',
      payload: {
        eye: [550,12000,800],
        lookat: [550,0,800],
        fov: 15
      }
    },
    {
      title: 'Westgevel',
      name: 'mda-westgevel',
      payload: {
        eye: [-12000,550,800],
        lookat: [0,550,800],
        fov: 15
      }
    },
    {
      title: 'MdA Entresol',
      name: 'mda-entresol',
      payload: {
        eye: [-56.68,-7.09,8.99],
        lookat: [-47.15,-3.46,9.00],
        fov: 60
      }
    },
    {
      title: 'MdA Souterrain',
      name: 'mda-souterrain',
      payload: {
        eye: [11.23,2.43,-2.18],
        lookat: [7.99,1.89,-1.60],
        fov: 45
      }
    },
    {
      title: 'Vanuit MdA',
      name: 'vanuit-mda',
      payload: {
        eye: [-28.23,2.50,14.67],
        lookat: [-22.82,0.07,12.61],
        fov: 60
      }
    },

  ],
  player: [
    {
      name: 'museum',
      library: null,
      payload: {
        orbit_constraint_zoom_in: 2,
        orbit_constraint_zoom_out: 500,
        orbit_constraint_pan: 0
      }
    }
  ],
  background: [
    {
      name: 'museum',
      library: null,
      payload: {
        uid: '171301985c784b77a9eeeef15e727bf7'
      }
    },
    {
      name: 'dark',
      library: null,
      payload: {
        enable: 'color',
        color: [0.2, 0.2, 0.2]
      }
    },
  ],
  environment: [
    {
      name: 'museum-dag',
      library: 'museum',
      payload: {
      }
    },
  ],
  postprocessing: [
    {
      name: 'museum-dag',
      library: 'museum',
      payload: {}
    },
  ],
  annotation: [
  ],
  labels: [
  ],
  pano: [
  ],
  studiogroup: [
  ],
  presetgroup: [
  ]

}
  
export default template;