import tweakpane from '../../tweakpane'
import materials from '../materials';
import player from '../player';
import store from '../../../store';

let folder = null

let values = {
  materialName: 'NONE',
  import: '',  
}
let presetkeys = [
  'AlbedoPBR-colorrgb',
  'AlbedoPBR-factor',
  'AlbedoPBR-texture',
  'MetalnessPBR-enable',
  'MetalnessPBR-factor',
  'MetalnessPBR-texture',
  'RoughnessPBR-enable',
  'RoughnessPBR-factor',
  'RoughnessPBR-texture',
  'SpecularF0-enable',
  'SpecularF0-factor',
  'SpecularF0-texture',
  'NormalMap-enable',
  'NormalMap-factor',
  'NormalMap-texture',
  'NormalMap-flipY',
  'BumpMap-enable',
  'BumpMap-factor',
  'BumpMap-texture',
  'Opacity-enable',
  'Opacity-factor',
  'Opacity-texture',
  'Opacity-type',
  'Opacity-ior',
  'Opacity-roughnessFactor',
  'Opacity-refractionColorrgb',
  'Opacity-thinLayer',
  'Opacity-useAlbedoTint',
  'Opacity-useMicrosurfaceTexture',
  'Opacity-useNormalOffset',
  'EmitColor-enable',
  'EmitColor-factor',
  'EmitColor-texture',
  'Anisotropy-enable',
  'Anisotropy-factor',
  'Anisotropy-direction',
  'Anisotropy-flipXY',
  'Anisotropy-texture',
  'Sheen-enable',
  'Sheen-factor',
  'Sheen-colorFactor',
  'Sheen-texture',
  'SheenRoughness-enable',
  'SheenRoughness-factor',
  'SheenRoughness-texture',
  'ClearCoat-enable',
  'ClearCoat-factor',
  'ClearCoat-texture',
  'ClearCoat-reflectivity',
  'ClearCoat-thickness',
  'ClearCoat-tintrgb',
  'ClearCoatRoughness-factor',
  'ClearCoatRoughness-texture',
  'ClearCoatNormalMap-factor',
  'ClearCoatNormalMap-texture',
  'ClearCoatNormalMap-flipY',
]
let materialChannels = {}

const matchTextureValue = function (theChannels, channelName) {
  if (channelName === 'Matcap') return
  if (theChannels[channelName] === undefined) return

  if (theChannels[channelName].texture) {
    materialChannels[channelName].texture = store.getters['sf_textures/getTexture'](theChannels[channelName].texture.uid).name
    delete materialChannels[channelName].color// = [0,0,0]
    delete materialChannels[channelName].tintrgb// = [0,0,0]
    delete materialChannels[channelName].refractionColorrgb// = [0,0,0]
  } else {
    materialChannels[channelName].texture = 'NONE'
  }
}

const matchColorValue = function (theChannels, channelName) {
  if (theChannels[channelName].color) {
    materialChannels[channelName].color = materials.colorNormalize255(theChannels[channelName].color)
    materialChannels[channelName].colorrgb = convertColorToObject(theChannels[channelName].color)
    materialChannels[channelName].texture = 'NONE'
  }
  if (theChannels[channelName].tint) {
    materialChannels[channelName].tint = materials.colorNormalize255(theChannels[channelName].tint)
    materialChannels[channelName].tintrgb = convertColorToObject(theChannels[channelName].tint)
  }
  if (theChannels[channelName].refractionColor) {
    materialChannels[channelName].refractionColor = materials.colorNormalize255(theChannels[channelName].refractionColor)
    materialChannels[channelName].refractionColorrgb = convertColorToObject(theChannels[channelName].refractionColor)
  }
}

/**
 * Convert a color from array formatting to object formatting
 * @param {array} color A 3-element array, normalized between 0-255.
 * @returns {r:,g:,b:} color object, normalized between 0-255
 */
 const convertColorToObject = function (color) {
  const normalizedColor = materials.colorNormalize255(color)
  return {r: normalizedColor[0],g: normalizedColor[1],b: normalizedColor[2]}
}

const convertColorToArray = function (color) {
  return [color.r, color.g, color.b]
}
const insertColorRGB = function (material) {
  console.log("material", material);
  for (let channelName in material.channels) {
    for (let type in material.channels[channelName]) {
      if (type === 'color') {
        material.channels[channelName].colorrgb = convertColorToObject(material.channels[channelName].color)
      } else if (type === 'tint') {
        material.channels[channelName].tintrgb = convertColorToObject(material.channels[channelName].tint)
      } else if (type === 'refractionColor') {
        material.channels[channelName].refractionColorrgb = convertColorToObject(material.channels[channelName].refractionColor)
      } else if (type === 'colorFactor') {
        material.channels[channelName].colorFactorrgb = convertColorToObject(material.channels[channelName].colorFactor)
      }
    }
  }
}

const insertNullTextures = function (material) {
  for (let channelName in material.channels) {
    for (let type in material.channels[channelName]) {
      if (type === 'texture') {

      } else if (type === 'colorFactor') {
        material.channels[channelName].colorFactorrgb = convertColorToObject(material.channels[channelName].colorFactor)
        material.channels[channelName].texture = 'NONE'
      } else if (type === 'refractionColor') {
        material.channels[channelName].refractionColorrgb = convertColorToObject(material.channels[channelName].refractionColor)
        material.channels[channelName].texture = 'NONE'
      } else if (type === 'tint') {
        material.channels[channelName].tintrgb = convertColorToObject(material.channels[channelName].tint)
        material.channels[channelName].texture = 'NONE'
      } else if (type === 'color') {
        material.channels[channelName].colorrgb = convertColorToObject(material.channels[channelName].color)
        material.channels[channelName].texture = 'NONE'
      } else {
        material.channels[channelName].texture = 'NONE'
      }
    }
  }
}

const removeMutuallyExclusiveColor = function (material) {
  for (let channelName in material.channels) {
    if (material.channels[channelName].texture !== 'NONE' && material.channels[channelName].color !== undefined) {
      delete material.channels[channelName].color
    }
  }
}

const normalizeColor = function (materialChannels) {
  for (let channelname in materialChannels) {
    if (materialChannels[channelname].enable === true && materialChannels[channelname].colorrgb) {
      materialChannels[channelname].color = convertColorToArray(materialChannels[channelname].colorrgb)
    }
    if (materialChannels[channelname].enable === true && materialChannels[channelname].tintrgb) {
      materialChannels[channelname].tint = convertColorToArray(materialChannels[channelname].tintrgb)
    }
    if (materialChannels[channelname].enable === true && materialChannels[channelname].refractionColorrgb) {
      materialChannels[channelname].refractionColor = convertColorToArray(materialChannels[channelname].refractionColorrgb)
    }
    if (materialChannels[channelname].enable === true && materialChannels[channelname].colorFactorrgb) {
      materialChannels[channelname].colorFactor = convertColorToArray(materialChannels[channelname].colorFactorrgb)
    }
  }
}

const getTextureChoices = function () {
  const allTextures = store.state.sf_textures.all
  let textureChoices = {NONE: 'NONE'}
  for (let uid in allTextures) {
    textureChoices[allTextures[uid].name] = allTextures[uid].name
  }
  return textureChoices
}

const getMaterialChoices = function () {
  const allMaterials = store.state.sf_materials.all
  let materialChoices = {}
  for (let uid in allMaterials) {
    materialChoices[allMaterials[uid].name] = allMaterials[uid].name
  }
  return materialChoices
}
const buildGUI = async function() {
  let materialChoices = getMaterialChoices()
  let textureChoices = getTextureChoices()

  const firstMaterial = await materials.getMaterialFromScene(Object.values(materialChoices)[0])
  insertColorRGB(firstMaterial)
  insertNullTextures(firstMaterial)
  materialChannels = firstMaterial.channels



  folder = tweakpane.pane.addFolder({title: 'Material'})

  folder.addInput(values, 'materialName', {options: materialChoices})
  const btnExport = folder.addButton({
    title: 'Export Material',
  })
  btnExport.on('click', () => {
    values.import = tweakpane.exportJSON('material')
    tweakpane.updateGui()
  })  
  const btnImport = folder.addButton({
    title: 'Import Material',
  })
  btnImport.on('click', () => {
    tweakpane.refreshing = true
    tweakpane.pane.importPreset(JSON.parse(values.import));
    tweakpane.refreshing = false
    applyValues(null)
  })  
  folder.addInput( values, 'import')

  const folderAlbedoPBR = folder.addFolder({title: 'AlbedoPBR'});
  const folderMetalnessPBR = folder.addFolder({title: 'MetalnessPBR'});
  const folderRoughnessPBR = folder.addFolder({title: 'RoughnessPBR'});
  const folderSpecularF0 = folder.addFolder({title: 'SpecularF0'});
  const folderOpacity = folder.addFolder({title: 'Opacity',expanded: false});
  const folderNormalMap = folder.addFolder({title: 'NormalMap',expanded: false});
  const folderBumpMap = folder.addFolder({title: 'BumpMap',expanded: false});
  const folderEmitColor = folder.addFolder({title: 'EmitColor',expanded: false});
  const folderAnisotropy = folder.addFolder({title: 'Anisotropy',expanded: false});
  const folderClearCoat = folder.addFolder({title: 'ClearCoat',expanded: false});
  const folderClearCoatRoughness = folder.addFolder({title: 'ClearCoatRoughness',expanded: false});
  const folderClearCoatNormalMap = folder.addFolder({title: 'ClearCoatNormalMap',expanded: false});
  const folderSheen = folder.addFolder({title: 'Sheen',expanded: false});
  const folderSheenRoughness = folder.addFolder({title: 'SheenRoughness',expanded: false});
  

  folderAlbedoPBR.addInput( materialChannels.AlbedoPBR, 'colorrgb', {presetKey: 'AlbedoPBR-colorrgb'})
  folderAlbedoPBR.addInput( materialChannels.AlbedoPBR, 'factor', {min: 0, max: 1, step: 0.01, presetKey: 'AlbedoPBR-factor'})
  folderAlbedoPBR.addInput( materialChannels.AlbedoPBR, 'texture', {options: textureChoices, presetKey: 'AlbedoPBR-texture'} )

  folderMetalnessPBR.addInput( materialChannels.MetalnessPBR, 'enable', {hidden: true, presetKey: 'MetalnessPBR-enable'})
  folderMetalnessPBR.addInput( materialChannels.MetalnessPBR, 'factor', {min: 0, max: 1, step: 0.01, presetKey: 'MetalnessPBR-factor'} )
  folderMetalnessPBR.addInput( materialChannels.MetalnessPBR, 'texture', {options: textureChoices, presetKey: 'MetalnessPBR-texture'} )

  folderRoughnessPBR.addInput( materialChannels.RoughnessPBR, 'enable', {hidden: true, presetKey: 'RoughnessPBR-enable'})
  folderRoughnessPBR.addInput( materialChannels.RoughnessPBR, 'factor', {min: 0, max: 1, step: 0.01, presetKey: 'RoughnessPBR-factor'} )
  folderRoughnessPBR.addInput( materialChannels.RoughnessPBR, 'texture', {options: textureChoices, presetKey: 'RoughnessPBR-texture'} )

  folderSpecularF0.addInput( materialChannels.SpecularF0, 'enable', {hidden: true, presetKey: 'SpecularF0-enable'})
  folderSpecularF0.addInput( materialChannels.SpecularF0, 'factor', {min: 0, max: 1, step: 0.01, presetKey: 'SpecularF0-factor'} )
  folderSpecularF0.addInput( materialChannels.SpecularF0, 'texture', {options: textureChoices, presetKey: 'SpecularF0-texture'} )

  // Normal and Bump are mutually exclusive
  folderNormalMap.addInput( materialChannels.NormalMap, 'enable', {presetKey: 'NormalMap-enable'})
  .on('change', ev => {
    if (ev.value === true) {
      materialChannels.BumpMap.enable = false
      tweakpane.updateGui()
    }
  })
  folderNormalMap.addInput( materialChannels.NormalMap, 'factor', {min: 0, max: 5, step: 0.01, presetKey: 'NormalMap-factor'} )
  folderNormalMap.addInput( materialChannels.NormalMap, 'texture', {options: textureChoices, presetKey: 'NormalMap-texture'} )
  folderNormalMap.addInput( materialChannels.NormalMap, 'flipY', {presetKey: 'NormalMap-flipY'} )

  folderBumpMap.addInput( materialChannels.BumpMap, 'enable', {presetKey: 'BumpMap-enable'})
  .on('change', ev => {
    if (ev.value === true) {
      materialChannels.NormalMap.enable = false
      tweakpane.updateGui()
    }
  })
  folderBumpMap.addInput( materialChannels.BumpMap, 'factor', {min: 0, max: 5, step: 0.01, presetKey: 'BumpMap-factor'} )
  folderBumpMap.addInput( materialChannels.BumpMap, 'texture', {options: textureChoices, presetKey: 'BumpMap-texture'} )

  folderOpacity.addInput( materialChannels.Opacity, 'enable', {presetKey: 'Opacity-enable'})
  folderOpacity.addInput( materialChannels.Opacity, 'factor', {min: 0, max: 1, step: 0.01, presetKey: 'Opacity-factor'} )
  folderOpacity.addInput( materialChannels.Opacity, 'texture', {options: textureChoices, presetKey: 'Opacity-texture'} )
  folderOpacity.addInput( materialChannels.Opacity, 'type', {
    options:{
      alphaBlend: 'alphaBlend',
      refraction: 'refraction',
      additive: 'additive',
      dithering: 'dithering',
    }, presetKey: 'Opacity-type'
  })

  folderOpacity.addInput( materialChannels.Opacity, 'ior', {min: 0, max: 2, step: 0.01, presetKey: 'Opacity-ior'} )
  folderOpacity.addInput( materialChannels.Opacity, 'roughnessFactor', {min: 0, max: 2, step: 0.01, presetKey: 'Opacity-roughnessFactor'} )
  folderOpacity.addInput( materialChannels.Opacity, 'refractionColorrgb', {presetKey: 'Opacity-refractionColorrgb'})
  folderOpacity.addInput( materialChannels.Opacity, 'thinLayer', {presetKey: 'Opacity-thinLayer'})
  folderOpacity.addInput( materialChannels.Opacity, 'useAlbedoTint', {presetKey: 'Opacity-useAlbedoTint'})
  folderOpacity.addInput( materialChannels.Opacity, 'useMicrosurfaceTexture', {presetKey: 'Opacity-useMicrosurfaceTexture'})
  folderOpacity.addInput( materialChannels.Opacity, 'useNormalOffset', {presetKey: 'Opacity-useNormalOffset'})

  folderEmitColor.addInput( materialChannels.EmitColor, 'enable', {presetKey: 'EmitColor-enable'})
  folderEmitColor.addInput( materialChannels.EmitColor, 'factor', {min: 0, max: 10, step: 0.1, presetKey: 'EmitColor-factor'} )
  folderEmitColor.addInput( materialChannels.EmitColor, 'texture', {options: textureChoices, presetKey: 'EmitColor-texture'} )

  folderAnisotropy.addInput( materialChannels.Anisotropy, 'enable', {presetKey: 'Anisotropy-enable'})
  folderAnisotropy.addInput( materialChannels.Anisotropy, 'factor', {min: 0, max: 1, step: 0.01, presetKey: 'Anisotropy-factor'} )
  folderAnisotropy.addInput( materialChannels.Anisotropy, 'direction', {min: -1, max: 1, step: 0.01, presetKey: 'Anisotropy-direction'} )
  folderAnisotropy.addInput( materialChannels.Anisotropy, 'flipXY', {presetKey: 'Anisotropy-flipXY'} )
  folderAnisotropy.addInput( materialChannels.Anisotropy, 'texture', {options: textureChoices, presetKey: 'Anisotropy-texture'} )

  folderClearCoat.addInput( materialChannels.ClearCoat, 'enable', {presetKey: 'ClearCoat-enable'})
  folderClearCoat.addInput( materialChannels.ClearCoat, 'factor', {min: 0, max: 1, step: 0.01, presetKey: 'ClearCoat-factor'} )
  folderClearCoat.addInput( materialChannels.ClearCoat, 'texture', {options: textureChoices, presetKey: 'ClearCoat-texture'} )
  folderClearCoat.addInput( materialChannels.ClearCoat, 'reflectivity', {min: 0, max: 1, step: 0.01, presetKey: 'ClearCoat-reflectivity'} )
  folderClearCoat.addInput( materialChannels.ClearCoat, 'thickness', {min: 0, max: 20, step: 0.05, presetKey: 'ClearCoat-thickness'} )
  folderClearCoat.addInput( materialChannels.ClearCoat, 'tintrgb', {presetKey: 'ClearCoat-tintrgb'})

  folderClearCoatRoughness.addInput( materialChannels.ClearCoatRoughness, 'factor', {min: 0, max: 1, step: 0.01, presetKey: 'ClearCoatRoughness-factor'} )
  folderClearCoatRoughness.addInput( materialChannels.ClearCoatRoughness, 'texture', {options: textureChoices, presetKey: 'ClearCoatRoughness-texture'} )

  folderClearCoatNormalMap.addInput( materialChannels.ClearCoatNormalMap, 'factor', {min: 0, max: 1, step: 0.01, presetKey: 'ClearCoatNormalMap-factor'} )
  folderClearCoatNormalMap.addInput( materialChannels.ClearCoatNormalMap, 'texture', {options: textureChoices, presetKey: 'ClearCoatNormalMap-texture'} )
  folderClearCoatNormalMap.addInput( materialChannels.ClearCoatNormalMap, 'flipY', {presetKey: 'ClearCoatNormalMap-flipY'} )

  folderSheen.addInput( materialChannels.Sheen, 'enable', {presetKey: 'Sheen-enable'})
  folderSheen.addInput( materialChannels.Sheen, 'factor', {min: 0, max: 1, step: 0.01, presetKey: 'Sheen-factor'} )
  folderSheen.addInput( materialChannels.Sheen, 'texture', {options: textureChoices, presetKey: 'Sheen-texture'} )
  folderSheen.addInput( materialChannels.Sheen, 'colorFactorrgb', {presetKey: 'Sheen-colorFactor'})

  folderSheenRoughness.addInput( materialChannels.SheenRoughness, 'enable', {presetKey: 'SheenRoughness-enable'})
  folderSheenRoughness.addInput( materialChannels.SheenRoughness, 'factor', {min: 0, max: 1, step: 0.01, presetKey: 'SheenRoughness-factor'} )
  folderSheenRoughness.addInput( materialChannels.SheenRoughness, 'texture', {options: textureChoices, presetKey: 'SheenRoughness-texture'} )

  //TODO: add alphamask, AO, Cavity, Displacement, SSS

  folder.on('change', (event) => applyValues(event))
  values.materialName = Object.values(materialChoices)[0]
  tweakpane.updateGui()
}

async function applyValues(event) {
  const mtl = await materials.getMaterialFromScene(values.materialName)

  if (!mtl) return
  if (event?.presetKey === 'materialName') {
    // When selecting a material, highlight it
    setGuiMaterialValues(mtl)
    player.api.highlightMaterial(mtl)
  } else if (!tweakpane.refreshing) {
    normalizeColor(materialChannels)
    removeMutuallyExclusiveColor({channels: materialChannels})
    if (!tweakpane.refreshing) {
      materials.setMaterialChannelsOptions(mtl, materialChannels)
      materials.setMaterial(mtl)
    }
  }
}

const setGuiMaterialValues = function (sceneMaterial) {
  values.materialName = sceneMaterial.name
    for (let channelName in sceneMaterial.channels) {
      // Only the first time the materialchannels are populated, put an empty object in
      // Don't reset these channels each time, otherwise tweakpane doesn't detect a change
      if (materialChannels[channelName] === undefined) materialChannels[channelName] = {}
      for (let type in sceneMaterial.channels[channelName]) {
        // try {
          // if (channelName === 'AlbedoPBR') {
            materialChannels[channelName].texture = 'NONE'
            if (type === 'color' || type === 'tint' || type === 'refractionColor') matchColorValue(sceneMaterial.channels, channelName)
            else if (type === 'texture') matchTextureValue(sceneMaterial.channels, channelName)
            else materialChannels[channelName][type] = sceneMaterial.channels[channelName][type]
          // }
        // } catch (e) {
        //   console.log('Unable to apply material settings', sceneMaterialName, channelName, type)
        //   // console.log(e);
        // }
      }
    }
    // values.materialName = sceneMaterial.name
  // }
  tweakpane.updateGui()
}

const getValues = async function(materialName) {
  const mtl = await materials.getMaterialFromScene(materialName)
  setGuiMaterialValues(mtl)
  // return mtl
}

export default {
  get values() {
    return values;
  },  
  get folder() {
    return folder;
  },
  get presetkeys() {
    return presetkeys
  },
  set values(newvalue) {
    return values = newvalue;
  },  
  buildGUI,
  getValues,
  setGuiMaterialValues,
  convertColorToObject
}