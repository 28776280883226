<script>
import configure from '@/tools/configurator/configure'

export default {
  name: 'materialmenu',
  props: {
    material: Object
  },
  computed: {
  },
  methods: {
    setMaterial (option) {
      // using the store from the import helps keep the ui updated even when it's
      // not visible. e.g. when it's triggered from the states menu
      this.$store.commit('material/updateSelected', {group: this.material.name, option: option.name})
      configure.SetMaterialOption(this.material, option)
    },
    getIcon (iconpath) {
      if (iconpath) {
        return (process.env.BASE_URL + iconpath)
      } else {
        return null
      }
    },      
  },
  mounted () {
  }
}
</script>

<template>
<div>
  <v-sheet
    class="d-flex flex-wrap pa-2"
    elevation="0"
    color="cardaccent2"
  >
    <v-sheet
      v-for="(option, index) in material.options" 
      :key="index" 
    >
      <v-avatar
        tile
        v-if="option.swatch" 
        :color="option.swatch" 
        @click.stop="setMaterial(option)"
        size=62
      >  
      </v-avatar>
      
      <v-avatar
        tile
        v-if="option.icon" 
        @click="setMaterial(option)"
        size=122
      >
        <img
          :src="getIcon(option.icon)"
        >      
      </v-avatar>
      
      <v-avatar
        tile
        v-if="option.iconcompact" 
        @click="setMaterial(option)"
        size=62
      >
        <img
          :src="getIcon(option.iconcompact)"
        >      
      </v-avatar>
    </v-sheet>
  </v-sheet>
</div>
</template>