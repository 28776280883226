<script>
import configure from "@/tools/configurator/configure"
import nma from  '@/tools/project/nma'

export default {
  name: 'cardCommercial',
  components: {
  },
  props: {
    contactcard: Boolean,
    summary: Boolean,
    pricing: Boolean,
  },

  data: () => ({
  }),
  computed: {
    bottomnav: {
      get: function () { return this.$store.state.ui.bottomnav },
      set: function (newValue) { this.$store.dispatch('ui/setBottomnav', {isOpen: newValue}) }
    },      
    commercialbar: {
      get: function () { return this.$store.state.ui.commercialbar },
      set: function (newValue) { this.$store.dispatch('ui/setCommercialbar', {value: newValue}) }
    },
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    summaryheight () {
      if (this.pricing || this.summary) {
        return this.mobile ? 36 : 48
      } else {
        return 0
      }
    },
    contactheight () {
      if (this.contactcard) {
        return 62
      } else {
        return 0
      }
    },
    bottombarheight () {
      if (this.bottomnav && this.mobile) {
        return 36
      } else {
        return this.summaryheight + this.contactheight
      }
    },    
  },
  methods: { 
    openPortfolio () {
      this.$store.commit('ui/updateItemByName', {key: 'projectsdialog', value: true})
    },
    async clickSummary() {
      const exportObject = nma.getSetupJson()
      this.$store.commit('ui/updateItemByName', {key: 'export', value: JSON.stringify(exportObject)})
      configure.makeSummaryScreenshot('start')
      const view = await configure.getView()
      this.$store.commit('ui/updateItemByName', {key: 'queryview', value: JSON.stringify(view)})

      this.commercialbar = !this.commercialbar
    },
    clickCommercialBar() {
      // if (this.bottomnav) this.bottomnav = false
    },

  },
  mounted () {
  }
}
</script>

<template>
  <v-card
    v-if="(summary || pricing || contactcard)"
    tile
    color="cardaccent"
    flat
    :height="bottombarheight"
    class="bottombar d-flex flex-column align-start px-4 pb-0"
    :ripple="false"
  >
    <v-sheet 
      v-if="summary || pricing"
      color="cardaccent"
      :height="summaryheight"
      @click="clickCommercialBar"   
      class="d-flex align-center pricingbar"
    >
      <div
        v-if="pricing"
        class="text-truncate text-h6 font-weight-medium"
      >Price</div>
    
      <v-btn
        text
        v-if="summary"
        height=32
        class="ml-auto text-truncate text-h6 font-weight-light text--secondary"
        @click="clickSummary"
      >Summary <v-icon right>mdi-chevron-right</v-icon> </v-btn>
    </v-sheet>

    <v-sheet
      v-if="contactcard"
      color="cardaccent"
      :height="contactheight"
      class="d-flex align-center commercialbar mt-1"
    >
      <v-avatar
        @click.stop="openPortfolio()"
      >
        <v-img src="../assets/img/Klaas_profilePicture.jpg"></v-img>      
      </v-avatar>

      <div 
        class="d-flex flex-column ml-2 mr-auto"
        @click.stop="openPortfolio()"
      >
        <div 
          class="text-truncate text-h5 font-weight-medium"
        >Klaas Nienhuis</div>
      
        <div 
          class="mt-n1 text-truncate text-subtitle-2 font-weight-light text--secondary text-uppercase"
        >3d development</div>
      </div>

      <v-btn
        fab
        small
        color="primary" 
        @click.stop="openPortfolio()"
      >
        <v-icon>mdi-emoticon-outline</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="primary" 
        href="https://klaasnienhuis.freshsales.io/web_forms/404bcc64bc2b7d2fadc81f06d07264e35c21a9cc6c36e680a1bd52d8b929b501/form.html" 
        target="_blank"
        class="ml-2"
      >
        <v-icon>mdi-at</v-icon>
      </v-btn>

    </v-sheet>
  </v-card> 

</template>

<style lang="scss" scoped>
  .bottombar {
    box-shadow: 0px 6px 20px 9px #00000057 !important;
    z-index: 2;
    transition: height 0.3s;
  }

  .pricingbar {
    width: 100%;
    height: 45px;
  }

  .commercialbar {
    width: 100%;
  }

  .v-btn {
    text-transform: capitalize;
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
</style>
