import scene from './scene'
import {StandardMaterial} from '@babylonjs/core';
import {PBRSpecularGlossinessMaterial} from '@babylonjs/core';
import {PBRMaterial} from '@babylonjs/core';
import {Color3} from '@babylonjs/core';
import {Texture} from '@babylonjs/core';
import {DynamicTexture} from '@babylonjs/core';


let sceneMaterials = {}

const getMtl = function (id) {
    return sceneMaterials[id]
}

const getSceneMtl = function (name) {
    let sc = scene.getScene()
    return sc.getMaterialByName(name)
}

//build the materials from the data file
const setupMaterials = function (materialData, hdrTexture){
    materialData.forEach(data => {      
        if (data.type === 'single' || data.type === 'overrideexclude') {
            data.options.forEach(option => {
                const newMtl = createMaterial(option.payload, option.id, hdrTexture)
                sceneMaterials[option.id] = newMtl
            });
        }
    });
}

const createMaterial = function (payload, id, hdrTexture) {
    let sc = scene.getScene()
    let newMtl = null;
    //Get the material type from the data
    //Some channels are only relevant to certian types of mateials. If relevant, they get the hdrTexture for
    //reflection or environment
    switch (payload.type){
        case "PBRSpecularGlossinessMaterial":
            newMtl = new PBRSpecularGlossinessMaterial(id, sc);
            newMtl.environmentTexture  = hdrTexture;
        break;
        case "PBRMaterial":
            newMtl = new PBRMaterial(id, sc);
            newMtl.reflectionTexture  = hdrTexture;
        break;
    }

    // var boxMaterial = new StandardMaterial(id, sc);
    // boxMaterial.diffuseColor = new Color3(0.8,0.6,0.8);
    // boxMaterial.specularColor = Color3.Black();

    applyMaterialSettings(newMtl, payload);

    return newMtl
    // return boxMaterial
}

const applyMaterialSettings = function (material, settings){
    let sc = scene.getScene()

    if (material instanceof PBRSpecularGlossinessMaterial){
        applyTextureSettings(sc, material,settings,"diffuseTexture")
        applyTextureSettings(sc, material,settings,"specularGlossinessTexture")
        applyTextureSettings(sc, material,settings,"normalTexture")
        applyTextureSettings(sc, material,settings,"bumpTexture")
        
        applySetting(material, settings, 'glossiness');
        applyColor(material, settings, 'specularColor');
        applyColor(material, settings, 'diffuseColor');
        
    }
    
    if (material instanceof PBRMaterial){
        applyTextureSettings(sc, material,settings,"albedoTexture")
        applyTextureSettings(sc, material,settings,"bumpTexture")
        applyTextureSettings(sc, material,settings,"metallicTexture")
        applyTextureSettings(sc, material,settings,"reflectivityTexture")
        
        applyColor(material, settings, 'albedoColor');
        applySetting(material, settings, 'roughness');
        applySetting(material, settings, 'metallic');
    }
}

//Take texturesettings from a data source and apply them to a material
//The structure of the settings and material need to match
const applyTextureSettings = function (scene, material, settings, textureName){
    if (settings[textureName] == undefined){
        //don't change anything. We don't want to overwrite the default material
    }else if (settings[textureName].path == null || settings[textureName].path == ''){
        //if the path is explicitly absent, remove the texture
        material[textureName] = null
    }else{
        //add a callback to render the scene once the texture is ready
        var onload = function(){scene.render()}
        material[textureName] = new Texture(settings[textureName].path, scene, null, settings[textureName].invertY, null, onload);
        applySetting(material[textureName], settings[textureName], 'level');
    }        
}

const applyColor = function (material, settings, settingName) {
    if (settings[settingName] == undefined) {
        //don't change anything. We don't want to overwrite the default setting
    }else if (settings[settingName] == null) {
        //The setting is explicitly set to null, revert to a default value
        material[settingName] = new Color3(1,1,1);
    }else {
        material[settingName] = new Color3(settings[settingName][0],settings[settingName][1],settings[settingName][2]);
    }
}    
const applySetting = function (material, settings, settingName) {
    if (settings[settingName] == undefined) {
        //don't change anything. We don't want to overwrite the default setting
    }else if (settings[settingName] == null) {
        //The setting is explicitly set to null, revert to a default value
        material[settingName] = 1
    }else {
        material[settingName] = settings[settingName]
    }
}

//Apply a material to objects with a specific name
const applyMaterialByNames = function (objectNames, material) {
    console.log('material', material)
    objectNames.forEach(name => {
        const objects = scene.getMesh(name)
        objects.forEach(obj => {
            obj.material = material
        });
    })
}

const createTextTexture = function (textOption) {
    let sc = scene.getScene()
    let engraving = new DynamicTexture(textOption.name, textOption.payload.size, sc);
    let ctx = engraving.getContext();
    let font = textOption.payload.font;
    ctx.font = font;
    let textWidth = ctx.measureText(textOption.text).width;
    const xpos = textOption.payload.size.width/2 - textWidth/2
    engraving.drawText(textOption.text, xpos, 350, font, textOption.payload.textColor, textOption.payload.canvasColor, true, true);
    return engraving
}

export default { setupMaterials, getMtl, getSceneMtl, applyMaterialByNames, createTextTexture }
