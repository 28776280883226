import scene from './scene'
import {ArcRotateCamera} from '@babylonjs/core/Cameras/arcRotateCamera';
import {Vector3} from '@babylonjs/core';

const buildCamera = function () {
  let sc = scene.getScene()
  let cv = scene.getCanvas()

  // Creates, angles, distances and targets the camera
  //Y and Z are swapped between max and babylon
  //Use scene.activeCamera to get the current cam props
  const cam = new ArcRotateCamera("Camera", -2.1,1.1, 1750, new Vector3(0,650,-150), sc);
  //Set up the camera
  cam.inertia = 0.75;
  cam.angularSensibilityX = 600;
  cam.angularSensibilityY = 600;
  cam.panningSensibility = 0; //disable panning
  cam.wheelPrecision = 0.0001;
  // cam.lowerRadiusLimit = 1000;
  // cam.upperRadiusLimit = 2500;
  cam.upperBetaLimit = 1.75;
  //these two are important for z fighting
  cam.maxZ = 5000
  cam.minZ = 50

  // This attaches the camera to the canvas
  cam.attachControl(cv, true, false);
  sc.activeCameras = [cam]
}

const editActiveCamera = function (payload) {
  let sc = scene.getScene()
  let cam = sc.activeCameras[0]

  if (payload.alpha) cam.alpha = payload.alpha
  if (payload.beta) cam.beta = payload.beta
  if (payload.radius) cam.radius = payload.radius
  if (payload.lowerRadiusLimit) cam.lowerRadiusLimit = payload.lowerRadiusLimit
  if (payload.upperRadiusLimit) cam.upperRadiusLimit = payload.upperRadiusLimit
  if (payload.maxZ) cam.maxZ = payload.maxZ
  if (payload.minZ) cam.minZ = payload.minZ
  if (payload.wheelPrecision) cam.wheelPrecision = payload.wheelPrecision
  if (payload.wheelDeltaPercentage) cam.wheelDeltaPercentage = payload.wheelDeltaPercentage
  if (payload.upperBetaLimit) cam.upperBetaLimit = payload.upperBetaLimit
  if (payload.target) cam.setTarget(new Vector3(payload.target[0],payload.target[1],payload.target[2]))

  sc.render()
}

// const getCamera = function () {
//   let api = player.getApi()
//   if (api) {
//     api.getCameraLookAt(function (err, camera) {
//       if (err) {
//         console.log(err.stack)
//       } else {
//         console.log('View: {\n\teye: [%s,%s,%s],\n\tlookat: [%s,%s,%s],\n}', camera.position[0].toFixed(2), camera.position[1].toFixed(2), camera.position[2].toFixed(2), camera.target[0].toFixed(2), camera.target[1].toFixed(2), camera.target[2].toFixed(2))
//       }
//     })
//   }
// }

export default { buildCamera, editActiveCamera }
