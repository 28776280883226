import Vue from 'vue'
import Vuex from 'vuex'

import materialoptionslists from './modules/editors/materialoptionslists'
import material from './modules/editors/material'
import visibilitysingle from './modules/editors/visibilitysingle'
import visibilitygroup from './modules/editors/visibilitygroup'
import optionhook from './modules/editors/optionhook'
import presetgroup from './modules/editors/presetgroup'
import studiogroup from './modules/editors/studiogroup'
import view from './modules/editors/view'
import pano from './modules/editors/pano'
import annotation from './modules/editors/annotation'

import player from './modules/singular/player'
import background from './modules/singular/background'
import lighting from './modules/singular/lighting'
import environment from './modules/singular/environment'
import postprocessing from './modules/singular/postprocessing'


import animations from './modules/animations'
import scenes from './modules/scenes'
import camera from './modules/camera'
import menu from './modules/menu'
import materialswappable from './modules/materialswappable'
import liquid from './modules/liquid'
import sensar from './modules/sensar'
import nmalight from './modules/nmalight'
import ui from './modules/ui'
import labels from './modules/labels'
import transform from './modules/transform'
import text from './modules/text'
import sf_textures from './modules/sf_textures'
import sf_materials from './modules/sf_materials'

Vue.use(Vuex)

const getters = {}
const state = {}
const actions = {}
const mutations = {}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    animations,
    annotation,
    background,
    camera,
    environment,
    lighting,
    studiogroup,
    labels,
    liquid,
    material,
    materialswappable,
    menu,
    pano,
    player,
    postprocessing,
    // presets,
    presetgroup,
    scenes,
    sensar,
    nmalight,
    ui,
    view,
    visibilitygroup,
    visibilitysingle,
    transform,
    text,
    optionhook,
    sf_textures,
    sf_materials,
    materialoptionslists,
  },
  strict: true
})
