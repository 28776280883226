<script>
import configure from '@/tools/configurator/configure'
import labeloption from './label-option'

export default {
  name: 'labelcard',
  components: {
      labeloption
  },
  props: {
    optionname: String
  },
  data: () => ({
    show: false,
  }),  
  computed: {
    theOption () {
      return this.$store.getters['labels/getItemByName'](this.optionname)
    }
  },
  methods: {
  }  
}
</script>

<template>
<v-card flat>
  <v-card-actions class="pa-0">
    <v-card-title class="subtitle-1 py-1" @click="show = !show">{{ theOption.title }}</v-card-title>
    <v-spacer></v-spacer>
    <v-btn icon @click="show = !show">
      <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
    </v-btn>
  </v-card-actions>

  <v-expand-transition>
    <div v-show="show">
      <labeloption :optionname="optionname"/>
    </div>
  </v-expand-transition> 
</v-card>
</template>