<script>
import materialmenu from './material-menu'

export default {
  name: 'materialoption',
  components: {
    materialmenu
  },
  props: {
    optionname: String
  },
  computed: {
    material () {
      return this.$store.getters['material/getItemByName'](this.optionname)
    },
    visibleOption () {
      return this.material.options.find(option => option.name === this.material.visible)
    },   
  },
  methods: {
    getIcon (iconpath) {
      if (iconpath) {
        return (process.env.BASE_URL + iconpath)
      } else {
        return null
      }
    }, 
  }  
}
</script>

<template>
  <v-list-group
    v-if="material && !material.hidden && material.options.length > 1"
    color='primary'
  >
    <template v-slot:activator>
      <v-list-item-avatar 
        v-if="visibleOption.swatch" 
        :color="visibleOption.swatch" 
        class="elevation-4"
        left
      />
      <v-list-item-avatar v-if="visibleOption.icon" class="elevation-4">
        <v-img :src="getIcon(visibleOption.icon)"/>
      </v-list-item-avatar>      
      <v-list-item-avatar v-if="visibleOption.iconcompact" class="elevation-4">
        <v-img :src="getIcon(visibleOption.iconcompact)"/>
      </v-list-item-avatar>      

      <v-list-item-content color='cardaccent' two-line>
          <v-list-item-title v-text="material.title"></v-list-item-title>
          <v-list-item-subtitle>{{ visibleOption.title }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
      <v-divider></v-divider>

      <materialmenu :material="material"/>

  </v-list-group>
</template>