<script>
import playerTools from '@/tools/sketchfab/player'
import store from 'Store'
import splash from '../splash'
import annotationoverlay from '../annotation-overlay'
import nmalightoverlay from '../project/nma-light-overlay'
import tweakpaneoverlay from '../tweakpane/tweakpane-overlay'
// import FabCommercial from '../fab-commercial.vue'
import FabCamera from '../fab-camera.vue'
import FabScreenshot from '../fab-screenshot.vue'
import configure from "@/tools/configurator/configure"
import FabPanoExit from '../fab-pano-exit.vue'

export default {
  name: 'playerSketchfab',
  props: {
    sceneuid: String
  },
  components: {
    annotationoverlay,
    splash,
    nmalightoverlay,
    tweakpaneoverlay,
    // FabCommercial,
    FabCamera,
    FabScreenshot,
    FabPanoExit,
  },
  data: function () {
    return {
    }
  },  

  computed: {
    loaded () {
      return this.$store.getters['scenes/getsceneLoaded']
    },
    panoActive () {
      return this.$store.state.pano.panoActive
    },
    cameraMoves () {
      return this.$store.state.ui.cameraMoves
    },
    editorvisible () {
      return this.$store.state.ui.editorvisible
    },
    manipulatingSlider () {
      return this.$store.state.ui.manipulatingSlider
    },
    ui () {
      return this.$store.getters['ui/getState']
    },    
    myStyle () {
      const pointer = this.ui.navigatebyannotationonly ? 'none' : 'all'

       return {
        'pointer-events': pointer
      }     
    }
  },
  mounted () {
    configure.loadScene(this.sceneuid)
  },
  methods: {}  
}
</script>

<template>
  <v-container id="playercontainer" class="fill-height player-container" fluid>

    <fab-camera v-if="loaded && !panoActive"/>
    <fab-screenshot v-if="loaded"/>
    <fab-pano-exit v-if="loaded && panoActive"/>
    <splash v-if="!loaded"/>
    <div v-show="editorvisible" class="editor-overlay" id="editor-overlay"/>
    <annotationoverlay v-if="loaded && !panoActive" :cameraMoves="cameraMoves"/>
    <nmalightoverlay v-if="loaded" :cameramoves="cameraMoves" :manipulatingSlider="manipulatingSlider"/>
    <tweakpaneoverlay v-if="loaded" :cameramoves="cameraMoves"/>
    <iframe class="player" :class="{'iframe__hidden': !loaded}" id="player__iframe" :style="myStyle">
    </iframe>
  </v-container>
</template>

<style lang="scss" scoped>

.player-container {
  padding:0px;
  align-items: start;
  position: relative;
}
.iframe {
  height:100%;
  width: 100%;
  position: absolute;

}
.editor-overlay {
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  top: 8px;
  right: 8px;  
  z-index:5;
  width: 356px;
  max-height: 90%;
  min-height: 250px;
  
  --tp-base-background-color: hsla(40, 3%, 90%, 1.00);
  --tp-base-shadow-color: hsla(0, 0%, 0%, 0.30);
  --tp-button-background-color: hsla(40, 3%, 70%, 1.00);
  --tp-button-background-color-active: hsla(40, 3%, 55%, 1.00);
  --tp-button-background-color-focus: hsla(40, 3%, 60%, 1.00);
  --tp-button-background-color-hover: hsla(40, 3%, 65%, 1.00);
  --tp-button-foreground-color: hsla(40, 3%, 20%, 1.00);
  --tp-container-background-color: hsla(40, 3%, 70%, 1.00);
  --tp-container-background-color-active: hsla(40, 3%, 55%, 1.00);
  --tp-container-background-color-focus: hsla(40, 3%, 60%, 1.00);
  --tp-container-background-color-hover: hsla(40, 3%, 65%, 1.00);
  --tp-container-foreground-color: rgb(0, 0, 0);
  --tp-groove-foreground-color: hsla(40, 3%, 40%, 1.00);
  --tp-input-background-color: hsla(120, 3%, 20%, 1.00);
  --tp-input-background-color-active: hsla(120, 3%, 35%, 1.00);
  --tp-input-background-color-focus: hsla(120, 3%, 30%, 1.00);
  --tp-input-background-color-hover: hsla(120, 3%, 25%, 1.00);
  --tp-input-foreground-color: rgb(113, 176, 235);
  --tp-label-foreground-color: rgb(0, 0, 0);
  --tp-monitor-background-color: hsla(120, 3%, 20%, 1.00);
  --tp-monitor-foreground-color: hsla(120, 40%, 60%, 0.80);
}
.player {
  width: 100%;
  height: 100%;
  // overflow: hidden;
  border: 0;
  // opacity: 1;
  transition: opacity 2.0s ease;
  // background: #ddd;
  position: relative;
  z-index:2;

  &.iframe__hidden {
    opacity: 0;
  }
}

</style>
