<script>
import configure from '@/tools/configurator/configure'

export default {
  name: 'panomenu',
  props: {
    options: Array,
    name: String,
    avatarsize: Number
  },
  computed: {
    currentPano () {
      return this.$store.getters['pano/getItemByName'](this.name)
    },
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    setPano (pano, option) {
      this.$store.commit('pano/updateSelected', {group: pano.name, option: option})
      this.$store.commit('pano/currentPano', pano.name)
      configure.setPano(option.payload.cameraconstraints, option.payload.view, option.payload.image, option.scenematerial, option.payload.UVoffset)

      // modify UI. Remove elements which interfere with the pano
      // Hide hotspots
      this.$store.commit('pano/panoActive', true)
      if (this.mobile) {
        this.$store.commit('ui/setBottomnav', false)
      } else {
        this.$store.commit('ui/setDesktopnav', false)
      }

    },
    getThumbnail (option) {
      if (option.thumbnail) {
        return (process.env.BASE_URL + option.thumbnail)
      } else {
        return null
      }
    },      
  }  
}
</script>

<template>
<div>
  <v-sheet
    class="d-flex flex-wrap pa-2"
    elevation="0"
    color="cardaccent2"
  >
    <v-sheet
      v-for="(option, index) in options" 
      :key="index" 
    >

      <v-avatar
        tile
        v-if="option.thumbnail" 
        @click="setPano(currentPano, option)"
        :size="avatarsize ? avatarsize : 122"
      >
        <img
          :src="getThumbnail(option)"
        >      
      </v-avatar>
      <v-avatar
        tile
        v-else 
        @click="setPano(currentPano, option)"
        size=122
        color="secondary"
      >
        {{ option.title }}
      </v-avatar>
    </v-sheet>
  </v-sheet>
</div>
</template>