<script>
import configure from '@/tools/configurator/configure'
import transformtools from '@/tools/sketchfab/transform'

export default {
  name: 'transformrotateslider',
  props: {
    option: Object,
    name: String
  },
  data: () => ({
      rotation: 0,
      matrix: null
  }),  
  computed: {
    currentTransform () {
      return this.$store.getters['transform/getItemByName'](this.name)
    },
    currentrotation: {
      get: function () {
        return this.rotation
      },
      set: function (newValue) {
        if (this.matrix === null) {
          this.setupMatrix()
        }
        this.rotation = newValue
        transformtools.rotate(this.option.node, newValue, this.matrix, this.option.payload.axis)
      }
    },    
  },
  mounted () {
    this.rotation = this.option.payload.default
  },
  methods: {
    setupMatrix () {
      // calculate the original transform of the node. All rotations are done
      // based on this original rotation
      let self = this
      transformtools.getLocalMatrix(this.option.node).then(m4 => {
        self.matrix = m4
      })
    }
  }  
}
</script>

<template>
  <v-slider
    class="timeslider black--text mx-3"
    hide-details
    :label="option.title"
    :min="option.payload.min"
    :max="option.payload.max"
    v-model="currentrotation"
  >
    <template v-slot:append>
      <v-text-field
        v-model="rotation"
        class="mt-0 pt-0"
        hide-details
        single-line
        readonly
        type="string"
        style="width: 40px"
      ></v-text-field>
    </template>
  </v-slider> 
</template>