<script>
import configure from '@/tools/configurator/configure'

export default {
  name: 'visibilitysingleoption',
  components: {
  },
  props: {
    optionname: String
  },
  computed: {
    theOption () {
      return this.$store.getters['visibilitysingle/getItemByName'](this.optionname)
    },
    theSwitch () {
      return this.theOption.visible
    },
  },
  methods: {
    toggleSwitch () {
      this.$store.commit('visibilitysingle/updateSelected', {group: this.optionname, option: !this.theOption.visible})
      configure.SetVisibilitySingle(this.theOption.visible, this.theOption.objects, this.theOption.exactname)
    }
  }  
}
</script>

<template>
<v-list-item-group>
  <v-list-item 
    @click="toggleSwitch"
  >
    <template 
      v-slot:default
    >
      <v-list-item-content >
        <v-list-item-title
          v-if="theOption"
          v-text="theOption.title"
        ></v-list-item-title>
      </v-list-item-content>
      <v-list-item-action >
        <v-switch 
          color="accent" 
          @click.stop="toggleSwitch" 
          :input-value="theSwitch" 
        >
        </v-switch>
      </v-list-item-action>
    </template>
  </v-list-item>
</v-list-item-group>
</template>