import scene from './scene'
import {DirectionalLight} from '@babylonjs/core/Lights/directionalLight';
import {Vector3} from '@babylonjs/core';
import {ShadowGenerator} from '@babylonjs/core';
import store from 'Store'


let shadowGenerator;

const setupLighting = function (lightSettings){
    let sc = scene.getScene()

    //disable the default scene light which comes with teh babylon exporter
    const hemi = sc.lights[0]
    hemi.intensity = 0

    lightSettings.forEach(settings => {
        let light = new DirectionalLight(settings.name, new Vector3(settings.vector[0], settings.vector[1], settings.vector[2]), sc);
        light.position = new Vector3(settings.pos[0], settings.pos[1], settings.pos[2]);
        light.intensity = settings.intensity;
        if (settings.shadowMinZ) light.shadowMinZ = settings.shadowMinZ;
        if (settings.shadowMaxZ) light.shadowMaxZ = settings.shadowMaxZ;
        if (settings.includedOnlyMeshes) {
            settings.includedOnlyMeshes.forEach(meshname => {
                light.includedOnlyMeshes.join(scene.getMesh(meshname))
            })
        }
    });
}

const getLight = function (name) {
    let sc = scene.getScene()
    const light = sc.lights.find(node => node.name === name)
    return light
}

const setupShadows = function (shadowSettings){
    const light = getLight(shadowSettings.lightname)

    if (light) {
        shadowGenerator = new ShadowGenerator(shadowSettings.size, light);
        shadowGenerator.bias = shadowSettings.bias;
        shadowGenerator.usePercentageCloserFiltering  = true;
    }

}

const addShadowCaster = function () {
    let sc = scene.getScene()

    sc.meshes.forEach(mesh => {
        shadowGenerator.addShadowCaster(mesh)
        mesh.receiveShadows = true
    })
}

export default { setupLighting, getLight, setupShadows, addShadowCaster }
