<script>
import configure from '@/tools/configurator/configure'

export default {
  name: 'labeloption',
  components: {
  },
  props: {
    optionname: String
  },
  computed: {
    theOption () {
      return this.$store.getters['labels/getByName'](this.optionname)
    }
  },
  methods: {
    urlButton (address) {
      window.open(address, '_blank');
    }
  }  
}
</script>

<template>
<div>
  <v-card-text v-if="theOption.text" class="body-2">
    {{ theOption.text }}
  </v-card-text>
  <v-card-text v-if="theOption.url" class="body-2 py-2 grey--text text--lighten-1">
    <v-btn @click="urlButton(theOption.url.address)">
      {{ theOption.url.title }}
    </v-btn>
  </v-card-text>
</div>
</template>