import tweakpane from '../../tweakpane'
import lighting from '../lighting';
import materials from '../materials';
import { vec3, mat4, mat3, glMatrix, quat } from 'gl-matrix'
import store from 'Store'
let folder = null

let values = {
}

let presetkeys = [
  'Lights-enabled',
  'Lights-angle',
  'Lights-colorrgb',
  'Lights-attached',
  'Lights-falloff',
  'Lights-intensity',
  'Lights-matrix',
  'Lights-shadowEnabled',
  'Lights-type',
  'Lights-id',
  'Lights-pos',
  'Lights-quat',
]
/**
 * Convert a color from array formatting to object formatting
 * @param {array} color A 3-element array, normalized between 0-255.
 * @returns {r:,g:,b:} color object, normalized between 0-255
 */
 const convertColorToObject = function (color) {
  const normalizedColor = materials.colorNormalize255(color)
  return {r: normalizedColor[0],g: normalizedColor[1],b: normalizedColor[2]}
}

const convertColorToArray = function (color) {
  return [color.r, color.g, color.b]
}


const buildGUI = async function() {
  values = await lighting.getLight(0)
  values.lightid = 0
  values.import = ''
  deriveLightValues()

  const lightTypes = {
    'Spot': 'SPOT',
    'Directional': 'DIRECTION',
    'Point': 'POINT',
  }
  const lightIds = {
    0: 0,
    1: 1,
    2: 2,
  }
  folder = tweakpane.pane.addFolder({
    title: 'Lights',
    hidden: true
  })

  const btnExport = folder.addButton({
    title: 'Export Lights',
  })
  btnExport.on('click', () => {
    values.import = tweakpane.exportJSON('lights')
    tweakpane.updateGui()
  })  
  const btnImport = folder.addButton({
    title: 'Import Lights',
  })
  btnImport.on('click', () => {
    tweakpane.refreshing = true
    tweakpane.pane.importPreset(JSON.parse(values.import));
    tweakpane.refreshing = false
    applyValues(null)
  })  
  folder.addInput( values, 'import')

  folder.addInput(values, 'lightid', {
    options: lightIds,
    presetKey: 'Lights-id'
  })

  folder.addInput(values, 'enabled', {presetKey: 'Lights-enabled'})
  folder.addInput(values, 'type', {
    options: lightTypes,
    presetKey: 'Lights-type'
  })
  folder.addInput(values, 'intensity', {min: 0, max: 10, step: 0.01, presetKey: 'Lights-intensity'})
  folder.addInput(values, 'angle', {min: 0, max: 90, step: 1, presetKey: 'Lights-angle'})
  folder.addInput(values, 'falloff', {min: 0, max: 1, step: 0.01, presetKey: 'Lights-falloff'})
  // folder.addInput(values, 'softness', {min: 0, max: 1, step: 0.01, presetKey: 'Lights-softness'})
  folder.addInput(values, 'shadowEnabled', {presetKey: 'Lights-shadowEnabled'})
  folder.addInput(values, 'attached', {presetKey: 'Lights-attached'})
  folder.addInput(values, 'colorrgb', {
    presetKey: 'Lights-colorrgb',
    picker: 'inline',
    expanded: true,
  })
  folder.addInput(values, 'quat', {
    view: 'rotation',
    rotationMode: 'quaternion',
    picker: 'inline',
    expanded: true,
    presetKey: 'Lights-quat'
  })
  folder.addInput(values, 'pos', {presetKey: 'Lights-pos'})

  folder.on('change', (event) => applyValues(event))


}

const initLightValues = async function (lightid) {
  deriveLightValues()
}

const deriveLightValues = function () {
  console.log("values", values);
  values.colorrgb = convertColorToObject(values.color)
  const q = getQuatFromLightMatrix(values.matrix)
  values.quat = {x: q[0], y: q[1], z: q[2], w: q[3]}
  values.pos = {x: values.matrix[12], y: values.matrix[13], z: values.matrix[14]}
  console.log("values.quat", values.quat);
}

const getValues = function(payload) {
  console.log("payload", payload);
  if (payload) {
    for (let value in payload) {
      values[value] = payload[value]
    }
    deriveLightValues()
  }
  // values.colorrgb = convertColorToObject(values.color)
}

async function applyValues (event) {
  const light = await lighting.getLight(values.lightid)
  if (!light) return
  if (event?.presetKey === 'Lights-id') {
    console.log("light", light);
    values.lightid = event.value
    getValues(light)

    
    tweakpane.updateGui()
  } else {
    
    values.color = materials.colorNormalize1(convertColorToArray(values.colorrgb))

    let m4 = mat4.create()
    const q = quat.fromValues(values.quat.x, values.quat.y, values.quat.z, values.quat.w)
    mat4.fromQuat(m4, q)
    m4[12] = values.pos.x
    m4[13] = values.pos.y
    m4[14] = values.pos.z
    values.matrix = glMatrixToSfMatrix(m4)

    lighting.setLight(values.lightid, values)
    store.dispatch('nmalight/setLight', {id: values.lightid, light: JSON.parse(JSON.stringify(values))})
    
    
    // create a target at some distance of the light
    let v3delta = vec3.fromValues(0,0,-2)
    let v3target = vec3.create()
    vec3.transformQuat(v3target, v3delta, q)
    vec3.add(v3target, v3target, vec3.fromValues(m4[12], m4[13], m4[14]))

    console.log("v3target", v3target);
    const light3d = [v3target.x, v3target.y, v3target.z]
    console.log("light3d", light3d);
    store.dispatch('nmalight/setLight3d', {id: values.lightid, coord: v3target})

  }
}

const glMatrixToSfMatrix = function (matrix) {
  let sf_matrix = []
  sf_matrix[0] = matrix[4]
  sf_matrix[1] = matrix[5]
  sf_matrix[2] = matrix[6]
  sf_matrix[3] = matrix[7]

  sf_matrix[4] = -matrix[0]
  sf_matrix[5] = -matrix[1]
  sf_matrix[6] = -matrix[2]
  sf_matrix[7] = -matrix[3]

  sf_matrix[8] = matrix[8]
  sf_matrix[9] = matrix[9]
  sf_matrix[10] = matrix[10]
  sf_matrix[11] = matrix[11]

  sf_matrix[12] = matrix[12]
  sf_matrix[13] = matrix[13]
  sf_matrix[14] = matrix[14]
  sf_matrix[15] = matrix[15]

  return sf_matrix  
}

const sfM4toflM4 = function (sf_matrix) {
  return mat4.fromValues(
    -sf_matrix[4],
    -sf_matrix[5],
    -sf_matrix[6],
    -sf_matrix[7],
    sf_matrix[0],
    sf_matrix[1],
    sf_matrix[2],
    sf_matrix[3],
    sf_matrix[8],
    sf_matrix[9],
    sf_matrix[10],
    sf_matrix[11],
    sf_matrix[12],
    sf_matrix[13],
    sf_matrix[14],
    sf_matrix[15],
  )
}


const getQuatFromLightMatrix = function (sf_matrix) {
  const m4 = sfM4toflM4(sf_matrix)
  const m3 = mat3.create()
  mat3.fromMat4(m3, m4)
  const q = quat.create()
  quat.fromMat3(q, m3)
  return q
}

export default {
  get values() {
    return values;
  },  
  get folder() {
    return folder;
  },
  get presetkeys() {
    return presetkeys
  },
  set values(newvalue) {
    return values = newvalue;
  },  
  buildGUI,
  getValues
}