<script>
import configure from '@/tools/configurator/configure'

export default {
  components: {
  },
  data: () => ({
    viewIdx: 1
  }),
  computed: {
    views () {
      return this.$store.getters['view/getAll']
    },
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    hasscreenshot()    {
      return this.$store.state.ui.query?.screenshot === '1'
    }
  },
  methods: { 
    screenshot () {
      configure.downloadScreenshot();
    }
  },
  mounted () {
  }
}
</script>

<template>
  <v-btn
    v-if="hasscreenshot"
    fab
    absolute
    :small="mobile"
    color="accent"
    class="camera-fab mr-16 mb-2"
    @click="screenshot"
  >
    <v-icon 
      :large="!mobile"
    >mdi-camera</v-icon>
  </v-btn>
</template>

<style lang="scss" scoped>
.camera-fab {
  right: 0px;
  bottom: 0px;
}
</style>
