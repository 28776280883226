import _common from './_common'

const state = {
  all: {},
  current: '',
  show: true
}

const getters = {
  ..._common.getters,
  getCurrent (state) {
    const current = Object.keys(state.all).find(key => key === state.current)
    if (current) {
      return state.all[current]
    } else if (state.all === {}) {
      return null
    } else {
      return Object.values(state.all)[0]
    }
  },
  getShow (state) {
    return state.show
  }, 
}

const actions = {
}

const mutations = {
  ..._common.mutations,
  updateCurrent (state, name) {
    state.current = name
  },
  updateShow (state, show) {
    state.show = show
  },
  setVisible (state, payload) {
    let newState = {}
    Object.keys(state.all).forEach(key => {
      newState[key] = state.all[key]
      if (key === payload.annotationName) {
        newState[key].show = payload.visibility
      } else {
        newState[key].show = false
      }
    })
    state.all = {}
    state.all = newState
  },  
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}