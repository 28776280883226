import _base from './_base-module'
import templateData from 'ProjectData/template'
import Label from '@/classes/Label'
const Model = Label

const state = {
  all: []
}

const getters = {
  ..._base.getters,
  getByName: (state) => (name) => {
    let item = state.all.find(item => item.name === name)
    if (item === undefined) item = new Label()
    return item
  }  
}

const actions = {
  fetchData ({ commit, rootGetters  }, {scene}) {
    // Labels can exist in two places: the menu or a separate
    // Annotations list
    // commit('clearState')
    let labels = []

    // Get labels from the menu
    for (let menuitem of scene.menu) {
      if (menuitem.options) {
        for (let option of menuitem.options) {
          if (option.label) {
            const label = templateData.labels.find(item => item.name === option.label)
            label ? labels.push(label) : null
          }
        }
      }
    }

    // Get labels from the annotations
    const annotations = rootGetters['annotation/getAll']
    for (let annotation of annotations) {
      if (annotation.payload.action.label) {
        const label = templateData.labels.find(item => item.name === annotation.payload.action.label)
        label ? labels.push(label) : null
      }
    }
    commit('init', labels)
  }
}

const mutations = {
  ..._base.mutations,
  init(state, set) {
    return _base.mutations.init(state, set, Model);
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
