import tweakpane from '../../tweakpane'
import environment from '../environment';
import materials from '../materials';

let folder = null

let values = {
  color: [1, 1, 1]
}
let presetkeys = [
  'Background-colorrgb',
]

const buildGUI = function() {
  folder = tweakpane.pane.addFolder({
    title: 'Background',
    hidden: true
  })
  folder.addInput( values, 'colorrgb', {
    presetKey: 'Background-colorrgb',
    picker: 'inline',
    expanded: true,
  })
  folder.on('change', (event) => applyValues(event))
}

function applyValues (value) {
  values.color = tweakpane.convertColorToArray(values.colorrgb)
  values.color = materials.colorNormalize1(values.color)
  console.log("values", values);
  environment.setBackground(values, null)
}

export default {
  get values() {
    return values;
  },  
  get folder() {
    return folder;
  },
  get presetkeys() {
    return presetkeys
  },
  set values(newvalue) {
    return values = newvalue;
  },  
  buildGUI,
}