import _base from './_base-module'
import templateData from 'ProjectData/template'

const state = {
	all: []
  }
  
  const getters = {
	..._base.getters,
	getText: (state) => (name) => {
		const textitem = state.all.find(item => item.name === name)
		return textitem.text
	},
	getTextitem: (state) => (name) => {
		const textitem = state.all.find(item => item.name === name)
		return textitem
	}	
  }

const actions = {
	fetchData ({ commit }) {
		commit('clearTexts')
		if (templateData.textoptions) {
				templateData.textoptions.forEach(textoption => {
				commit('addText', textoption)
			})
		}
	},
	setText ({ commit }, {name, text}) {
		commit('updateText', {name: name, text: text})
	},
	
}
  
const mutations = {
	..._base.mutations,
	addText (state, textoption) {
		state.all.push(textoption)
	},
	clearTexts (state) {
		state.all = []
	},
	updateText (state, payload) {
		state.all = state.all.map(item => {
			if (item.name === payload.name) {
				item.text = payload.text
			} return item
		})		
	}  	
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
