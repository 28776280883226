import _common from './_common'

const state = {
  all: {},
  panoActive: false,
  currentPano: null
}

const getters = {
  ..._common.getters
}

const actions = {
}

const mutations = {
  ..._common.mutations,
  panoActive (state, active) {
    state.panoActive = active
  },
  currentPano (state, name) {
    state.currentPano = name
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}