import scene from './scene'
import {HDRCubeTexture} from '@babylonjs/core';
import {CubeTexture} from '@babylonjs/core';
import {Color3} from '@babylonjs/core';

import {StandardMaterial, Mesh} from '@babylonjs/core';

let hdrTexture;

const setupEnvironment = function (settings){
    let sc = scene.getScene()

    sc.clearColor = new Color3(settings.clearcolor[0], settings.clearcolor[1], settings.clearcolor[2]);

    sc.imageProcessingConfiguration.exposure = settings.exposure;
    sc.imageProcessingConfiguration.contrast = settings.contrast;

    // scene.createDefaultSkybox(hdrTexture, true, 5000);

    // var hdrTexture = new CubeTexture.CreateFromPrefilteredData('/data/appliance/env/environment.env', sc);
    // hdrTexture.gammaSpace = false;
    // sc.environmentTexture = hdrTexture;    
}

const buildSkybox = function () {
    let sc = scene.getScene()

    // Skybox
    var skybox = Mesh.CreateBox("skyBox", 5.0, sc);
    skybox.flipFaces(true);
    var skyboxMaterial = new StandardMaterial("skyBox", sc);
    // skyboxMaterial.backFaceCulling = false;
    // skyboxMaterial.twoSidedLighting = true;
    skyboxMaterial.useEmissiveAsIllumination = true;
    // skyboxMaterial.reflectionTexture = new BABYLON.CubeTexture("/textures/skybox", scene);
    // skyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
    skyboxMaterial.diffuseColor = new Color3(1, 1, 1);
    skyboxMaterial.emissiveColor = new Color3(1, 1, 1);
    skyboxMaterial.specularColor = new Color3(1, 1, 1);
    skyboxMaterial.disableLighting = true;
    skybox.material = skyboxMaterial;
    skybox.infiniteDistance = true;    
}
const makeEnvHdr = function (filename){
    let sc = scene.getScene()
    hdrTexture = new HDRCubeTexture('/data/' + filename, sc, 512);
}

const getHdr = function() {
    return hdrTexture
}
export default { makeEnvHdr, setupEnvironment, getHdr, buildSkybox }
