import Sketchfab from '@sketchfab/viewer-api'
import materials from "./materials";
import textures from "./textures";
import scene from "./scene";
import init from  "../configurator/init"
import configure from  "../configurator/configure"
// import playersettings from "data/playersettings";
import store from "../../store";
import sensar from "../project/sensar"
import tavannes from '../project/tavannes';
import nma from '../project/nma';
import axios from 'axios'
import datatools from '../datatools'

let sketchfabApi

const getClient = function (elementId) {
  const iframe = document.getElementById(elementId)
  const version = '1.12.0'
  return new Sketchfab(version, iframe)
}

const loadScene = function (sceneId, playerSettings, elementId, onSuccess) {
  let client = getClient(elementId)

  client.init(sceneId, {
    success: onSuccess,
    error: function (error) {
      console.log(error)
    },
    autospin: 0.0,
    preload: 0,
    // transparent: playerSettings.transparent,
    camera: 1, // skips the entry animation, we have more control by setting the viewer position manually
    //  blending: 0,
    ui_stop: 0,
    ui_infos: 0, // need biz account
    ui_controls: 0, // need biz account
    ui_watermark: 0,
    scrollwheel: 1,
    graph_optimizer: 0,
    merge_materials: 0,
    ...playerSettings
  })

}

const playerSuccess = function (api) {
  sketchfabApi = api
  sketchfabApi.load()
  sketchfabApi.start()

  sketchfabApi.addEventListener('viewerready', function () {
    initViewer()
  })
  api.addEventListener('click', function (info) {
    console.log('Mouseclick', info)
  }, { pick: 'fast' }) // use slow when the model is high-res or animated
  api.addEventListener('camerastart', function () {
    store.dispatch('ui/cameraStart')
  })
  api.addEventListener('camerastop', function () {
    store.dispatch('ui/cameraStop')
  })
}


const initViewer = async function () {
  const query = store.state.ui.query
  // get data from the scene for future use
  await textures.storeTextureList();
  await scene.storeSceneGraph();
  await materials.storeMaterialList();

  // set up the viewer
  configure.SetPostProcessing()
  configure.SetBackground()
  configure.SetLighting()
  configure.SetEnvironment()

  const currentScene = store.getters['scenes/getCurrentScene']
  if (query.view) {
    console.log('Setting view from query')
    const view = JSON.parse(query.view)
    configure.setView(view)
  } else {
    init.initViews(currentScene.view)
  }
  let promises = []
  await init.initPresetgroup()

  if (query.setup) {
    const setup = JSON.parse(query.setup)
    if (setup.nmalight) {
      store.commit('nmalight/import', setup.nmalight)
    }
    if (setup.material) {
      datatools.material.importData(setup.material)
    }
    if (setup.visibilitysingle) {
      datatools.visibilitysingle.importData(setup.visibilitysingle)
    }
    if (setup.visibilitygroup) {
      datatools.visibilitygroup.importData(setup.visibilitygroup)
    }
    if (setup.presetgroup) {
      datatools.presetgroup.importData(setup.presetgroup)
    }
  } else if (query.setupfile) {
    
    console.log("query.setupfile", query.setupfile);
    const setupfile = `${process.env.BASE_URL}/${query.setupfile}`
    const config = {
      method: "get",
      url: setupfile,
      headers: {},
    };
    axios(config)
    .then((response) => {
      console.log("response.data", response.data);
      if (response.data.nmalight) {
        console.log("setup.nmalight", response.data.nmalight);
        store.commit('nmalight/import', response.data.nmalight)
      }
      if (response.data.material) {
        console.log("setup.material", response.data.material);
        datatools.material.importData(response.data.material)
      }
  
    })    
  } else {
    nma.initLights(promises)
  }
  // set up initial states
  await init.initMaterials()
  init.initVisibility()
  // await init.initPresetgroup()
  init.initEnvironment()
  init.initLiquid()

  // Project specific init functions
  sensar.initTerrain(promises)
  tavannes.startTime()

  
  Promise.all(promises).then(values => {
    // open the sidebar
    console.log('SCENE LOADED')
    store.dispatch('scenes/setSceneLoaded')
  })  
};

// const getApi = function () {
//   return sketchfabApi
// }

export default {
  get api() {
    return sketchfabApi;
  },  
  // getApi, 
  loadScene,
  playerSuccess
}
