<script>
import nmatools from "@/tools/project/nma"
import cameratools from '@/tools/sketchfab/camera'
// import configure from '@/tools/configure'

export default {
  name: "lightcontrol",
  components: {},
  props: {
  },
  computed: {
     
    current_camera_fov: {
      get: function () { return this.$store.state.nmalight.camera.fov },
      set: function (newValue) {
        this.$store.commit('nmalight/setCameraProperty', {key: 'fov', property: newValue})
        this.setFov(newValue) 
      }
    },
    latlon () {
      return this.$store.state.nmalight.camera.latlon
    },    
    cameramoves () {
      return this.$store.state.ui.cameraMoves
    },    
  },
  data: function () {
    return {
      sf_light_matrix: null,
      light3d: [0,0,0],
      latlon_temp: [0,0]
    }
  },     
  methods: {
    setFov(fov) {
      this.fov = fov
      cameratools.setFov(fov)
    },
    recenter() {
      /*
      Radius formula
      r = atan(0.20/fov)*1000
      */
      cameratools.getCamera()
      .then(camera => {
        const r = Math.atan(0.20/this.fov)*1000

        this.setLatlon(this.latlon, -r, camera.target)
        console.log("camera", camera);
      })

    },
    inputLat (value) {
      if (!this.cameramoves) this.setLatlon([value, this.latlon[1]])
    },
    inputLon (value) {
      if (!this.cameramoves) this.setLatlon([this.latlon[0], value])
    },
    changeLatlon () {
      this.$store.commit('nmalight/setCameraProperty', {key: '3d', property: this.light3d})
      this.$store.commit('nmalight/setCameraProperty', {key: 'latlon', property: this.latlon_temp})
      this.$store.commit('ui/setManipulatingSlider', false)
    },
    startLonLatSlider () {
      this.$store.commit('ui/setManipulatingSlider', true)

    },
    setLatlon (latlon, radius, target) {
      this.sf_light_matrix = nmatools.setLightMatrixFromLatLon(latlon, radius, target)
      this.light3d = [this.sf_light_matrix[12], this.sf_light_matrix[13], this.sf_light_matrix[14]]
      cameratools.setCameraUnconstrained(this.light3d, target, 0)
      this.latlon_temp = latlon
      // this.$store.dispatch('nmalight/setLight3d', {id: this.lightid, coord: this.light3d})
      // this.$store.dispatch('nmalight/setCameraLatLon', {coord: latlon})
    },    
  },
  async mounted() {
    const camera = await cameratools.getCamera()
    console.log("camera", camera);
    const angle = await cameratools.getFov()    
    const latlon = nmatools.getLatLonFromPos(camera.position)
    this.$store.commit('nmalight/setCameraProperty', {key: 'latlon', property: latlon})
    cameratools.getFov()
    .then(fov => {
      this.current_camera_fov = fov
    })
    // this.$store.dispatch('nmalight/setCameraLatLon', {coord: latlon})

    
  }
};
</script>

<template>
<div>
  <v-card flat tile>
    <v-slider
      class="black--text mx-3"
      hide-details
      label="Field of view"
      min="0.2"
      max="120"
      step="0.1"
      v-model="current_camera_fov"
      @end="recenter"
    >
      <template v-slot:append>
        <v-text-field
          v-model="current_camera_fov"
          class="mt-0 pt-0"
          hide-details
          single-line
          readonly
          type="string"
          style="width: 40px"
        ></v-text-field>
      </template>
    </v-slider>

    <v-slider
      class="black--text mx-3"
      hide-details
      label="Lat"
      step="1"
      min="-90"
      max="90"
      disabled
      :value="latlon[0]"
      @input="inputLat"
      @start="startLonLatSlider"
      @change="changeLatlon"
    >
      <template v-slot:append>
        <v-text-field
          :value="latlon[0]"
          class="mt-0 pt-0"
          hide-details
          single-line
          readonly
          type="string"
          style="width: 40px"
        ></v-text-field>
      </template>
    </v-slider>

    <v-slider
      class="black--text mx-3"
      hide-details
      label="Lon"
      step="1"
      min="-180"
      max="180"
      disabled
      :value="latlon[1]"
      @input="inputLon"
      @start="startLonLatSlider"
      @change="changeLatlon"
    >
      <template v-slot:append>
        <v-text-field
          :value="latlon[1]"
          class="mt-0 pt-0"
          hide-details
          single-line
          readonly
          type="string"
          style="width: 40px"
        ></v-text-field>
      </template>
    </v-slider>


  </v-card>
</div>
</template>

<style lang="scss">


</style>
