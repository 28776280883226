import _common from "./_common"
import store from 'Store'

const type = 'optionhook'

class optionhook {
  title = ''
  name = ''
  visible = null
  hidden = false
  expand = false
  options = []
  optionhook = null

  constructor (data) {
    this.title = data.title ?? ''
    this.name = data.name ?? ''
    this.visible = data.visible ?? null
    this.expand = data.expand ?? false
    this.options = data.options ?? []
    this.optionhook = data.optionhook ?? null

    if (this.options.length > 0) {
      this.visible = this.visible ?? this.options[0].name
    }
  }

  setHidden(value) {
    this.hidden = value ?? false
  }

  setVisible(value) {
    this.visible = value ?? this.visible
  }

}


const assembleData = function (menu) {
  const menuItems = _common.getMenuItems(menu, type)

  return menuItems.map(menuItem => {
    const template = _common.getTemplateItem(menuItem[type], type)
  
    if (template) {
      let option = new optionhook(template)
      option.setHidden(menuItem.hidden)
      option.setVisible(menuItem.visible)
      return option
    } else {
      return null
    }
  })

}

const fetchData = function (menu) {
  _common.fetchMenuData(assembleData, menu, type)
}

const importData = function (data) {
  return _common.importGroupData(data, type)
}

const exportData = function () {
  return _common.exportGroupData(type)
}

const exportDataPretty = function () {
  return _common.exportGroupDataPretty(type)
}

/**
 * The material menus can subscribe to one or more optionhooks. If they do,
 * the menu is visible in case one of the hooks is active. The menu is hidden 
 * in case none of the hooks is active.
 */
 const hookMenuVisibility = function () {
  const optionhooks = store.getters['optionhook/getAll']
  // const materials = store.getters['material/getAll']

  optionhooks.forEach(optionhook => {
    if (optionhook.optionhook) {
      const hasActiveHook = optionhook.optionhook.find(optionhookhook => {
        return optionhooks.find(optionhook => {
          return optionhookhook[optionhook.name] === optionhook.visible
        })
      })
      
      store.commit('optionhook/updateHidden', {name: optionhook.name, hidden: !hasActiveHook})

      // // reset the optionhook to the "visible" value of the optionhook. It's possible the active menu
      // // has less options than the previously active menu.
      // if (hasActiveHook) {
      //   const option = optionhook.options.find(item => item.name === optionhook.visible)
      //   configure.SetMaterialOption(material, option)
      // }
    }
  })
}

export default {
  fetchData,
  importData,
  exportData,
  exportDataPretty,
  hookMenuVisibility
}