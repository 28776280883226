import tweakpane from '../../tweakpane'
import camera from '../camera';

let folder = null

let values = {
}
let presetkeys = [
  'Camera-fov',
  'Camera-position',
  'Camera-target',
]

const getFromScene = async function () {
  values = await camera.getCamera()
  values.fov = await camera.getFov()
  values.import = 'BABY'
  deriveValues()

}

const buildGUI = function() {
  getFromScene()
  console.log("values", values);
  folder = tweakpane.pane.addFolder({
    title: 'Camera',
    hidden: true
  })
  const btnExport = folder.addButton({
    title: 'Export Camera',
  })
  btnExport.on('click', () => {
    
    getFromScene()
    tweakpane.updateGui()

    values.import = tweakpane.exportJSON('camera')
    tweakpane.updateGui()
  })  
  const btnImport = folder.addButton({
    title: 'Import Camera',
  })
  btnImport.on('click', () => {
    tweakpane.refreshing = true
    tweakpane.pane.importPreset(JSON.parse(values.import));
    tweakpane.refreshing = false
    applyValues(null)
  })  
  folder.addInput( values, 'import')

  folder.addSeparator();
  const btnGetCamera = folder.addButton({
    title: 'Get Camera values',
  })
  btnGetCamera.on('click', () => {
    getFromScene()
    console.log("values", values);
    tweakpane.updateGui()
  })  


  folder.addInput(values, 'pos', {step: 0.1, presetKey: 'Camera-position'})
  folder.addInput(values, 't', {step: 0.1, presetKey: 'Camera-target'})

  folder.addInput(values, 'fov', {min: 0,  max: 90, step: 0.1, presetKey: 'Camera-fov'})


  folder.on('change', (event) => applyValues(event))
}

function applyValues (value) {
  camera.setCameraUnconstrained([values.pos.x, values.pos.y, values.pos.z], [values.t.x, values.t.y, values.t.z], 0)
  camera.setFov(values.fov)
}

const deriveValues = function () {
  values.pos = {x: values.position[0], y: values.position[1], z: values.position[2]}
  values.t = {x: values.target[0], y: values.target[1], z: values.target[2]}
  values.import = ''
}

const getValues = function(payload) {
  values.position = payload.eye
  values.target = payload.lookat
  values.fov = payload.fov
  deriveValues()
}
export default {
  get values() {
    return values;
  },  
  get folder() {
    return folder;
  },
  get presetkeys() {
    return presetkeys
  },
  set values(newvalue) {
    return values = newvalue;
  },  
  buildGUI,
  getValues,
}