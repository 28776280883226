import _common from "./_common"
import store from 'Store'
import configure from '@/tools/configurator/configure'

const type = 'material'

class material {
  name = ''
  title = ''
  type = 'single'
  visible = null
  hidden = false
  scenematerial = []
  optionhook = null
  options = []
  optionslistbasedon = null

  constructor (data) {
    this.name = data.name ?? ''
    this.title = data.title ?? ''
    this.type = data.type ?? 'single'
    this.visible = data.visible ?? null
    this.optionslistbasedon = data.optionslistbasedon ?? null
    this.scenematerial = data.scenematerial ?? []
    this.optionhook = data.optionhook ?? null
    this.options = data.options ?? []
  }

  setVisible() {
    if (this.options.length > 0) {
      this.visible = this.visible ?? this.options[0].name
    }
  }

  setHidden(value) {
    this.hidden = value ?? false
  }
}

/**
 * Sometimes the material doesn't store the list of options but only a reference
 * to a materialoptionslists object. This is useful when multiple materials use 
 * the same list of options.
 * @param {object} material a material object
 */
function assembleOptionslist(material) {
  if (material.optionslistbasedon) {
    const list = _common.getTemplateItem(material.optionslistbasedon, 'materialoptionslists')
    if (list) {
      list.options.forEach(option => {
        material.options.push({...option})
      });
    }
  }
}

/**
 * Attempts to merge two material payloads. One payload serves as the base, 
 * the other one is placed on top. If both payloads have corresponding channels,
 * the "ontop" channel takes precedence.
 * @param {object} basePayload An object with material channels
 * @param {object} ontopPayload An object with material channels
 * @returns An new object with material channels
 */
function mergePayloadChannels(basePayload, ontopPayload) {
  let mergedPayload = {}
  
  for (const channel in basePayload) {
    mergedPayload[channel] = basePayload[channel]
  }

  for (const channel in ontopPayload) {
    if (mergedPayload[channel] === undefined) {
      mergedPayload[channel] = ontopPayload[channel]
    } else {
      mergedPayload[channel] = {...basePayload[channel], ...ontopPayload[channel]}
    }
  }
  return mergedPayload
}

/**
 * Assemble the payload of the material option. The payload is what's sent
 * to Sketchfab. this payload comes from the materialoption itself, the template
 * file and the library file
 * @param {object} materialOption A materialoption object
 * @returns An object with material channels
 */
const assembleOptionPayload = function (materialOption) {
  let materialPayload = materialOption.payload

  if (materialOption.basedon) {
    const basedonMaterial = _common.getTemplateItem(materialOption.basedon, 'materials')
    if (basedonMaterial) {
      materialPayload = mergePayloadChannels(basedonMaterial.payload, materialOption.payload)
      const libraryMaterial = _common.getLibraryItem(basedonMaterial.library, 'materials')
      if (libraryMaterial) {
        materialPayload = mergePayloadChannels(libraryMaterial.payload, materialPayload)
      }
    }
  }
  return materialPayload
}

/**
 * If the template materialoption has a swatch and the base materialoption
 * doesn't, take the one from the template
 * @param {object} materialOption A materialoption object
 * @returns a swatch string
 */
const assembleOptionSwatch = function (materialOption) {
  let swatch = materialOption.swatch
  if (materialOption.basedon) {
    const basedonMaterial = _common.getTemplateItem(materialOption.basedon, 'materials')
    if (basedonMaterial) {
      if (basedonMaterial.swatch) {
        swatch = materialOption.swatch ?? basedonMaterial.swatch
      }
    }
  }
  return swatch
}

const assembleData = function (menu) {
  const menuItems = _common.getMenuItems(menu, type)

  const materials =  menuItems.map(menuItem => {
    const template = _common.getTemplateItem(menuItem[type], 'materialoptions')
  
    if (template) {
      let item = new material(template)
      item.setHidden(menuItem.hidden)
      return item
    }
  })

  materials.forEach(material => {
    if (material.type === 'single' || material.type === 'overrideexclude') {
      assembleOptionslist(material)
      material.options.forEach(option => {
        option.payload = assembleOptionPayload(option)
        option.swatch = assembleOptionSwatch(option)
      })
    }

    if (material.type === 'colorway') {
      material.options.forEach(colorwayoption => {
        colorwayoption.swatch = colorwayoption.swatch ?? 'rgb()255,0,128'
        colorwayoption.payload.forEach(option => {
          option.payload = assembleOptionPayload(option)
        })
      })
    }

    material.setVisible()
  })
  return materials
}

const fetchData = function (menu) {
  _common.fetchMenuData(assembleData, menu, type)
}

const importData = function (data) {
  return _common.importGroupData(data, type)
}

const exportData = function () {
  return _common.exportGroupData(type)
}

const exportDataPretty = function () {
  return _common.exportGroupDataPretty(type)
}

/**
 * The material menus can subscribe to one or more optionhooks. If they do,
 * the menu is visible in case one of the hooks is active. The menu is hidden 
 * in case none of the hooks is active.
 */
const hookMenuVisibility = function () {
  const optionhooks = store.getters['optionhook/getAll']
  const materials = store.getters['material/getAll']

  materials.forEach(material => {
    if (material.optionhook) {
      const hasActiveHook = material.optionhook.find(materialhook => {
        return optionhooks.find(optionhook => {
          return materialhook[optionhook.name] === optionhook.visible
        })
      })
      
      store.commit('material/updateHidden', {name: material.name, hidden: !hasActiveHook})

      // reset the material to the "visible" value of the material. It's possible the active menu
      // has less options than the previously active menu.
      if (hasActiveHook) {
        const option = material.options.find(item => item.name === material.visible)
        configure.SetMaterialOption(material, option)
      }
    }
  })
}

export default {
  fetchData,
  importData,
  exportData,
  exportDataPretty,
  hookMenuVisibility,
}