<script>

import menuGroup from '../menugroup'
import annotationmenu from '../annotation-menu'

export default {
  name: 'configuratordrawer',
  components: {
    menuGroup,
    annotationmenu,
  },
  data: () => ({
    modelroute: '/models',
    expanded: null
  }),
  computed: {
    menu () {
      return this.$store.getters['menu/getAll']
    },
    contactcard () {
      return this.$store.state.ui.contactcard
    },
    annotations () {
      return this.$store.getters['annotation/getAll']
    },
    pickModel () {
      return (this.$store.state.scenes.all.length > 1)
    },
  },
  methods: {
  },
  mounted () {
    for (let n = 0; n < this.menu.length; n++) {
      const element = this.menu[n];
      if (element.expand) this.expanded = n
      
    }
  }  
}
</script>

<template>

  <v-expansion-panels
    accordion
    selectable
    tile
    hover
    color="card"
    v-model="expanded"
  >
    <menuGroup 
      v-for="item in menu" 
      :key="item.name"
      :menu="item"
    />

    <v-expansion-panel
      v-if="annotations.length > 0"
    >
      <v-expansion-panel-header 
        color="cardaccent"
        class="text-h6 font-weight-light"
      >
      <v-icon 
        class="headericon"
      >
        mdi-note-text
      </v-icon>        
        Hotspots
      </v-expansion-panel-header>
      <v-expansion-panel-content  color="card">
        <annotationmenu 
          :annotations="annotations"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
    
    <!-- <v-expansion-panel>
      <v-expansion-panel-content  color="card">
        <annotationmenu 
          :annotations="annotations"
        />
      </v-expansion-panel-content>
    </v-expansion-panel> -->
  </v-expansion-panels>
</template>

<style lang="scss" scoped>
  .headericon {
    flex: 0 0 0;
    margin-right: 12px;
  }
</style>