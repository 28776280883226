import _common from "./_common"

const type = 'annotation'

class annotation {
  title = ''
  name = ''
  icon = null
  payload = {}
  show = false

  constructor (data) {
    this.title = data.title ?? ''
    this.name = data.name ?? ''
    this.icon = data.icon ?? null
    this.payload = data.payload
    this.show = data.show ?? data.show
  }
}

const assembleData = function (scene) {
  const items = scene[type]
  if (!items) return null
  return items.map(item => {
    const template = _common.getTemplateItem(item, 'annotation')
    if (template) {
      let option = new annotation(template)
      return option
    } else {
      return null
    }
  })
}

const fetchData = function (menu) {
  _common.fetchMenuData(assembleData, menu, type)
}

export default {
  fetchData,
}