<script>
import panomenu from './pano-menu'

export default {
  name: 'panooption',
  components: {
    panomenu
  },
  data: () => ({

  }),  
  props: {
    optionname: String
  },
  computed: {
    pano () {
      return this.$store.getters['pano/getItemByName'](this.optionname)
    },
    thumbnail () {
      if (this.currentOption?.thumbnail) {
        return (process.env.BASE_URL + this.currentOption.thumbnail)
      } else {
        return null
      }
    }, 
    currentOption () {
      let current = this.pano.options.find(item => item.name === this.pano.currentOption)
      return current
    },
  },
  methods: {
  }  
}
</script>

<template>
  <v-list-group
    v-if="pano && pano.options.length > 0"
    color='primary'
  >
    <template v-slot:activator>

      <v-list-item-avatar v-if="thumbnail" class="elevation-4">
        <v-img :src="thumbnail"/>
      </v-list-item-avatar>      

      <v-list-item-content color='cardaccent' two-line>
          <v-list-item-title v-text="pano.title"></v-list-item-title>
          <v-list-item-subtitle v-if="currentOption">{{ currentOption.title }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <v-divider></v-divider>

    <panomenu :options="pano.options" :name="pano.name"/>

  </v-list-group>

</template>