import configure from './configure'
import store from '@/store'
import datatools from '../../tools/datatools'

const initVisibility = async function () {
  const visibilitysingle = store.getters['visibilitysingle/getAll']
  if (visibilitysingle) {
    for (let opt of visibilitysingle) {
      await configure.SetVisibilitySingle(opt.visible, opt.objects, opt.matchexact)
    }
  }
  const visibilitygroups = store.getters['visibilitygroup/getAll']
  if (visibilitygroups !== undefined) {
    for (let group of visibilitygroups) {
      let option = group.options.find(item => item.name === group.visible)
      if (option === null) {
        option = group.options[0]
      }
      configure.SetVisibilityGroup(group, option, option.matchNameExactly)
    }
  }
}

const initPresetgroup = async function () {
  const presetgroups = store.getters['presetgroup/getAll']
  
  for (let group of presetgroups) {
    if (group.init) {
      let option = group.options.find(item => item.name === group.visible)
      if (option === null) {
        option = group.options[0]
      }
      await configure.SetPresetGroup(group, option)
    }
  }
}

const initViews = function (startView) {
  if (startView) {
    const myView = store.getters['view/getItemByName'](startView)
    if (myView) configure.setView(myView.payload)
  } else {
    const views = store.getters['view/getAll']
    if (views.length > 0) configure.setView(views[0].payload)
  }
}

const initMaterials = async function () {
  const materials = store.getters['material/getAll']
  if (materials !== undefined) {
    for (let group of materials) {
      let option = group.options[0]
      if (group.visible !== undefined) {
        option = group.options.find(item => item.name === group.visible)
        if (option === null) {
          option = group.options[0]
        }
      }
      if (group.options.length > 0) {
        await configure.SetMaterialOption(group, option)
      }
      store.commit('material/updateSelected', {group: group.name, option: option.name})
    }
    datatools.material.hookMenuVisibility()
  }

  const swappees = store.getters['materialswappable/getAllSwappee']
  const swappableMaterials = store.getters['materialswappable/getAllSwappable']
  if (swappableMaterials !== undefined) {
    for (let swappable of swappableMaterials) {
      const swappee = swappees.find(swappee => swappee.name === swappable.visible)
      const mltOption = swappee.options.find(op => op.name === swappee.visible)

      //add additional payload
      let payload = mltOption.payload
      if (swappable.payload) {
        payload = {...payload, ...swappable.payload}
      }

      await configure.SetMaterialOption(swappable, {payload: payload})
    }
  }
  console.log('MATERIALS READY')

  // const state = store.getters['liquid/getState']
  // if (state.liquid !== undefined) {
  //   for (let liquid of state.liquid) {
  //     if (liquid.material !== undefined) {
  //       configure.SetMaterialOption(liquid.material, liquid.material, liquid.liquidsets)
  //     }
  //   }
  // }
}
const initLiquid = function () {
  const liquids = store.getters['liquid/getAll']
  liquids.forEach(liquid => {
    configure.SetVisibilitySingle(liquid.visibility.visible, liquid.visibility.objects, false)
    configure.SetMaterialOption(liquid.material, liquid.material, liquid.liquidsets)
  });


}
const initEnvironment = function () {
  const studiogroups = store.getters['studiogroup/getAll']
  if (studiogroups[0] !== undefined) {
    let option = studiogroups[0].options[0]
      if (studiogroups[0].visible !== undefined) {
        option = studiogroups[0].options.find(item => item.name === studiogroups[0].visible)
        if (option === null) {
          option = studiogroups[0].options[0]
        }
      }
      if (studiogroups[0].options.length > 0) {
        configure.SetEnvironment(option.payload)
      }
      store.commit('studiogroup/updateSelected', {group: studiogroups[0].name, option: option.name})
  }
}

export default {
    initVisibility,
    initPresetgroup,
    initViews,
    initMaterials,
    initEnvironment,
    initLiquid
}
