import store from '../store';
import player from './sketchfab/player';
import materials from './sketchfab/materials';
import material from './sketchfab/editor/material';
import environment from './sketchfab/editor/environment';
import postprocessing from './sketchfab/editor/postprocessing';
import background from './sketchfab/editor/background';
import lights from './sketchfab/editor/lights';
import camera from './sketchfab/editor/camera';

import {Pane} from 'tweakpane';
import * as TweakpaneRotationInputPlugin from '@0b5vr/tweakpane-plugin-rotation'

let pane = null
let refreshing = false

const updateGui = function () {
  if (pane) {
    refreshing = true
    pane.refresh()
    refreshing = false
  }  
}

const setGuiMaterialValuesFromName = function (materialName) {
  material.getValues(materialName)
  updateGui()
}

const setGuiLightingValues = function(payload) {
  lights.getValues(payload)
  updateGui()
}

const setGuiCameraValues = function(payload) {
  camera.getValues(payload)
  updateGui()
}

const setGuiEnvironmentValues = function(payload) {
  environment.getValues(payload)
  updateGui()
}

const setGuiBackgroundValues = function(payload) {
  for (let value in payload) {
    background.values[value] = payload[value]
  }
  background.values.colorrgb = material.convertColorToObject(background.values.color)

  updateGui()
}

const setGuiPostprocessingValues = function(payload) {
  postprocessing.getValues(payload)
  updateGui()
}

const buildSketchfabGui = function () {
  pane = new Pane({
    title: '3D editor',
    container: document.getElementById('editor-overlay'),
  });
  pane.registerPlugin(TweakpaneRotationInputPlugin);
  pane.addInput(document, 'title')
  const editList = pane.addBlade({
    view: 'list',
    label: 'Edit',
    options: [
      {text: 'Materials', value: 'material'},
      {text: 'Post processing', value: 'post'},
      {text: 'Background', value: 'back'},
      {text: 'Environment', value: 'env'},
      {text: 'Lights', value: 'lights'},
      {text: 'Camera', value: 'camera'},
    ],
    value: 'material'
  })
  editList.on('change', event => {
    console.log("event", event.value);
    material.folder.hidden = event.value !== 'material'
    environment.folder.hidden = event.value !== 'env'
    postprocessing.folder.hidden = event.value !== 'post'
    background.folder.hidden = event.value !== 'back'
    lights.folder.hidden = event.value !== 'lights'
    camera.folder.hidden = event.value !== 'camera'
  })
}

const exportJSON = function (type) {
  const preset = pane.exportPreset()
  delete preset.materialName
  delete preset.import
  delete preset.title
  delete preset['Lights-id']

  if (type !== 'post') postprocessing.presetkeys.forEach(key => delete preset[key])
  if (type !== 'background') background.presetkeys.forEach(key => delete preset[key])
  if (type !== 'environment') environment.presetkeys.forEach(key => delete preset[key])
  if (type !== 'material') material.presetkeys.forEach(key => delete preset[key])
  if (type !== 'lights') lights.presetkeys.forEach(key => delete preset[key])
  if (type !== 'camera') camera.presetkeys.forEach(key => delete preset[key])


  navigator.clipboard.writeText(JSON.stringify(preset, null, 2))
  return JSON.stringify(preset)
}

const showEditor = async function () {

  if (pane) {
    pane.hidden = false
  } else {

  
  player.api.setHighlightOptions({
    outlineWidth: 5,
    outlineColor: [1.0, 0.0, 0.0],
    outlineDuration: 4,
    highlightColor: [1.0, 1.0, 1.0],
    highlightDuration: 2
  }, function() {
      window.console.log('Set highlight options');
  });





  // Click on a material to load its name in the gui
  player.api.addEventListener('click', function (info) {
    if (info.material) {
      material.setGuiMaterialValues(info.material)
    }
  }, { pick: 'fast' })


  buildSketchfabGui()
  postprocessing.buildGUI()
  material.buildGUI()
  environment.buildGUI()
  background.buildGUI()
  lights.buildGUI()
  camera.buildGUI()
}
}

const hideEditor = function () {
  if (pane) {
    pane.hidden = true
  }
}

const convertColorToArray = function (color) {
  return [color.r, color.g, color.b]
}

window.configuratorapi.showEditor = showEditor

export default {
  get pane() {
    return pane;
  },  
  get refreshing() {
    return refreshing
  },
  set refreshing(newvalue) {
    return refreshing = newvalue;
  },
  updateGui,
  setGuiEnvironmentValues,
  setGuiBackgroundValues,
  setGuiPostprocessingValues,
  setGuiMaterialValuesFromName,
  setGuiLightingValues,
  setGuiCameraValues,
  exportJSON,
  showEditor,
  hideEditor,
  convertColorToArray,
}