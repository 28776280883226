const project = {
  ui: {
    contactcard: true,
    projecttitle: 'Prototype by Klaas Nienhuis',
    headerlogo: null,//'data/de-stijl-museum/assets/destijl-header.png',
    occludedannotationopacity: 0.5,
    movingannotationopacity: 0.2,
    expandactiveannotation: true,
    navigatebyannotationonly: false,    
    background: {
      image: '',
      color: '#5F2E2E',
      type: 'sketchfab'
    },
    theme: 'light',
    checkout: true,
    pricing: false,
    editor: false
  },
  scenes: [
    {
      scenetitle: 'De Stijl Museum',
      header: 'De Stijl Museum',
      description: '',
      sceneuid: '15e7efd3cb564e70b56bf3e23dce2768',
      subheader: 'Ontwerp',
      banner: 'data/de-stijl-museum/assets/de_stijl_museum-banner.png',
      menu: [
        {
          name: 'opt',
          title: 'Lagen',
          icon: 'mdi-wrench',
          expand: true,
          options: [
            {visibilitysingle: 'zonwering'},
            {visibilitysingle: 'spaceframe'},
            {visibilitysingle: 'dak'},
            {visibilitysingle: 'gevel'},
            {visibilitysingle: 'beganegrond'},
            {visibilitysingle: 'entresol'},
            {visibilitysingle: 'entree'},
            {visibilitysingle: 'souterrain'},
            {visibilitysingle: 'kelder'},
            {visibilitysingle: 'blackbox'},
            {visibilitysingle: 'mda'},
            {visibilitysingle: 'mensen'},
          ]
        },
        {
          name: 'cam',
          title: 'Standpunten',
          icon: 'mdi-camera',
          options: [
            {view: 'zuidgevel'},
            {view: 'oostgevel'},
            {view: 'noordgevel'},
            {view: 'westgevel'},
            {view: 'dak'},
            {view: 'entree'},
            {view: 'mda'},
            {view: 'box'},
            {view: 'mda-entresol'},
            {view: 'mda-souterrain'},
            {view: 'vanuit-mda'},
          ]
        },
      ],
      player: {template: 'museum', payload: {}},
      background: {template: 'museum', payload: {}},
      environment: {template: 'museum-dag', payload: {}},
      postprocessing: {template: 'museum-dag', payload: {}},      
      annotation: []  
    },
    {
      scenetitle: 'Maidon d\'Artiste',
      header: 'Maidon d\'Artiste',
      description: '',
      sceneuid: 'c31568df4bcf4787b46a564bb5afafd8',
      subheader: 'Ontwerp',
      banner: 'data/de-stijl-museum/assets/mda-banner.png',
      menu: [
        {
          name: 'cam',
          title: 'Standpunten',
          icon: 'mdi-camera',
          options: [
            {view: 'mda-zuidgevel'},
            {view: 'mda-oostgevel'},
            {view: 'mda-noordgevel'},
            {view: 'mda-westgevel'},
          ]
        },
      ],
    },
  ]
}

export default project;