import Vue from 'vue'
import Router from 'vue-router'
import Configurator from './views/Configurator.vue'
import Models from './views/Models.vue'

Vue.use(Router)

export default new Router({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'configurator',
      component: Configurator
    },
    {
      path: '/models',
      name: 'models',
      component: Models
    }
  ]
})
