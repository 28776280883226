<script>
import nmatools from "@/tools/project/nma"

export default {
  name: 'tweakpanelightoverlayitem',
  components: {
  },
  props: {
    lightid: Number,
    origin: Array,
    cameramoves: Boolean,
  },
  data: function () {
    return {
      light2d: [-100, -100],
      light2dTarget: [-100, -100],
      gp: null,
      resize: false
    }
  },  
  computed: {
    pointid () {
      return `nmapoint${this.lightid}`
    },
    lineid () {
      return `nmaline${this.lightid}`
    },
    lightmoves () {
      return this.$store.state.nmalight.lightmoves
    },
    nmalight () {
      return this.$store.getters['nmalight/getLight'](this.lightid)
    },
    lightPos () {
      // return this.$store.getters['nmalight/getLight3d'](this.lightid) 
      return [this.nmalight.matrix[12], this.nmalight.matrix[13], this.nmalight.matrix[14] ]
    },
    light3d () {
      return this.$store.getters['nmalight/getLight3d'](this.lightid) 
      // return [this.nmalight.matrix[12], this.nmalight.matrix[13], this.nmalight.matrix[14] ]
    },
    isMenuVisible () {
      return this.$store.state.nmalight.lightsVisible
    },
    mobileportrait() {
      return this.$vuetify.breakpoint.width < 960 && this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.height;
    },
    showonscreen () {
      return true
      // if (this.nmalight.enabled && this.isMenuVisible) {
      //   if (this.mobileportrait) {
      //     return this.$store.state.ui.bottomnav
      //   } else {
      //     return this.$store.state.ui.desktopnav
      //   }
      // } else return false
    },
    translate () {
      if (this.showonscreen) {
        return { transform: 'translate(' + (this.light2d[0]-10) + 'px,' + (this.light2d[1]-10) + 'px)' }        
      } else {
        return {visibility: 'hidden'} //transform: 'translate(-100px,-100px)', 
      }
    },
    svgpoints () {
      if (this.showonscreen) {
        return `${this.light2d[0]},${this.light2d[1]} ${this.light2dTarget[0]},${this.light2dTarget[1]}`
      } else {
        return ''
      }
    },
  },
  mounted () {
    this.tick()
  },
  methods: {
    tick () {
      // This tick is called every frame. If the camera is moving, we update the position
      // of the annotation. If the camera has just stopped, we update the occlusion
      // of the annotation but we don't update the position anymore.
      const self = this
      if (self.cameramoves || self.lightmoves || self.resize) {
        nmatools.getScreenCoord(this.lightPos)
        .then(result => {
          if (result) {
            self.light2d = [result[0], result[1]]
            self.resize = false
          }
        })
        nmatools.getScreenCoord(this.light3d)
        .then(result => {
          if (result) {
            self.light2dTarget = [result[0], result[1]]
            // self.resize = false
          }
        })
        // this.$store.dispatch('nmalight/setLightmoves', {value: false})
      } else {
      }
      requestAnimationFrame(self.tick)
    },
    onResize () {
      this.resize = true
    }

  }

}
</script>

<template>
  <div v-resize="onResize">
    <div
      :id="pointid"
      class="point" 
      :style="translate"
    >
      {{ this.lightid + 1 }}
    </div>

    <svg
      :id="lineid"
    >
      <polyline :points="svgpoints"></polyline>    
    </svg>    
  </div>
</template>

<style lang="scss" scoped>
  .nmalightwrapper {
    overflow: hidden;
    position: absolute;
    top: 0px;
    left: 0px;  
    z-index:3;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .point {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ffffff;
    color: #000000;
    pointer-events: none;
    z-index: 30;
    line-height: 20px;
    text-align: center;
  }
  polyline {
    fill: none;
    stroke-width: 2;
    stroke-linecap: round;
    stroke: #ffffff;
  }
  svg {
    width:100%;
    min-height:1000px;
    position: absolute;
  }  
</style>
