<script>
import visibilitygroupoption from './option'

export default {
  name: 'visibilitygroupmenu',
  components: {
    visibilitygroupoption
  },
  props: {
    visibilitygroup: Object
  }
}
</script>

<template>
  <v-list color="cardaccent2">
    <template v-for="(option, index) in visibilitygroup.options">
      <visibilitygroupoption :key="index" :option="option" :visibilitygroup="visibilitygroup"/>
    </template>  
  </v-list>  
</template>